import { API } from "aws-amplify";
import axios from "axios";
import { store } from "../store";

const interceptor = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
});

interceptor.interceptors.response.use(
  async (res) => {
    return res;
  },
  async (err) => {
    console.log("Api error: ", { err });
    throw err;
  }
);

export const getAttribute = async (customerId) => {
  let init = {
    body: {
      dataClassAttributeDtoExample: {
        customerDto: {
          customerId: customerId,
        },
        containerDataClassDto: {
          containerDataClassId: 7,
        },
      },
    },
  };

  return API.post(
    "blustreamREST",
    "/gandalfproxy/api/data_class_attribute/find",
    init
  );
};

export const addAttribute = async ({
  AttributeTitle,
  AttributeType,
  customerId,
}) => {
  let init = {
    body: {
      customerId: customerId,
      name: AttributeTitle,
      containerDataClassId: 9,
      typeDataClassId: AttributeType,
    },
  };
  return API.post(
    "blustreamREST",
    "/gandalfproxy/api/data_class_attribute/add",
    init
  );
};

export const getProduct = async (id) => {
  let response = await getCustomerId(id);
  let customerId = response.response?.data?.[0]?.customerId;
  return API.get(
    "blustreamREST",
    `/gandalfproxy/api/product/find?productDtoExample.CustomerDto.CustomerId=${customerId}`
  );
};

export const addValue = async (datas, id, val) => {
  return API.get(
    "blustreamREST",
    `/gandalfproxy/api/data_class_instance_attribute_value/addOrUpdateContactAttributeValue?contactId=${id}&dataClassAttributeId=${datas}&value=${val}`
  );
};

export const getAttributeValues = async (id) => {
  return API.get(
    "blustreamREST",
    `/gandalfproxy/api/data_class_instance_attribute_value/findContactAttributeValues?contactId=${id}`
  );
};

export const getAllAttributeValues = async (id) => {
  return API.get(
    "blustreamREST",
    `/gandalfproxy/api/data_class_attribute/find?dataClassAttributeDtoExample.containerDataClass.dataClassId=7&dataClassAttributeDtoExample.customerDto.customerId=${id}`
  );
};

export const createContact = async ({
  firstName,
  lastName,
  emailAddress,
  phoneNumber,
}) => {
  const { loginReducer } = store.getState();
  let id = loginReducer?.groups?.[0];
  let init = {
    body: {
      firstName,
      lastName,
      primaryEmailAddress: emailAddress,
      primaryPhoneNumber: phoneNumber,
      customerExternalUuid: id,
    },
  };
  return API.post("blustreamREST", "/gandalfproxy/api/contact/add", init);
};
export const updateContact = async ({
  acctid,
  firstName,
  lastName,
  primaryEmailAddress,
  primaryPhoneNumber,
}) => {
  let init = {
    body: {
      contactId: acctid,
      firstName: firstName,
      lastName: lastName,
      primaryEmailAddress: primaryEmailAddress,
      primaryPhoneNumber: primaryPhoneNumber,
    },
  };
  return API.post("blustreamREST", "/gandalfproxy/api/contact/update", init);
};

export const deleteContact = async (id) => {
  let init = {
    body: {
      id: id,
    },
  };
  return API.post(
    "blustreamREST",
    "/gandalfproxy/api/contact/delete-by-id",
    init
  );
};

export const getContacts = async (accountID, page, size) => {
  let init = {
    body: {
      page: page,
      size: size,
      contactExample: {
        customerExternalUuid: `${accountID}`,
        isActive: true,
      },
    },
  };
  return API.post("blustreamREST", "/gandalfproxy/api/contact/find", init);
};

export const getContactbyID = async (contactId) => {
  return API.get(
    "blustreamREST",
    `/gandalfproxy/api/contact/find-by-id?id=${contactId}`
  );
};

export const getAllContacts = async (accountID) => {
  let init = {
    body: {
      contactExample: {
        customerExternalUuid: `${accountID}`,
        isActive: true,
      },
    },
  };
  return API.post("blustreamREST", "/gandalfproxy/api/contact/find", init);
};

export const AddProduct = async (contactId, productId) => {
  return API.get(
    "blustreamREST",
    `/gandalfproxy/api/contact_product/add?contactId=${contactId}&productId=${productId}`
  );
};
export const getContactProductRelations = async (contactId) => {
  return API.get(
    "blustreamREST",
    `/gandalfproxy/api/contact_product/find?contactProductDtoExample.contactDto.customerDto.customerId=${contactId}`
  );
};

export const getContactProductAttributeName = async (customerId) => {
  return API.get(
    "blustreamREST",
    `/gandalfproxy/api/data_class_attribute/find?dataClassAttributeDtoExample.containerDataClass.dataClassId=9&dataClassAttributeDtoExample.customerDto.customerId=${customerId}`
  );
};

export const getDataClassInstanceId = async (cId, pId) => {
  return API.get(
    "blustreamREST",
    `/gandalfproxy/api/contact_product/find?contactProductDtoExample.contactDto.contactId=${cId}&contactProductDtoExample.productDto.productId=${pId}&contactProductDtoExample.isActive=true`
  );
};

export const getDataClassInstanceAttributeId = async (id) => {
  return API.get(
    "blustreamREST",
    `/gandalfproxy/api/data_class_instance_attribute/find?dataClassInstanceAttributeDtoExample.dataClassInstanceDto.dataClassInstanceId=${id}`
  );
};

export const AddDataClassInstanceAttributeId = async (id, value) => {
  return API.get(
    "blustreamREST",
    `/gandalfproxy/api/data_class_instance_attribute_value/add?dataClassInstanceAttributeId=${id}&value=${value}`
  );
};
export const getDataClassInstanceAttributeValue = async (id) => {
  return API.get(
    "blustreamREST",
    `/gandalfproxy/api/data_class_instance_attribute_value/findValueByAttributeInstance?dataClassInstanceAttributeId=${id}`
  );
};

export const getTuchPointData = async (id) => {
  return API.get(
    "blustreamREST",
    `/gandalfproxy/api/customer/${id}/journeys?$select=Id,Name`
  );
};
export const getTuchPointIdData = async (id, journeyId) => {
  return API.get(
    "blustreamREST",
    `/gandalfproxy/api/customer/${id}/journey/${journeyId}`
  );
};

export const editTuchPointData = async (id, journeyId, touchpointId, data) => {
  let init = {
    body: data,
  };
  return API.put(
    "blustreamREST",
    `/gandalfproxy/api/customer/${id}/journey/${journeyId}/touchpoint/${touchpointId}`,
    init
  );
};

export const tresableLinkData = async (id, target_url, touchpointId) => {
  return API.get(
    "blustreamREST",
    `/gandalfproxy/api/target_url_activity_link/getLinkTracker?customerId=${id}&targetUrl=${target_url}&journeyActivity=${touchpointId}`
  );
};

export const getCustomerId = async (id) => {
  return API.get(
    "blustreamREST",
    `/gandalfproxy/api/customer/find?customerDtoExample.externalUuid=${id}`
  );
};

export const testJourneyTouchpoint = async (
  phoneNumber,
  journeyId,
  touchpointId
) => {
  const { loginReducer } = store.getState();
  let externalUuid = loginReducer?.groups?.[0];
  let init = {
    body: +phoneNumber,
  };
  return API.post(
    "blustreamREST",
    `/gandalfproxy/api/customer/${externalUuid}/journey/${journeyId}/touchpoint/${touchpointId}/test`,
    init
  );
};

export const fuzzySearch = async (customerArn, queryString) => {
  return API.get(
    "blustreamREST",
    `/gandalfproxy/api/customers/${customerArn}/contacts?searchQuery=${queryString}`
  );
};

export const deleteContactProductRelation = async (contactProductId) => {
  return API.get(
    "blustreamREST",
    `/gandalfproxy/api/contact_product/delete-by-id?id=${contactProductId}`
  );
};

export const getWhatsappConfiguration = async (customerId) => {
  return API.get(
    "blustreamREST",
    `/gandalfproxy/api/whatsapp_configuration/find?whatsappConfigurationDtoExample.customerOriginationPhoneNumberDto.customerDto.customerId=${customerId}`
  );
};

export const addWhatsappConfiguration = async (payload) => {
  let init = {
    body: payload,
  };
  return API.post(
    "blustreamREST",
    "/gandalfproxy/api/whatsapp/verify-and-save",
    init
  );
};
