import { SearchOutlined } from "@ant-design/icons";
import { Button, Col, Empty, Input, Row, Skeleton } from "antd";
import { API, graphqlOperation } from "aws-amplify";
import { deleteCompanyInviteHistory } from "graphql/mutations";
import {
  onCreateCompanyInviteHistory,
  onDeleteCompanyInviteHistory,
} from "graphql/subscriptions";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import teamIcon from "./../../assets/Images/teamIcon.svg";

const PendingRequests = (props) => {
  const { ListPendingRequests, pendingRequestList } = props;
  const { roAdmin } = useSelector((state) => state.loginReducer);

  const [searchValue, setSearchValue] = useState("");
  const [filteredPendingRequestList, setFilteredPendingRequestList] = useState(
    []
  );
  const [listRequestLoading, setListRequestLoading] = useState(false);
  const [deleteRequestLoading, setDeleteRequestLoading] = useState(false);
  const [loadingFlag, setLoadingFlag] = useState(null);

  useEffect(() => {
    const createInviteHistorySubscription = API.graphql(
      graphqlOperation(onCreateCompanyInviteHistory)
    ).subscribe({
      next: async () => {
        await ListPendingRequests();
      },
      error: (error) => console.warn(error),
    });

    const deleteInviteHistorySubscription = API.graphql(
      graphqlOperation(onDeleteCompanyInviteHistory)
    ).subscribe({
      next: async () => {
        await ListPendingRequests();
      },
      error: (error) => console.warn(error),
    });
    const getInitialValue = async () => {
      try {
        setListRequestLoading(true);
        await ListPendingRequests();
      } catch (error) {
        console.log(error);
      } finally {
        setListRequestLoading(false);
      }
    };
    getInitialValue();

    return () => {
      createInviteHistorySubscription?.unsubscribe();
      deleteInviteHistorySubscription?.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (searchValue?.length > 0) {
      setFilteredPendingRequestList(
        pendingRequestList?.filter((item) => {
          return item?.email
            ?.toLowerCase()
            .includes(searchValue?.toLowerCase());
        })
      );
    } else {
      setFilteredPendingRequestList(pendingRequestList);
    }
  }, [pendingRequestList]);

  const searchPendingRequest = (value) => {
    if (value?.length > 0) {
      setFilteredPendingRequestList(
        pendingRequestList?.filter((item) => {
          return item?.email
            ?.toLowerCase()
            .includes(value?.trimStart()?.toLowerCase());
        })
      );
    } else {
      setFilteredPendingRequestList(pendingRequestList);
    }
  };

  const deletePendingRequest = async (id) => {
    try {
      setDeleteRequestLoading(true);
      await API.graphql(
        graphqlOperation(deleteCompanyInviteHistory, {
          input: { id: id },
        })
      );
      await ListPendingRequests();
    } catch (error) {
      console.log(error);
    } finally {
      setDeleteRequestLoading(false);
    }
  };

  return listRequestLoading ? (
    <>
      <Skeleton paragraph={{ rows: 4 }} />
    </>
  ) : (
    <>
      <Row
        style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}
      >
        <Col span={12}>
          <div className="user-request-title">Pending invites</div>
        </Col>
        <Col span={12}>
          <Input.Search
            className="standard-searchbox"
            allowClear
            value={searchValue}
            prefix={<SearchOutlined className="standard-searchbox-prefix" />}
            placeholder="Search users"
            type="search"
            onChange={(event) => {
              setSearchValue(event.target.value);
              searchPendingRequest(event.target.value);
            }}
          />
        </Col>
      </Row>
      <Row>
        {filteredPendingRequestList?.length > 0 ? (
          filteredPendingRequestList?.map((item, index) => {
            return (
              <Col span={24} key={index} className="user-request-main-wrapper">
                <div className="user-request-details-wrapper">
                  <img className="user-icon" src={teamIcon} alt="teamIcon" />
                  <div className="user-request-email">{item?.email}</div>
                </div>
                <div className="user-request-button-container">
                  <Button
                    className="standard-button secondary-orange-button"
                    onClick={() => {
                      setLoadingFlag(index);
                      deletePendingRequest(item?.id);
                    }}
                    disabled={roAdmin}
                    loading={index === loadingFlag && deleteRequestLoading}
                  >
                    Cancel
                  </Button>
                </div>
              </Col>
            );
          })
        ) : (
          <Empty
            description="No pending invitations found!"
            style={{
              width: "100%",
              marginTop: "20px",
            }}
          />
        )}
      </Row>
    </>
  );
};

export default PendingRequests;
