import Amplify, { Auth } from "aws-amplify";
import "App.css";
import "responsive.css";
import Routes from "Routers/Routes";

import config from "./aws-exports";
import Maintenance from "UnderMaintenance.js";

const updatedAwsConfig = {
  ...config,
  // Set API to use id token. This will ensure email is passed within the token
  API: {
    graphql_headers: async () => ({
      Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
    }),
  },
};

Amplify.configure(updatedAwsConfig);

function App() {
  return (
    <>
      {process.env.REACT_APP_IS_UNDER_MAINTENANCE === "true" ? (
        <Maintenance />
      ) : (
        <Routes />
      )}
    </>
  );
}

export default App;
