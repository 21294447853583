import { EditOutlined, LeftOutlined, MoreOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Dropdown, Row , Menu } from 'antd';
import Profile from '../../assets/Images/profileImg.svg'
import keyboard from '../../assets/Images/keyboard.svg'
import Saxophone from '../../assets/Images/Saxophone.svg'
import flute from '../../assets/Images/flute.svg'
import React from 'react';
import Triggers from './Triggers';
import { useHistory } from "react-router-dom";


export default function TouchpointDetail() {
  const history = useHistory();

  const menu = (
    <Menu>
      <Menu.Item>
        <a href="#">
          Keyboard
        </a>
      </Menu.Item>
      <Menu.Item>
        <a href="#">
        Bass Guitar
        </a>
      </Menu.Item>
      <Menu.Item>
        <a href="#">
        Saxophone
        </a>
      </Menu.Item>
      <Menu.Item>
        <a href="#">
        Flute
        </a>
      </Menu.Item>
    </Menu>
  );

  return <>
    <div>

      <Row gutter={10}>
        <Col span={6} lg={6} md={6} sm={24} xs={24}>
          <div style={{
              width: '100%',
              background: 'white',
              borderRadius: '8px',
              marginBottom: '10px',
              padding: 10
          }}> 
            <div style={{display: 'flex' , justifyContent: 'space-between' , alignItems:'center'}}> 
              <p style={{marginBottom: 0}}><Button onClick={() => window.history.back()} icon={<LeftOutlined />} type="text" shape="circle"/> Product Onboarding</p>

              <Button type="text" icon={<MoreOutlined style={{color: '#959595'}} />} shape="circle"></Button>
            </div>

            <div style={{display: 'flex' , alignItems:'center'}}> 
              <h3 style={{color: '#124173' , fontWeight: 600, marginBottom: 0}}>About your Guitar </h3>
              <Button type="text" icon={<EditOutlined style={{color: '#959595'}} />} shape="circle"></Button>
            </div>

            <Avatar.Group size="large" style={{marginTop: 7 , marginBottom: 7}}>
              <Avatar icon={<img src={Profile} />} />
              <Avatar icon={<img src={keyboard} />} />
              <Avatar icon={<img src={Saxophone} />} />
              <Avatar icon={<img src={flute} />} />
              <Avatar icon={<img src={Profile} />} />
            </Avatar.Group>

            <p style={{color: "#959595"}}>Bass Guitar, cello, violin & <Dropdown overlay={menu} arrow><Button type="text" style={{padding: 0 , height: 'auto' , color: "#959595", borderBottom: '1px solid #959595'}}>5 more</Button></Dropdown></p>
          </div>

          <div style={{
              width: '100%',
              background: 'white',
              borderRadius: '8px',
              marginBottom: '10px',
          }}> 
            <h3 style={{background: '#E2EBF3',  borderRadius: '8px 8px 0 0', padding: '10px 20px', color: '#124173', marginBottom: 0}}>Trigger Types</h3>

            <Menu
              className="triggerTypes"
              defaultSelectedKeys={['1']}
              defaultOpenKeys={['sub1']}
              mode='inline'
              theme='light'
            >
              <Menu.Item key="1">
                Channel Type & Message
              </Menu.Item>
              <Menu.Item key="2">
                Timed (Single Usage)
              </Menu.Item>
              <Menu.Item key="3">
                Timed (Reoccuring)
              </Menu.Item>
              <Menu.Item key="4">
                Audience
              </Menu.Item>
              <Menu.Item key="5">
                Touchpoint response
              </Menu.Item>
              <Menu.Item key="6">
                Usage Equation
              </Menu.Item>
              <Menu.Item key="7">
                External System
              </Menu.Item>
              <Menu.Item key="8">
                Calendar
              </Menu.Item>
            </Menu>
          </div>
        </Col>

        <Col span={18} lg={18} md={18} sm={24} xs={24}>
          <div style={{
              width: '100%',
              background: 'white',
              borderRadius: '8px',
              marginBottom: '10px',
              padding: 10
          }}> 
              <div style={{display: 'flex' , justifyContent: 'space-between' , alignItems: 'center' , flexWrap: 'wrap' , borderBottom: '1px solid #F0F0F0' , paddingBottom: 8}}>
                <h3 style={{color: '#252525' , marginBottom:0}}>Trigger Analytics</h3>
                <Button shape="round" danger>View all analytics</Button>
              </div>

              <div className="responsiveRow" style={{display: 'flex', margin: '20px 0'}}>
                <div style={{flexGrow: 1,flexBasis: '0px' , textAlign: 'center'}}>
                  <h2 style={{marginBottom: 0 , color: '#3B84D1' , fontWeight: 600}}>350</h2>
                  <p style={{marginBottom: 0}}>Contacts <br/>Recieved</p>  
                </div> 
                <div style={{flexGrow: 1,flexBasis: '0px' , textAlign: 'center'}}>
                  <h2 style={{marginBottom: 0 , color: '#3B84D1' , fontWeight: 600}}>68</h2>
                  <p style={{marginBottom: 0}}>Products <br/>Connected</p>  
                </div> 
                <div style={{flexGrow: 1,flexBasis: '0px' , textAlign: 'center'}}>
                  <h2 style={{marginBottom: 0 , color: '#3B84D1' , fontWeight: 600}}>180</h2>  
                  <p style={{marginBottom: 0}}>Notifications <br/>Opened</p>
                </div> 
                <div style={{flexGrow: 1,flexBasis: '0px' , textAlign: 'center'}}>
                  <h2 style={{marginBottom: 0 , color: '#3B84D1' , fontWeight: 600}}>210</h2>
                  <p style={{marginBottom: 0}}>Contacts <br/>Engaged</p>  
                </div> 
                <div style={{flexGrow: 1,flexBasis: '0px' , textAlign: 'center'}}>
                  <h2 style={{marginBottom: 0 , color: '#3B84D1' , fontWeight: 600}}>180 Secs</h2>  
                  <p style={{marginBottom: 0}}>Avg. Time Spent On Content</p>
                </div> 
              </div>
          </div>

          <Triggers />
        </Col>
      </Row>
    </div>
  </>;
}
