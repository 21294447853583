/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import {
  // Table,
  Button,
  Form,
  Input,
  Modal,
  // Spin,
  Row,
  Col,
  Drawer,
  // Space,
  // Radio,
  message,
  // Checkbox,
  Select,
  Skeleton,
  Typography,
  Pagination,
  Empty,
  Badge,
} from "antd";
import {
  // DeleteFilled,
  SearchOutlined,
  CheckOutlined,
  RedoOutlined,
  // Loading3QuartersOutlined,
  SyncOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
// import { Pagination } from 'antd';
// import dog1 from '../../assets/Images/dog1.svg'
// import settingIcon from '../../assets/Images/settingsBlue.svg'
import { Link } from "react-router-dom";
// import DeleteIcon from '../../assets/Images/DeleteIcon.svg'
// import UnionIcon from "../../assets/Images/UnionIcon.svg";
// import AnalyticsIcon from "../../assets/Images/AnalyticsIcon.svg";
import {
  getAllContacts,
  getContactProductRelations,
  createContact,
  AddProduct,
  addAttribute,
  getContactProductAttributeName,
  getDataClassInstanceId,
  getDataClassInstanceAttributeId,
  AddDataClassInstanceAttributeId,
  getDataClassInstanceAttributeValue,
  getCustomerId,
  fuzzySearch,
  getContactbyID,
  deleteContactProductRelation,
} from "apiService/RESTApi";
// import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
// import { emailPattern } from "utility/Constants";
import { useSelector } from "react-redux";
// import shopifyLogo from "./../../assets/Images/shopifyLogo.svg";
// import blustreamLogo from "./../../assets/Images/blustreamLogo.svg";
// import backgroundIcon from "./../../assets/Images/backgroundIcon.svg";
// import dog1 from "./../../assets/Images/dog1.svg";
// import dog2 from "./../../assets/Images/dog2.svg";
// import dog4 from "./../../assets/Images/dog4.svg";
// import keyboard from "./../../assets/Images/keyboard.svg";
// import guitar1 from "./../../assets/Images/guitar1.png";
// import guitar2 from "./../../assets/Images/guitar2.png";
// import guitar3 from "./../../assets/Images/guitar3.png";
// import shopifyIcon from "./../../assets/Images/shopifyIcon.svg";
// import prestashopIcon from "./../../assets/Images/prestashopIcon.svg";
// import bigcommerceIcon from "./../../assets/Images/bigcommerceIcon.svg";
import glowingBulbIcon from "./../../assets/Images/glowingBulbIcon.svg";
// import magicHatIcon from "./../../assets/Images/magicHatIcon.svg";
import boxIcon from "./../../assets/Images/boxIcon.svg";
import deleteIcon3 from "../../assets/Images/deleteIcon3.svg";

import "./productmapping.css";

// import { a } from "aws-amplify";
// import { toNumber } from "lodash";
// const { Option } = Select;

const { Search } = Input;
let fuzzySearchDebounce = "";

export default function ProductDetailPage() {
  const [form] = Form.useForm();
  const [existingCustomerForm] = Form.useForm();
  const { groups, roAdmin } = useSelector((state) => state.loginReducer);

  const [addLoading, setAddLoading] = useState(false);
  // eslint-disable-next-line
  const [addForm, setAddForm] = useState({
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNumber: "",
  });
  const [loading, setLoading] = useState(true);
  // const { Option } = Select;
  // const [pageData, setPageData] = useState([1]);
  // const [editProductAttributeDrawer, setEditProductAttributeDrawer] =
  //   useState(false);
  // eslint-disable-next-line
  // const [
  //   editContactProductAttributeDrawer,
  //   setEditContactProductAttributeDrawer,
  // ] = useState(false);
  const [
    addContactProductAttributeDrawer,
    setAddContactProductAttributeDrawer,
  ] = useState(false);
  const [
    addExistingContactProductAttributeDrawer,
    setAddExitsingContactProductAttributeDrawer,
  ] = useState(false);
  // const [isInviteUserModelOpen, setisInviteUserModelOpen] = useState(false);
  // const [showAddAttribute, setShowAddAttribute] = useState(false);
  const [contactData, setContactData] = useState([]);
  const [titleData, setTitleData] = useState([]);
  const location = useLocation();
  const CurrentUserID = groups[0];
  const [allContactData, setAllContactData] = useState([]);
  // const [allRemainingContactData, setAllRemainingContactData] = useState([]);
  const [contactId, setContactId] = useState([]);
  // eslint-disable-next-line
  const [allTableData, setAllTableData] = useState();
  // const [ProductData, setProductData] = useState({});
  // const [contact_data, setcontact_data] = useState([]);
  // const [tableData, settableData] = useState([]);
  // const [tableDataBackup, settableDataBackup] = useState([]);
  // eslint-disable-next-line
  const [searchData, setSearchData] = useState([]);
  // eslint-disable-next-line
  // const [allContactsData, setAllContactsData] = useState([]);
  const [allSearchData, setAllSearchData] = useState("");
  const [existingLoading, setExistingLoading] = useState(false);
  // const [isQuickMapVisible, setisQuickMapVisible] = useState(false);
  // const [shopifySelectedProductType, setShopifySelectedProductType] =
  //   useState("");
  // const [shopifySearchValue, setShopifySearchValue] = useState("");
  // const [selectedShopifyProductsValue, setSelectedShopifyProductsValue] =
  //   useState("");
  // const [isAllSelected, setIsAllSelected] = useState(false);
  // const [warningModalVisible, setWarningModalVisible] = useState(false);
  // const [successModalVisible, setsuccessModalVisible] = useState(false);
  const [searchedContacts, setSearchedContacts] = useState([]);
  const [initialContacts, setInitialContacts] = useState([]);
  const [fuzzySearchValue, setFuzzySearchValue] = useState("");
  const [fuzzySearchLoading, setFuzzySearchLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [productContacts, setProductContacts] = useState([]);
  const [filteredProductContacts, setFilteredProductContacts] = useState([]);
  const [contactSearchValue, setContactSearchValue] = useState("");
  const [contactLoading, setContactLoading] = useState(false);
  const [productContactDeleteLoading, setProductContactDeleteLoading] =
    useState(false);
  const [productAttributeLoading, setProductAttributeLoading] = useState(false);
  const [saveProductAttributesLoading, setSaveProductAttributesLoading] =
    useState(false);
  const [selectedProductContactToDelete, setSelectedProductContactToDelete] =
    useState({});
  const [showDeleteProductContactModal, setShowDeleteProductContactModal] =
    useState(false);
  const [selectedContact, setSelectedContact] = useState({});
  const [selectedProductAttributes, setSelectedProductAttributes] = useState(
    []
  );
  const [
    filteredSelectedProductAttributes,
    setFilteredSelectedProductAttributes,
  ] = useState([]);
  const [attributeSearchValue, setAttributeSearchValue] = useState("");
  const [updatedAttributes, setUpdatedAttributes] = useState([]);

  // let debounceValue = "";
  // edit product attribute
  // const showDrawer = () => {
  //   setEditProductAttributeDrawer(true);
  // };

  // const onClose = () => {
  //   setEditProductAttributeDrawer(false);
  // };
  // edit contact product attribute
  // const showContactEditDrawer = () => {
  //   setEditContactProductAttributeDrawer(true);
  // };
  // const ContactEditonClose = () => {
  //   setEditContactProductAttributeDrawer(false);
  // };
  // const showAddAttributeDrawer = () => {
  //   setShowAddAttribute(true);
  // };

  // const showonClose = () => {
  //   form.resetFields();
  //   setShowAddAttribute(false);
  // };

  // add product attribute

  // const handleAttribute = async () => {
  //   setLoading(true);
  //   try {
  //     let resolveData = {
  //       customerId: location?.state?.productData?.cId,
  //       ...form.getFieldsValue(),
  //     };
  //     let res = await addAttribute(resolveData);
  //     if (res) {
  //       message.success("Attribute Added successfully!");
  //       setLoading(false);
  //     } else {
  //       message.error("Something went wrong");
  //     }
  //   } catch (e) {
  //     message.error("Something went wrong!");
  //   }
  //   setShowAddAttribute(false);
  //   fetchContactProductAttributeList();
  //   //setLoading(false);
  //   form.resetFields();
  // };

  // add contact product attribute
  const showAddContactDrawer = () => {
    setAddContactProductAttributeDrawer(true);
  };
  const showExistingContactDrawer = () => {
    setAddExitsingContactProductAttributeDrawer(true);
  };
  const ContactonClose = () => {
    setAddForm({
      ...form,
      firstName: "",
      lastName: "",
      phoneNumber: "",
      emailAddress: "",
    });
    form.resetFields();
    setAddContactProductAttributeDrawer(false);
  };

  const ExistingContactonClose = () => {
    setAddExitsingContactProductAttributeDrawer(false);
    existingCustomerForm.resetFields();
  };

  // const menu = (
  //   <Menu>
  //     <Menu.Item>
  //       <a rel="noopener noreferrer" href="">
  //         Contact Product attributes
  //       </a>
  //     </Menu.Item>
  //   </Menu>
  // );

  // const TouchPointmenu = (
  //   <Menu>
  //     <h3 style={{ padding: "7px 10px", color: "#124173", marginBottom: 0 }}>
  //       Touchpoints associated
  //     </h3>
  //     <Menu.Item>
  //       <a href="">Welcome to Martin</a>
  //     </Menu.Item>
  //     <Menu.Item>
  //       <a href="">About your Guitar</a>
  //     </Menu.Item>
  //     <Menu.Item>
  //       <a href="">Guitar Care</a>
  //     </Menu.Item>
  //     <Menu.Item>
  //       <a href="">Warranty/Registration</a>
  //     </Menu.Item>
  //   </Menu>
  // );

  // const handleAttributeValue = async (value) => {

  //   for (let i in allTableData) {

  //     if (i === value.ContactName) {
  //       let abc = allTableData[i][0].filter(data => data.value !== "")
  //       for (let j = 0; j < abc.length; j++) {

  //         let fetchDataClassInstanceId = await getDataClassInstanceId(
  //           value?.id,
  //           location?.state?.productData?.pid
  //         );
  //         let dataClassInstanceId =
  //           fetchDataClassInstanceId?.response.data[0]?.dataClassInstanceDto
  //             ?.dataClassInstanceId;
  //         let fetchAttributeId = await getDataClassInstanceAttributeId(
  //           dataClassInstanceId
  //         );
  //         let DATA = fetchAttributeId?.response?.data.filter(
  //           (value) => value.dataClassAttributeDto.name === abc[j].title
  //         );
  //         let addAttributeValue = await AddDataClassInstanceAttributeId(
  //           DATA[0]?.dataClassInstanceAttributeId,
  //           abc[j].value
  //         );
  //         let getAttributeValues = await getDataClassInstanceAttributeValue(
  //           DATA[0]?.dataClassInstanceAttributeId
  //         );
  //       }
  //     }

  //   }
  // };

  // const handleResetData = (record) => {
  //   record.isEdited = false;
  //   settableData(tableDataBackup);
  // };

  // let columns = [
  //   {
  //     title: "Contact Name",
  //     dataIndex: "ContactName",
  //     width: "200px",
  //     // mvp commented code
  //     // render: text => <Link to="/contacts-detail" style={{color:'#053262', fontWeight: 600}}>{text}</Link>,
  //     render: (text, record) => (
  //       <div className="wrapper">
  //         <Link
  //           style={{ color: "#053262", fontWeight: 600 }}
  //           to={{
  //             pathname: "/contact-detail",
  //             state: {
  //               userToEdit: {
  //                 customerId: record.customerId,
  //                 id: record.id,
  //                 fname: record.fname,
  //                 lname: record.lname,
  //                 primaryPhoneNumber: allContactData.filter(
  //                   (data) => data.id === record.id
  //                 )[0]?.primaryPhoneNumber,
  //                 primaryEmailAddress: allContactData.filter(
  //                   (data) => data.id === record.id
  //                 )[0]?.primaryEmailAddress,
  //               },
  //             },
  //           }}
  //         >
  //           {text}
  //         </Link>
  //         {record?.isEdited && (
  //           <div className="buttonWrapper">
  //             <span
  //               onClick={() => SaveAttributeValue(record)}
  //               style={{ cursor: "pointer" }}
  //             >
  //               <CheckOutlined />
  //             </span>
  //             <span
  //               onClick={() => handleResetData(record)}
  //               style={{ cursor: "pointer" }}
  //             >
  //               <RedoOutlined />
  //             </span>
  //           </div>
  //         )}
  //       </div>
  //     ),
  //   },
  // ];

  // const SaveAttributeValue = (record) => {
  //   setLoading(true);
  //   Object.keys(record)?.forEach(async (key) => {
  //     if (record?.[key]?.dataClassInstanceAttributeId) {
  //       if (record?.[key]?.value) {
  //         await AddDataClassInstanceAttributeId(
  //           record?.[key]?.dataClassInstanceAttributeId,
  //           record?.[key]?.value
  //         );
  //       }
  //     }
  //   });
  //   setTimeout(() => {
  //     record.isEdited = false;
  //     setLoading(false);
  //   }, 1500);
  // };

  const getProductAttributes = async () => {
    setLoading(true);
    let temp = [];
    let getData = await getContactProductRelations(
      location?.state?.productData?.cId
    );
    if (getData?.response?.data !== null) {
      let data = getData?.response?.data.filter(
        (item) => item?.contactDto?.isActive !== false
      );
      let tempData = {};
      data.forEach((val) => {
        if (val?.productDto?.productId === location?.state?.productData?.pid) {
          temp.push({
            ContactName:
              val.contactDto.firstName + " " + val.contactDto.lastName,
            customerId: val.contactDto.customerDto.customerId,
            id: val.contactDto.contactId,
            fname: val.contactDto.firstName,
            lname: val.contactDto.lastName,
            primaryPhoneNumber: "00000000",
            primaryEmailAddress: "-",
          });
          tempData[val.contactDto.firstName + " " + val.contactDto.lastName] =
            [];
        }
      });
      setAllTableData(tempData);
      let tempContactId = [];
      data.map((item) =>
        tempContactId.push({
          contactId: item?.contactDto?.contactId,
          contactName:
            item?.contactDto?.firstName + " " + item?.contactDto?.lastName,
        })
      );
      let uniqueChars = [...new Set(tempContactId)];
      setContactId(uniqueChars);
      let jsonObject = temp.map(JSON.stringify);
      let uniqueSet = new Set(jsonObject);
      let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
      fetchContact(uniqueArray);
      setContactData(uniqueArray);
      setAllSearchData(uniqueArray);
    } else {
      setLoading(false);
    }
  };

  const handleAdd = async () => {
    try {
      setAddLoading(true);
      let res = await createContact(form.getFieldsValue());
      if (res?.id) {
        await AddProduct(res?.id, location?.state?.productData?.pid);
        // getProductAttributes();
        const productContacts = await getProductsContacts();
        setProductContacts(productContacts);
        setFilteredProductContacts(productContacts);
        if (!Object.keys(selectedContact)?.length > 0) {
          setSelectedContact(productContacts?.[0]);
          setProductAttributeLoading(true);
          const productAttributes = await getProductAttributesInstanceDataId(
            productContacts?.[0]
          );
          setSelectedProductAttributes(productAttributes);
          setFilteredSelectedProductAttributes(productAttributes);
          setProductAttributeLoading(false);
        }
        message.success("Contact created successfully!");
        setAddContactProductAttributeDrawer(false);

        form.resetFields();
      } else {
        message.error(
          // res?.message ? res?.message :
          "Unable to create contact!"
        );
      }
    } catch (err) {
      message.error("Something went wrong!");
    }
    setAddLoading(false);
  };

  const fetchContactProductAttributeList = async () => {
    let temp = [];
    setLoading(true);
    let res = await getContactProductAttributeName(
      location?.state?.productData?.cId
    )
      .then((res) => {
        //setLoading(false)
        res?.response?.data?.map((value) =>
          temp.push({
            title: value?.name,
            dataClassAttributeId: value?.dataClassAttributeId,
          })
        );
        setTitleData(temp);
      })
      .catch((error) => {
        message.error("Something went wrong!");
        setLoading(false);
        setskeletonLoader(false);
        console.log(error);
      });
  };

  // titleData.forEach((data, index) => {
  //   columns.push({
  //     title: data.title,
  //     dataIndex: data?.dataClassAttributeId,
  //     // mvp commented code
  //     render: (text, record) => {
  //       return (
  //         <Input
  //           disabled={roAdmin}
  //           className="product-table-cell"
  //           onChange={(e) =>
  //             EditTableCell(
  //               e.target.value,
  //               text?.dataClassInstanceAttributeId,
  //               record
  //             )
  //           }
  //           value={text?.value ? text?.value : undefined}
  //         />
  //       );
  //     },
  //   });
  // });

  const fetchContact = async (item) => {
    // if (!noMoreContacts) {
    let response = getAllContacts(CurrentUserID);

    response
      .then((data) => {
        if (data?.response?.error) {
          message.error("Something went Wrong");
          setLoading(false);
        } else {
          let usersData = data?.response?.data;
          if (usersData && usersData?.length > 0) {
            let tempArray = [];
            item.map((value) => tempArray.push(value.id));
            let temp = usersData.filter(
              (word) => !tempArray.includes(word["contactId"])
            );
            // setAllRemainingContactData(temp);
            const defaultContacts = temp?.map((contact) => {
              return {
                value: contact?.contactId,
                label: `${contact?.firstName} ${contact?.lastName}`,
              };
            });
            defaultContacts.unshift({
              value: "all",
              label: "All",
            });
            setSearchedContacts(defaultContacts);
            setInitialContacts(defaultContacts);
            let contacts = usersData?.map((item) => ({
              customerId: item?.customerId,
              id: item?.contactId,
              fname: item?.firstName,
              lname: item?.lastName,
              primaryPhoneNumber: item?.primaryPhoneNumber
                ? item?.primaryPhoneNumber
                : "-",
              primaryEmailAddress: item?.primaryEmailAddress
                ? item?.primaryEmailAddress
                : "-",
            }));
            setAllContactData(contacts);
          }
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("Error :", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, []);
  const [skeletonLoader, setskeletonLoader] = useState(false);

  const getAllData = async () => {
    setskeletonLoader(true);
    await fetchContactProductAttributeList();
    await getProductAttributes();
    setskeletonLoader(false);
  };

  // useEffect(() => {
  //   //setAllTableValues()
  //   if (contactId?.length > 0) {
  //     // fetchAttributeValue();
  //     getTableDataCustom(contactId);
  //   }
  //   // eslint-disable-next-line
  // }, [contactId]);

  // useEffect(() => {
  //   let dataObj = {};
  //   // if(contact_data?.length <= 5){
  //   setLoading(false);

  //   contact_data?.map((item) => {
  //     dataObj[Object.keys(item)?.[0]] = item[Object.keys(item)?.[0]];
  //     return true; //only for removing warnings.
  //   });
  //   setAllTableValues(dataObj);
  //   // setAllSearchData(dataObj)

  //   // eslint-disable-next-line
  // }, [contact_data]);

  // const setAllTableValues = (viewData) => {
  //   let totalData = JSON.parse(JSON.stringify(contactData));
  //   totalData?.map((item) => {
  //     viewData?.[item.id] &&
  //       viewData?.[item.id]?.map((value) => {
  //         if (value?.productAttributeId) {
  //           item[value?.productAttributeId] = {
  //             value: value.value,
  //             dataClassInstanceAttributeId: value?.dataClassInstanceAttributeId,
  //           };
  //         }
  //         return true; //only for removing warnings.
  //       });
  //     return true; //only for removing warnings.
  //   });
  //   //setLoading(false)
  //   settableData(JSON.parse(JSON.stringify(totalData)));
  //   setSearchData(JSON.parse(JSON.stringify(totalData)));
  //   settableDataBackup(JSON.parse(JSON.stringify(totalData)));
  //   setLoading(false);
  // };

  // const EditTableCell = (value, name, record) => {
  //   if (name) {
  //     record.isEdited = true;
  //     Object.keys(record)?.map((item) => {
  //       if (record[item]?.dataClassInstanceAttributeId === name) {
  //         record[item].value = value;
  //       }
  //       return true; //only for removing warnings.
  //     });
  //     let tableDataDuplicate = JSON.parse(JSON.stringify(tableData));
  //     let a = tableDataDuplicate.find((item) => item.id === record?.id);
  //     tableDataDuplicate[tableDataDuplicate.indexOf(a)] = record;
  //     settableData(JSON.parse(JSON.stringify(tableDataDuplicate)));
  //   }
  // };

  // useEffect(() => {
  //   setProductData(location?.state?.productData);
  // }, [location?.state?.productData]);

  // const getTableDataCustom = async (contacts) => {
  //   setLoading(true);
  //   // let Contact_data = []
  //   // await contacts.slice(0, 5).map(async contact_id => {
  //   //   let data = await getProductAttributeDataForTheId(contact_id?.contactId)
  //   //   Contact_data.push(data)
  //   //   setcontact_data(old => [...old ,data])
  //   // })
  //   await Promise.all(
  //     contacts
  //       .slice(0, 5)
  //       .map((item) => getProductAttributeDataForTheId(item?.contactId))
  //   )
  //     .then((res) => {
  //       setcontact_data(res);
  //     })
  //     .catch((error) => message.error("Something went wrong!"));

  //   //setLoading(false)
  // };
  // const handlePagination = async (data) => {
  //   let temp = [...pageData];
  //   let abc = temp.filter((item) => item === data.current);
  //   if (abc.length === 0) {
  //     let first = data.current * 5;
  //     let end = first - 5;
  //     getTablePageDataCustom(end, first);
  //     temp.push(data.current);
  //     setPageData(temp);
  //   }
  // };

  // const getTablePageDataCustom = async (first, end) => {
  //   setLoading(true);
  //   contactId.slice(first, end).map(async (contact_id) => {
  //     let data = await getProductAttributeDataForTheId(contact_id?.contactId);
  //     setcontact_data((old) => [...old, data]);
  //   });
  // };

  // const getProductAttributeDataForTheId = async (contact) => {
  //   let FinalData = {};
  //   await getDataClassInstanceId(contact, ProductData.pid).then(async (res) => {
  //     let dataClassInstanceId =
  //       res?.response?.data?.[0]?.dataClassInstanceDto?.dataClassInstanceId;
  //     if (dataClassInstanceId !== undefined) {
  //       let fetchAttributeId = await getDataClassInstanceAttributeId(
  //         dataClassInstanceId
  //       );
  //       let temp = [];
  //       let dataClassInstanceAttributeIds =
  //         fetchAttributeId?.response?.data?.map((item) =>
  //           temp.push({
  //             dataClassInstanceAttributeId: item?.dataClassInstanceAttributeId,
  //             dataClassAttributeId:
  //               item?.dataClassAttributeDto?.dataClassAttributeId,
  //           })
  //         );
  //       if (dataClassInstanceAttributeIds !== undefined) {
  //         await Promise.all(
  //           temp.map((item) =>
  //             getDataClassInstanceAttributeValue(
  //               item?.dataClassInstanceAttributeId
  //             )
  //           )
  //         )
  //           .then((res) => {
  //             let responseData = res.map((response, index) => {
  //               let dataObj = {};
  //               let productAttributeId = temp[index]["dataClassAttributeId"];

  //               if (productAttributeId) {
  //                 dataObj = {
  //                   productAttributeId,
  //                   value:
  //                     response?.response?.data?.[0]?.value !== undefined
  //                       ? response?.response?.data?.[0]?.value
  //                       : null,
  //                   dataClassInstanceAttributeId:
  //                     temp[index]["dataClassInstanceAttributeId"],
  //                 };
  //               } else {
  //                 dataObj = {
  //                   productAttributeId,
  //                   value:
  //                     response?.response?.data?.[0]?.value !== undefined
  //                       ? response?.response?.data?.[0]?.value
  //                       : null,
  //                   dataClassInstanceAttributeId:
  //                     temp[index]["dataClassInstanceAttributeId"],
  //                 };
  //               }
  //               return dataObj;
  //             });
  //             FinalData[contact] = responseData;
  //           })
  //           .catch((error) => console.log(error));
  //       }
  //     }
  //   });
  //   return FinalData;
  // };

  const handleAddExitisingCustomer = async (option) => {
    setExistingLoading(true);
    await Promise.all(
      option?.stages.map((item) =>
        AddProduct(item, location?.state?.productData?.pid)
      )
    )
      .then(async (res) => {
        const productContacts = await getProductsContacts();
        setProductContacts(productContacts);
        setFilteredProductContacts(productContacts);
        if (!Object.keys(selectedContact)?.length > 0) {
          setSelectedContact(productContacts?.[0]);
          setProductAttributeLoading(true);
          const productAttributes = await getProductAttributesInstanceDataId(
            productContacts?.[0]
          );
          setSelectedProductAttributes(productAttributes);
          setFilteredSelectedProductAttributes(productAttributes);
          setProductAttributeLoading(false);
        }
        setAddExitsingContactProductAttributeDrawer(false);
        existingCustomerForm.resetFields();
        message.success("Contact Added successfully!");
        setExistingLoading(false);
      })
      .catch((error) => console.log(error));
  };

  // const handleSearch = (searchData) => {
  //   if (searchData !== "") {
  //     let tempContactData = allSearchData.filter((item) =>
  //       item?.ContactName.toLowerCase().includes(searchData.toLowerCase())
  //     );
  //     let temp = contactId.filter((data) =>
  //       data?.contactName.toLowerCase().includes(searchData.toLowerCase())
  //     );
  //     if (temp.length > 0) {
  //       clearTimeout(debounceValue);
  //       debounceValue = setTimeout(() => {
  //         setContactData(tempContactData);
  //         getTableDataCustom(temp);
  //       }, 1500);
  //       //return () => clearTimeout(debounceValue)
  //     } else {
  //       clearTimeout(debounceValue);
  //       debounceValue = setTimeout(() => {
  //         setContactData(tempContactData);
  //         getTableDataCustom(temp);
  //       }, 1500);
  //     }
  //   } else {
  //     clearTimeout(debounceValue);
  //     debounceValue = setTimeout(() => {
  //       setContactData(allSearchData);
  //       getTableDataCustom(contactId);
  //     }, 1500);
  //     //return () => clearTimeout(debounceValue)
  //   }
  // };

  const handleAll = (e) => {
    if (e.includes("all")) {
      let temp = [];
      e.forEach((item) => {
        temp.push(item);
      });
      initialContacts.forEach((item) => {
        temp.push(item.value);
      });
      var filteredArray = temp.filter((e) => e !== "all");
      const finalArray = filteredArray.filter(
        (item, index) => filteredArray.indexOf(item) === index
      );
      let data = {
        stages: finalArray,
      };
      existingCustomerForm.setFieldsValue(data);
    }
  };

  // const handleSearchChange = (el) => {
  //   if (tableData === "") {
  //     return el;
  //   } else {
  //     return el.contactName.toLowerCase().includes(el);
  //   }
  // };

  // const selectedProductType = (value) => {
  //   setShopifySelectedProductType(value);
  // };

  // const shopifyProductSearch = (event) => {
  //   console.log(event, 23);
  //   // setShopifySearchValue(event);
  // };

  // const selectedShopifyProduct = (value) => {
  //   setSelectedShopifyProductsValue(value);
  // };

  // const selectedPlatform = (value) => {
  //   console.log(`selected platform ${value}`);
  // };

  // const hideWarningModal = () => {
  //   setWarningModalVisible(false);
  // };

  // const hideSuccessModal = () => {
  //   setsuccessModalVisible(false);
  // };
  // const overrideProducts = () => {
  //   setWarningModalVisible(false);
  //   setsuccessModalVisible(true);
  // };

  useEffect(() => {
    handleExistingContactSearch();
  }, [fuzzySearchValue]);

  useEffect(() => {
    getProductsContactsAndAttributes();
  }, []);

  useEffect(() => {
    if (productContacts?.length > 0) {
      if (contactSearchValue?.length > 0) {
        const filteredContacts = productContacts?.filter((item) => {
          return item?.ContactName?.toLowerCase().includes(
            contactSearchValue?.toLowerCase()
          );
        });
        setFilteredProductContacts(filteredContacts);
      } else {
        setFilteredProductContacts(productContacts);
      }
    }
  }, [productContacts]);

  useEffect(() => {
    setUpdatedAttributes([]);
  }, [selectedContact]);

  const handleExistingContactSearch = async () => {
    if (fuzzySearchValue !== "") {
      clearTimeout(fuzzySearchDebounce);
      fuzzySearchDebounce = setTimeout(async () => {
        setFuzzySearchLoading(true);
        await getCustomerId(groups[0]).then(async (res) => {
          const customerArn = res.response.data[0].customerArn;
          const searchResults = await fuzzySearch(
            customerArn,
            fuzzySearchValue
          );
          const promiseArray = searchResults?.data?.map((contact) => {
            return getContactbyID(contact?.contactId);
          });
          Promise.all(promiseArray).then((values) => {
            const searchedResults = values?.map((contact) => {
              return {
                value: contact?.contactId,
                label: `${contact?.firstName} ${contact?.lastName}`,
              };
            });

            setSearchedContacts(searchedResults);
            setFuzzySearchLoading(false);
          });
        });
      }, 1000);
    } else {
      clearTimeout(fuzzySearchDebounce);
      setSearchedContacts(initialContacts);
    }
  };

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <p>{`< Prev`}</p>;
    }
    if (type === "next") {
      return <p>{`Next >`}</p>;
    }
    return originalElement;
  };

  const contactProductAttributePageChange = (currentPage) => {
    setCurrentPage(currentPage);
  };

  const getProductsContacts = async () => {
    try {
      const result = await getContactProductRelations(
        location?.state?.productData?.cId
      );
      const contactProductRelations = result?.response?.data;
      if (contactProductRelations !== null) {
        const productContacts = [];
        //filtered all active contacts
        const ActiveContacts = contactProductRelations?.filter(
          (item) => item?.contactDto?.isActive !== false
        );
        ActiveContacts?.forEach((item) => {
          if (
            item?.productDto?.productId === location?.state?.productData?.pid &&
            !productContacts?.find(
              (val) => val?.id === item?.contactDto?.contactId
            )
          ) {
            productContacts.push({
              ContactName:
                item?.contactDto?.firstName + " " + item?.contactDto?.lastName,
              customerId: item?.contactDto?.customerDto?.customerId,
              id: item?.contactDto?.contactId,
              contactProductId: item?.contactProductId,
              fname: item?.contactDto?.firstName,
              lname: item?.contactDto?.lastName,
              primaryPhoneNumber: "00000000",
              primaryEmailAddress: "-",
            });
          }
        });
        return productContacts;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProductAttributesInstanceDataId = async (contact) => {
    let finalData = {};
    await getDataClassInstanceId(
      contact?.id,
      location?.state?.productData?.pid
    ).then(async (res) => {
      let dataClassInstanceId =
        res?.response?.data?.[0]?.dataClassInstanceDto?.dataClassInstanceId;
      if (dataClassInstanceId !== undefined) {
        let fetchAttributeId = await getDataClassInstanceAttributeId(
          dataClassInstanceId
        );
        let temp = [];
        let dataClassInstanceAttributeIds =
          fetchAttributeId?.response?.data?.map((item) =>
            temp.push({
              dataClassInstanceAttributeId: item?.dataClassInstanceAttributeId,
              dataClassAttributeId:
                item?.dataClassAttributeDto?.dataClassAttributeId,
            })
          );
        if (dataClassInstanceAttributeIds !== undefined) {
          await Promise.all(
            temp.map((item) =>
              getDataClassInstanceAttributeValue(
                item?.dataClassInstanceAttributeId
              )
            )
          )
            .then((res) => {
              let responseData = res.map((response, index) => {
                let dataObj = {};
                let productAttributeId = temp[index]["dataClassAttributeId"];

                if (productAttributeId) {
                  dataObj = {
                    productAttributeId,
                    productAttributeName:
                      response?.response?.data?.[0]
                        ?.dataClassInstanceAttributeDto?.dataClassAttributeDto
                        ?.name,
                    value:
                      response?.response?.data?.[0]?.value !== undefined
                        ? response?.response?.data?.[0]?.value
                        : null,
                    dataClassInstanceAttributeId:
                      temp[index]["dataClassInstanceAttributeId"],
                  };
                } else {
                  dataObj = {
                    productAttributeId,
                    productAttributeName:
                      response?.response?.data?.[0]
                        ?.dataClassInstanceAttributeDto?.dataClassAttributeDto
                        ?.name,
                    value:
                      response?.response?.data?.[0]?.value !== undefined
                        ? response?.response?.data?.[0]?.value
                        : null,
                    dataClassInstanceAttributeId:
                      temp[index]["dataClassInstanceAttributeId"],
                  };
                }
                return dataObj;
              });
              finalData = responseData;
            })
            .catch((error) => console.log(error));
        }
      }
    });
    return finalData;
  };

  //to get all contacts of selected products
  const getProductsContactsAndAttributes = async () => {
    try {
      setContactLoading(true);
      setProductAttributeLoading(true);
      const productContacts = await getProductsContacts();
      if (productContacts?.length > 0) {
        setProductContacts(productContacts);
        setSelectedContact(productContacts?.[0]);
        setContactLoading(false);
        const productAttributes = await getProductAttributesInstanceDataId(
          productContacts?.[0]
        );
        setSelectedProductAttributes(productAttributes);
        setFilteredSelectedProductAttributes(productAttributes);
        setProductAttributeLoading(false);
      } else {
        //this is for the case when no contacts for the products
        setProductContacts([]);
        setFilteredProductContacts([]);
        setSelectedContact({});
        setSelectedProductAttributes([]);
        setFilteredSelectedProductAttributes([]);
        setContactLoading(false);
        setProductAttributeLoading(false);
      }
    } catch (error) {
      console.log(error);
      setContactLoading(false);
      setProductAttributeLoading(false);
    }
  };

  //to search contacts
  const searchContact = (value) => {
    if (value?.length > 0) {
      const filteredContacts = productContacts?.filter((item) => {
        return item?.ContactName?.toLowerCase().includes(
          value?.trimStart()?.toLowerCase()
        );
      });
      setFilteredProductContacts(filteredContacts);
    } else {
      setFilteredProductContacts(productContacts);
    }
  };

  //to delete the contact product relation
  const deleteContactFromProduct = async () => {
    try {
      setProductContactDeleteLoading(true);
      await deleteContactProductRelation(
        selectedProductContactToDelete?.contactProductId
      );
      if (selectedProductContactToDelete?.id === selectedContact?.id) {
        setAttributeSearchValue("");
        await getProductsContactsAndAttributes();
        setUpdatedAttributes([]);
      } else {
        const productContacts = await getProductsContacts();
        setProductContacts(productContacts);
        setFilteredProductContacts(productContacts);
      }
      setProductContactDeleteLoading(false);
      setShowDeleteProductContactModal(false);
    } catch (error) {
      console.log(error);
      setProductContactDeleteLoading(false);
      setShowDeleteProductContactModal(false);
    }
  };

  const SaveProductAttributes = async () => {
    try {
      if (updatedAttributes?.length > 0) {
        setSaveProductAttributesLoading(true);
        var requestPending = updatedAttributes?.length;
        updatedAttributes.forEach((item) => {
          AddDataClassInstanceAttributeId(
            item?.dataClassInstanceAttributeId,
            item?.value
          ).then(() => {
            requestPending--;
            if (requestPending === 0) {
              setSaveProductAttributesLoading(false);
              message.success("Product attributes are saved successfully!");
            }
          });
        });
        setUpdatedAttributes([]);
      }
    } catch (error) {
      console.log(error);
      message.error("Something went wrong!");
    }
  };

  return (
    <>
      <Row className="product-detail-page-container" justify="center">
        <Col span={22} className="product-detail-content-wrapper">
          <div className="product-name-container">
            <img src={boxIcon} alt="box-icon" className="box-icon" />
            <h2 className="product-name">
              {location?.state?.productData?.pname}
            </h2>
          </div>
          <div className="product-details-contact-product-attributes-container">
            <div className="attributes-title-container">
              <p className="title">Product owners</p>
              <div className="search-button-container">
                <Search
                  className="standard-searchbox"
                  allowClear
                  prefix={
                    <SearchOutlined className="standard-searchbox-prefix" />
                  }
                  value={attributeSearchValue}
                  placeholder="Search attribute"
                  disabled={
                    productAttributeLoading ||
                    !selectedProductAttributes?.length > 0
                  }
                  onChange={(event) => {
                    setCurrentPage(1);
                    setAttributeSearchValue(event.target?.value);
                    if (event.target?.value) {
                      setFilteredSelectedProductAttributes(
                        selectedProductAttributes?.filter((item) => {
                          return item?.productAttributeName
                            ?.toLowerCase()
                            ?.includes(
                              event.target?.value?.trimStart()?.toLowerCase()
                            );
                        })
                      );
                    } else {
                      setFilteredSelectedProductAttributes(
                        selectedProductAttributes
                      );
                    }
                  }}
                />
                {updatedAttributes?.length > 0 ? (
                  <Badge
                    dot
                    color="#124172"
                    style={{
                      height: "8px",
                      width: "8px",
                    }}
                  >
                    {" "}
                  </Badge>
                ) : null}
                <Button
                  className="standard-button secondary-orange-button"
                  loading={saveProductAttributesLoading}
                  onClick={() => {
                    if (updatedAttributes?.length > 0) {
                      SaveProductAttributes();
                    }
                  }}
                  disabled={roAdmin}
                >
                  Save
                </Button>
                <Button
                  className="standard-button secondary-orange-button"
                  onClick={showExistingContactDrawer}
                  disabled={roAdmin}
                >
                  Add existing contact
                </Button>
                <Button
                  className="standard-button primary-orange-button"
                  onClick={showAddContactDrawer}
                  disabled={roAdmin}
                >
                  Add new contact
                </Button>
              </div>
            </div>
            <Row gutter={[20, 20]}>
              <Col span={24} xs={24} sm={24} md={8} lg={7}>
                <div className="products-contact-and-search-wrapper">
                  <Search
                    className="standard-searchbox products-contact-searchbox"
                    allowClear
                    prefix={
                      <SearchOutlined className="standard-searchbox-prefix" />
                    }
                    placeholder="Search contact"
                    disabled={!productContacts?.length > 0}
                    onChange={(event) => {
                      searchContact(event.target.value);
                      setContactSearchValue(event.target.value);
                    }}
                  />

                  <div className="products-contact-container">
                    {contactLoading ? (
                      [...Array(5).keys()].map(() => {
                        return (
                          <div className="contacts-product-detail-container">
                            <div className="product-wrapper">
                              <Skeleton.Avatar active shape="circle" />
                              <Skeleton.Input className="product-name" active />
                            </div>
                            {/* <div className="delete-icon-container">
                              <Skeleton.Button
                                active
                                shape="default"
                                style={{ width: "40px" }}
                              />
                            </div> */}
                          </div>
                        );
                      })
                    ) : filteredProductContacts?.length > 0 ? (
                      filteredProductContacts?.map((item) => {
                        return (
                          <div
                            className="contacts-product-detail-container"
                            onClick={async () => {
                              setAttributeSearchValue("");
                              setProductAttributeLoading(true);
                              setCurrentPage(1);
                              setSelectedContact(item);
                              const productAttributes =
                                await getProductAttributesInstanceDataId(item);
                              setSelectedProductAttributes(productAttributes);
                              setFilteredSelectedProductAttributes(
                                productAttributes
                              );
                              setProductAttributeLoading(false);
                            }}
                          >
                            <div className="product-wrapper">
                              <Typography.Paragraph className="product-image">
                                {item?.ContactName?.slice(0, 1)?.toUpperCase()}
                              </Typography.Paragraph>
                              <Typography.Paragraph
                                className={`product-name ${
                                  selectedContact?.id === item?.id
                                    ? "highlight-product"
                                    : ""
                                }`}
                                ellipsis={{
                                  rows: 1,
                                }}
                              >
                                {item?.ContactName}
                              </Typography.Paragraph>
                            </div>
                            {/* <div className="delete-icon-container">
                              <img
                                className={`delete-icon ${
                                  productAttributeLoading || roAdmin
                                    ? "disable-pointer-events"
                                    : ""
                                }`}
                                src={deleteIcon3}
                                alt="delete-icon"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setSelectedProductContactToDelete(item);
                                  setShowDeleteProductContactModal(true);
                                }}
                              />
                            </div> */}
                            {selectedContact?.id === item?.id ? (
                              <div className="highlighted-bar"></div>
                            ) : null}
                          </div>
                        );
                      })
                    ) : (
                      <Empty
                        description="No contacts found!"
                        style={{
                          width: "100%",
                          minHeight: "300px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          margin: "0px",
                        }}
                      />
                    )}
                  </div>
                </div>
                {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    marginTop: "10px",
                  }}
                >
                  <p
                    style={{
                      marginBottom: "0px",
                      color: "#959595",
                      fontSize: "16px",
                    }}
                  >
                    100 contacts
                  </p>
                  <div style={{ display: "flex", gap: "12px" }}>
                    <ArrowLeftOutlined
                      style={{ color: "#959595", fontWeight: "700" }}
                    />
                    <ArrowRightOutlined style={{ color: "#959595" }} />
                  </div>
                </div> */}
              </Col>
              <Col span={24} xs={24} sm={24} md={16} lg={17}>
                <Form
                  layout="vertical"
                  className="contact-product-attribute-form"
                  requiredMark={false}
                >
                  {productAttributeLoading ? (
                    <Row gutter={[20, 40]} style={{ marginBottom: "20px" }}>
                      {[...Array(20).keys()].map(() => {
                        return (
                          <Col
                            span={24}
                            xs={24}
                            sm={24}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={8}
                          >
                            <Skeleton.Input
                              className="initial-skeleton-loader"
                              active={true}
                            />
                          </Col>
                        );
                      })}
                    </Row>
                  ) : filteredSelectedProductAttributes?.length > 0 ? (
                    <Row gutter={[20, 0]}>
                      {filteredSelectedProductAttributes
                        ?.slice(20 * (currentPage - 1), 20 * currentPage)
                        ?.map((item) => {
                          return (
                            <Col
                              span={24}
                              xs={24}
                              sm={24}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={8}
                              key={item.attributeId}
                            >
                              <Form.Item
                                label={item?.productAttributeName}
                                className="contact-attribute-form-item"
                                name={item?.dataClassInstanceAttributeId}
                                initialValue={item?.value}
                              >
                                <Input
                                  className="standard-input"
                                  disabled={roAdmin}
                                  value={item?.value}
                                  placeholder={`Enter ${item?.productAttributeName}`}
                                  onChange={(event) => {
                                    const updatedAttribute =
                                      updatedAttributes?.find(
                                        (attributeObject) =>
                                          attributeObject?.dataClassInstanceAttributeId ===
                                          item?.dataClassInstanceAttributeId
                                      );
                                    if (updatedAttribute) {
                                      setUpdatedAttributes(
                                        updatedAttributes?.map(
                                          (attributeObject) => {
                                            if (
                                              attributeObject?.dataClassInstanceAttributeId ===
                                              item?.dataClassInstanceAttributeId
                                            ) {
                                              attributeObject.value =
                                                event.target.value;
                                              return attributeObject;
                                            }
                                            return attributeObject;
                                          }
                                        )
                                      );
                                    } else {
                                      item.value = event.target.value;
                                      setUpdatedAttributes([
                                        ...updatedAttributes,
                                        item,
                                      ]);
                                    }
                                  }}
                                />
                              </Form.Item>
                            </Col>
                          );
                        })}
                    </Row>
                  ) : (
                    <Empty
                      description="No data found!"
                      style={{
                        width: "100%",
                        minHeight: "400px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    />
                  )}
                </Form>
                <div className="contact-product-attribute-pagination-container">
                  <p className="attribute-text">Showing 20 per page</p>
                  <Pagination
                    className="contact-product-attribute-pagination"
                    current={currentPage}
                    onChange={contactProductAttributePageChange}
                    total={filteredSelectedProductAttributes?.length}
                    itemRender={itemRender}
                    pageSize={20}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      {/* <div className="detailPage">
        <div className="formBox">
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              marginBottom: "10px",
              padding: "0 10px 10px 10px",
              alignItems: "center",
              borderBottom: "3px solid rgb(239, 244, 249)",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <h2
                style={{
                  paddingLeft: "10px",
                  color: "#3B84D1",
                  fontWeight: "600",
                  marginBottom: 0,
                }}
              >
                {location?.state?.productData?.pname}
              </h2>
            </div>
          </div>
        </div>

        <div
          className="productBox contactBox border"
          style={{
            width: "100%",
            background: "white",
            padding: "0px",
            marginTop: 10,
            borderRadius: 7,
          }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              padding: "10px 10px 7px 10px",
              alignItems: "center",
              gap: 8,
              borderBottom: "3px solid rgb(239, 244, 249)",
            }}
          >
            <h3 style={{ marginBottom: 0, color: "#252525", fontWeight: 600 }}>
              {location?.state?.productData?.pname} Owners
              {!skeletonLoader && loading && (
                <Loading3QuartersOutlined
                  spin
                  style={{ marginLeft: 10, fontSize: 18 }}
                />
              )}
            </h3>

            <div className="product-detail-container">
              <div
                style={{
                  position: "relative",
                  width: "100%",
                }}
              >
                <Search
                  prefix={<SearchOutlined />}
                  placeholder="Search Contacts"
                  style={{ width: "100%" }}
                  onSearch={(e) => handleSearchChange(e)}
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </div>
              <Button
                onClick={showAddContactDrawer}
                disabled={roAdmin}
                style={{
                  background: "#F37064",
                  color: "white",
                  borderRadius: 7,
                }}
              >
                Add New Contact
              </Button>
              <Button
                onClick={showExistingContactDrawer}
                disabled={roAdmin}
                style={{
                  background: "#F37064",
                  color: "white",
                  borderRadius: 7,
                }}
              >
                Add Existing Contact
              </Button>
            </div>
          </div>

          <div style={{ background: "white", padding: 10 }}>
            {skeletonLoader ? (
              <Skeleton active paragraph={{ rows: 5 }} />
            ) : (
              <Table
                scroll={{ y: 300, x: "130vw" }}
                className="productTable"
                columns={columns}
                dataSource={tableData}
                onChange={(e) => handlePagination(e)}
                pagination={{ defaultPageSize: 5 }}
                bordered
              />
            )}
          </div>
        </div>
      </div> */}

      {/* MVP commented code  */}
      {/* <AttributeDrawer /> */}

      {/* edit product attribute drawer  */}
      {/* <Drawer
        className="addProperty"
        width={430}
        title="Edit product attributes"
        placement="right"
        onClose={onClose}
        visible={editProductAttributeDrawer}
        maskClosable={false}
      >
        <Form form={form} layout="vertical">
          <Row gutter={[10, 10]}>
            <Col span={10} lg={10} md={10} sm={24} xs={24}>
              <Form.Item
                label={
                  <span style={{ color: "#124173", fontWeight: 600 }}>
                    Attribute Title
                  </span>
                }
                style={{ width: "100%" }}
              >
                <Input
                  disabled
                  style={{
                    cursor: "default",
                    background: "white",
                    color: "black",
                  }}
                  value="Allergy Free"
                />
              </Form.Item>
            </Col>

            <Col span={14} lg={14} md={14} sm={24} xs={24}>
              <Form.Item
                label={
                  <span style={{ color: "#124173", fontWeight: 600 }}>
                    Attribute Type
                  </span>
                }
                style={{ width: "100%", display: "flex" }}
              >
                <Input value="Text field" style={{ width: "auto" }} />
                <Button type="text">
                  <DeleteFilled style={{ color: "#CACACA" }} />
                </Button>
              </Form.Item>
            </Col>

            <Col span={10} lg={10} md={10} sm={24} xs={24}>
              <Form.Item style={{ width: "100%" }}>
                <Input
                  disabled
                  style={{
                    cursor: "default",
                    background: "white",
                    color: "black",
                  }}
                  value="Prone to Hip Dysplasia"
                />
              </Form.Item>
            </Col>

            <Col span={14} lg={14} md={14} sm={24} xs={24}>
              <Form.Item style={{ width: "100%", display: "flex" }}>
                <Input value="Text field" style={{ width: "auto" }} />
                <Button type="text">
                  <DeleteFilled style={{ color: "#CACACA" }} />
                </Button>
              </Form.Item>
            </Col>

            <Col span={10} lg={10} md={10} sm={24} xs={24}>
              <Form.Item style={{ width: "100%" }}>
                <Input
                  disabled
                  style={{
                    cursor: "default",
                    background: "white",
                    color: "black",
                  }}
                  value="Energy Level"
                />
              </Form.Item>
            </Col>

            <Col span={14} lg={14} md={14} sm={24} xs={24}>
              <Form.Item style={{ width: "100%" }}>
                <Input value="Date Selection" style={{ width: "auto" }} />
                <Button type="text">
                  <DeleteFilled style={{ color: "#CACACA" }} />
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <div
          style={{
            position: "absolute",
            right: 0,
            bottom: 0,
            left: 0,
            width: "auto",
            borderTop: "1px solid #e9e9e9",
            fontSize: "18px",
            padding: "10px 16px",
            background: "#EFF4F9",
            textAlign: "right",
            display: "flex",
          }}
        >
          <Button
            className="btn-secondary"
            style={{
              marginRight: 8,
              width: "auto",
              border: "1px solid #252525",
              color: "black",
            }}
            size="middle"
            onClick={() => {
              setEditProductAttributeDrawer(false);
            }}
          >
            Add new attribute
          </Button>
          <Button
            type="primary"
            style={{ width: "auto" }}
            htmlType="submit"
            className="btn-primary"
            size="middle"
          >
            Save
          </Button>
        </div>
      </Drawer> */}

      {/* <Drawer
        width={430}
        title="Add new product attribute"
        placement="right"
        onClose={showonClose}
        loading={loading}
        visible={showAddAttribute}
      >
        <Form
          onFinish={handleAttribute}
          form={form}
          loading={loading}
          layout="vertical"
        >
          <Form.Item
            label="Attribute Title"
            name="AttributeTitle"
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "This field can not be empty!",
              },
            ]}
          >
            <Input placeholder="Enter attribute title" />
          </Form.Item>

          <Form.Item
            label="Attribute Type"
            style={{ width: "100%" }}
            name="AttributeType"
            rules={[
              {
                required: true,
                message: "This field can not be empty!",
              },
            ]}
          >
            <Radio.Group>
              <Space direction="vertical">
                <Radio value={1}>Long</Radio>
                <Radio value={2}>Date Selection</Radio>
                <Radio value={3}>String</Radio>
                <Radio value={4}>Boolean</Radio>
                <Radio value={5}>Integer</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>

          <div
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              left: 0,
              width: "auto",
              borderTop: "1px solid #e9e9e9",
              fontSize: "18px",
              padding: "10px 16px",
              background: "#EFF4F9",
              textAlign: "right",
              display: "flex",
            }}
          >
            <Button
              className="btn-secondary"
              style={{
                marginRight: 8,
                width: "auto",
                border: "1px solid #252525",
                color: "black",
              }}
              size="middle"
              onClick={showonClose}
            >
              Cancel
            </Button>
            <Button
              // onClick={onSubmitAttribute}
              type="primary"
              loading={loading}
              style={{ width: "auto" }}
              htmlType="submit"
              className="btn-primary"
              size="middle"
            >
              Add
            </Button>
          </div>
        </Form>
      </Drawer> */}

      {/* Add new contact product attribute drawer  */}
      <Drawer
        className="addProperty"
        name="dynamic_form_nest_item"
        width={430}
        title="Add New Contact"
        placement="right"
        onClose={ContactonClose}
        visible={addContactProductAttributeDrawer}
        maskClosable={false}
      >
        <Form
          form={form}
          layout="vertical"
          style={{ paddingBottom: 60 }}
          onFinish={handleAdd}
          requiredMark={false}
          className="add-contact-form"
        >
          <Form.Item
            label="First Name *"
            style={{ width: "100%" }}
            name="firstName"
            rules={[
              {
                required: true,
                message: "This field can not be empty!",
              },
            ]}
          >
            <Input className="standard-input" placeholder="Enter First Name" />
          </Form.Item>
          <Form.Item
            label="Last Name *"
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "This field can not be empty!",
              },
            ]}
            name="lastName"
          >
            <Input className="standard-input" placeholder="Enter Last Name" />
          </Form.Item>
          <Form.Item
            label="Phone Number *"
            style={{ width: "100%" }}
            name="phoneNumber"
            rules={[
              {
                required: true,
                message: "This field can not be empty!",
              },
            ]}
          >
            <Input
              className="standard-input"
              placeholder="Enter Phone Number"
            />
          </Form.Item>
          <Form.Item
            label="Email Address"
            style={{ width: "100%" }}
            name="emailAddress"
          >
            <Input
              className="standard-input"
              placeholder="Enter Email Address"
            />
          </Form.Item>

          <div
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              left: 0,
              width: "auto",
              borderTop: "1px solid #e9e9e9",
              fontSize: "18px",
              padding: "10px 16px",
              background: "#EFF4F9",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p style={{ fontSize: 14, color: "#6B7A89" }}>
              All these contacts will be added in Contact manager as well
            </p>
            <div style={{ display: "flex" }}>
              <Button
                className="standard-button tertiary-button"
                style={{ marginRight: 8, width: "48%" }}
                onClick={ContactonClose}
                disabled={addLoading}
              >
                Cancel
              </Button>
              <Button
                className="standard-button primary-orange-button"
                type="primary"
                style={{ width: "48%" }}
                htmlType="submit"
                loading={addLoading}
              >
                Add
              </Button>
            </div>
          </div>
        </Form>
      </Drawer>

      {/* Add existing contact product attribute drawer  */}
      <Drawer
        className="addProperty"
        name="dynamic_form_nest_item"
        width={430}
        title="Add Existing Contact"
        placement="right"
        onClose={ExistingContactonClose}
        visible={addExistingContactProductAttributeDrawer}
        maskClosable={false}
      >
        <Form
          form={existingCustomerForm}
          layout="vertical"
          style={{ paddingBottom: 60 }}
          onFinish={handleAddExitisingCustomer}
        >
          <Form.Item
            name="stages"
            className="existing-contact-select"
            label={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <strong style={{ fontSize: "16px" }}>
                  Add existing contact
                </strong>
                {fuzzySearchLoading ? (
                  <SyncOutlined
                    spin
                    style={{ marginLeft: "5px", fontSize: "20px" }}
                  />
                ) : null}
              </div>
            }
          >
            <Select
              mode="multiple"
              showArrow
              allowClear
              style={{ width: "100%" }}
              className="add-existing-contact-multi-select"
              placeholder="Select contacts"
              size="large"
              filterOption={false}
              onSearch={setFuzzySearchValue}
              onChange={(e) => handleAll(e)}
              options={searchedContacts}
              searchValue={fuzzySearchValue}
            />
          </Form.Item>

          <div
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              left: 0,
              width: "auto",
              borderTop: "1px solid #e9e9e9",
              fontSize: "18px",
              padding: "10px 16px",
              background: "#EFF4F9",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ display: "flex" }}>
              <Button
                className="standard-button tertiary-button"
                style={{ marginRight: 8, width: "48%" }}
                onClick={ExistingContactonClose}
                disabled={existingLoading}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                style={{ width: "48%" }}
                htmlType="submit"
                className="standard-button primary-orange-button"
                loading={existingLoading}
              >
                Add
              </Button>
            </div>
          </div>
        </Form>
      </Drawer>

      {/* delete modal  */}
      {/* <Modal
        visible={isInviteUserModelOpen}
        className="select-journey"
        footer={null}
        width={370}
        centered
        onCancel={() => {
          setisInviteUserModelOpen(false);
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            padding: "0px 10px 30px",
          }}
        >
          <h3 style={{ color: "#124173", margin: "10px 0 20px" }}>
            Select Journey
          </h3>

          <Radio.Group>
            <Space direction="vertical">
              <Radio value={1}>Product Onboarding</Radio>
              <Radio value={2}>Product Usage</Radio>
              <Radio value={3}>Weather Conditions</Radio>
              <Radio value={4}>Enjoyment</Radio>
              <Radio value={5}>Maintenance</Radio>
            </Space>
          </Radio.Group>

          <Button
            style={{
              marginRight: 8,
              marginTop: 20,
              width: "100%",
              border: "1px solid #F37064",
              background: "#F37064",
              color: "white",
              borderRadius: "100px",
            }}
            size="middle"
            onClick={() => {
              setisInviteUserModelOpen(false);
            }}
          >
            Link To A Journey
          </Button>
        </div>
      </Modal> */}
      {/* <Modal
        centered
        width={894}
        visible={isQuickMapVisible}
        footer={null}
        maskClosable={true}
        className="quick-map-modal-container"
        closable={false}
        onCancel={() => setisQuickMapVisible(false)}
      >
        <div className="quick-map-modal">
          <div className="shopify-features-container">
            <Select
              className="integration-platforms"
              defaultValue="shopify"
              onChange={selectedPlatform}
            >
              <Option value="shopify" className="platform-type-option">
                <img src={shopifyIcon} alt="" />
              </Option>
              <Option value="prestashop" className="platform-type-option">
                <img src={prestashopIcon} alt="" />
              </Option>
              <Option value="big_commerce" className="platform-type-option">
                <img src={bigcommerceIcon} alt="" />
              </Option>
            </Select>
            <Select
              className="shopify-product-type-dropdown"
              placeholder="Select Product Type"
              onChange={selectedProductType}
            >
              <Option value="Cameras_Optics" className="products-type-option">
                Cameras & Optics
              </Option>
              <Option value="Electronics" className="products-type-option">
                Electronics
              </Option>
              <Option value="Food_Beverages" className="products-type-option">
                Food Beverages
              </Option>
              <Option value="Furniture" className="products-type-option">
                Furniture
              </Option>
              <Option value="Hardware" className="products-type-option">
                Hardware
              </Option>
              <Option value="Health_Beauty" className="products-type-option">
                Health & Beauty
              </Option>
              <Option value="Home_Garden" className="products-type-option">
                Home & Garden
              </Option>
              <Option value="Luggage_Bags" className="products-type-option">
                Luggage & Bags
              </Option>
            </Select>
            <div className="shopify-product-search">
              <Search
                prefix={
                  <SearchOutlined
                    style={{ fontSize: "14px", color: "#252525" }}
                  />
                }
                placeholder="Search your selected Shopify products"
                onSearch={shopifyProductSearch}
                type="search"
                value={shopifySearchValue}
                onChange={(e) => setShopifySearchValue(e.target.value)}
              />
            </div>
          </div>
          {shopifySelectedProductType !== "" ? (
            <Row>
              <Col span={24} className="shopify-box">
                <p className="shopify-text">
                  Choose <span className="title-focus">Shopify products</span>{" "}
                  to map with Blustream
                </p>
                <Checkbox.Group
                  style={{
                    width: "100%",
                  }}
                  onChange={(event) => selectedShopifyProduct(event)}
                  value={selectedShopifyProductsValue}
                >
                  <Row style={{ margin: "0px 18px" }}>
                    <Col
                      className="shopify-product-container"
                      span={24}
                      xs={24}
                      sm={12}
                      xl={12}
                      xxl={8}
                    >
                      <Checkbox value={1} />
                      <div className="shopify-product">
                        <img
                          className="shopify-product-image"
                          src={guitar1}
                          alt=""
                        />
                        <span className="shopify-product-name">guitar1</span>
                        {true ? (
                          <img
                            className="already-mapped-product"
                            src={blustreamLogo}
                            alt=""
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </Col>
                    <Col
                      className="shopify-product-container"
                      span={24}
                      xs={24}
                      sm={12}
                      xl={12}
                      xxl={8}
                    >
                      <Checkbox value={2} />
                      <div className="shopify-product">
                        <img
                          className="shopify-product-image"
                          src={guitar2}
                          alt=""
                        />
                        <span className="shopify-product-name">guitar2</span>
                      </div>
                    </Col>
                    <Col
                      className="shopify-product-container"
                      span={24}
                      xs={24}
                      sm={12}
                      xl={12}
                      xxl={8}
                    >
                      <Checkbox value={3} />
                      <div className="shopify-product">
                        <img
                          className="shopify-product-image"
                          src={guitar3}
                          alt=""
                        />
                        <span className="shopify-product-name">guitar3</span>
                      </div>
                    </Col>
                    <Col
                      className="shopify-product-container"
                      span={24}
                      xs={24}
                      sm={12}
                      xl={12}
                      xxl={8}
                    >
                      <Checkbox value={4} />
                      <div className="shopify-product">
                        <img
                          className="shopify-product-image"
                          src={keyboard}
                          alt=""
                        />
                        <span className="shopify-product-name">keyboard</span>
                        {true ? (
                          <img
                            className="already-mapped-product"
                            src={blustreamLogo}
                            alt=""
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </Col>
                    <Col
                      className="shopify-product-container"
                      span={24}
                      xs={24}
                      sm={12}
                      xl={12}
                      xxl={8}
                    >
                      <Checkbox value={5} />
                      <div className="shopify-product">
                        <img
                          className="shopify-product-image"
                          src={dog1}
                          alt=""
                        />
                        <span className="shopify-product-name">dog1</span>
                      </div>
                    </Col>
                    <Col
                      className="shopify-product-container"
                      span={24}
                      xs={24}
                      sm={12}
                      xl={12}
                      xxl={8}
                    >
                      <Checkbox value={6} />
                      <div className="shopify-product">
                        <img
                          className="shopify-product-image"
                          src={dog2}
                          alt=""
                        />
                        <span className="shopify-product-name">dog2</span>
                        {true ? (
                          <img
                            className="already-mapped-product"
                            src={blustreamLogo}
                            alt=""
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </Col>
                    <Col
                      className="shopify-product-container"
                      span={24}
                      xs={24}
                      sm={12}
                      xl={12}
                      xxl={8}
                    >
                      <Checkbox value={7} />
                      <div className="shopify-product">
                        <img
                          className="shopify-product-image"
                          src={dog4}
                          alt=""
                        />
                        <span className="shopify-product-name">dog4</span>
                      </div>
                    </Col>
                    <Col
                      className="shopify-product-container"
                      span={24}
                      xs={24}
                      sm={12}
                      xl={12}
                      xxl={8}
                    >
                      <Checkbox value={8} />
                      <div className="shopify-product">
                        <img
                          className="shopify-product-image"
                          src={guitar1}
                          alt=""
                        />
                        <span className="shopify-product-name">guitar1</span>
                      </div>
                    </Col>
                    <Col
                      className="shopify-product-container"
                      span={24}
                      xs={24}
                      sm={12}
                      xl={12}
                      xxl={8}
                    >
                      <Checkbox value={9} />
                      <div className="shopify-product">
                        <img
                          className="shopify-product-image"
                          src={guitar2}
                          alt=""
                        />
                        <span className="shopify-product-name">guitar2</span>
                        {true ? (
                          <img
                            className="already-mapped-product"
                            src={blustreamLogo}
                            alt=""
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </Col>
                    <Col
                      className="shopify-product-container"
                      span={24}
                      xs={24}
                      sm={12}
                      xl={12}
                      xxl={8}
                    >
                      <Checkbox value={10} />
                      <div className="shopify-product">
                        <img
                          className="shopify-product-image"
                          src={guitar3}
                          alt=""
                        />
                        <span className="shopify-product-name">guitar3</span>
                      </div>
                    </Col>
                    <Col
                      className="shopify-product-container"
                      span={24}
                      xs={24}
                      sm={12}
                      xl={12}
                      xxl={8}
                    >
                      <Checkbox value={11} />
                      <div className="shopify-product">
                        <img
                          className="shopify-product-image"
                          src={keyboard}
                          alt=""
                        />
                        <span className="shopify-product-name">keyboard</span>
                        {true ? (
                          <img
                            className="already-mapped-product"
                            src={blustreamLogo}
                            alt=""
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </Col>
                    <Col
                      className="shopify-product-container"
                      span={24}
                      xs={24}
                      sm={12}
                      xl={12}
                      xxl={8}
                    >
                      <Checkbox value={12} />
                      <div className="shopify-product">
                        <img
                          className="shopify-product-image"
                          src={dog1}
                          alt=""
                        />
                        <span className="shopify-product-name">dog1</span>
                      </div>
                    </Col>
                    <Col
                      className="shopify-product-container"
                      span={24}
                      xs={24}
                      sm={12}
                      xl={12}
                      xxl={8}
                    >
                      <Checkbox value={13} />
                      <div className="shopify-product">
                        <img
                          className="shopify-product-image"
                          src={dog2}
                          alt=""
                        />
                        <span className="shopify-product-name">dog2</span>
                      </div>
                    </Col>
                    <Col
                      className="shopify-product-container"
                      span={24}
                      xs={24}
                      sm={12}
                      xl={12}
                      xxl={8}
                    >
                      <Checkbox value={14} />
                      <div className="shopify-product">
                        <img
                          className="shopify-product-image"
                          src={dog4}
                          alt=""
                        />
                        <span className="shopify-product-name">dog4</span>
                      </div>
                    </Col>
                    <Col
                      className="shopify-product-container"
                      span={24}
                      xs={24}
                      sm={12}
                      xl={12}
                      xxl={8}
                    >
                      <Checkbox value={15} />
                      <div className="shopify-product">
                        <img
                          className="shopify-product-image"
                          src={guitar1}
                          alt=""
                        />
                        <span className="shopify-product-name">guitar1</span>
                        {true ? (
                          <img
                            className="already-mapped-product"
                            src={blustreamLogo}
                            alt=""
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </Col>
                    <Col
                      className="shopify-product-container"
                      span={24}
                      xs={24}
                      sm={12}
                      xl={12}
                      xxl={8}
                    >
                      <Checkbox value={16} />
                      <div className="shopify-product">
                        <img
                          className="shopify-product-image"
                          src={guitar2}
                          alt=""
                        />
                        <span className="shopify-product-name">guitar2</span>
                      </div>
                    </Col>
                    <Col
                      className="shopify-product-container"
                      span={24}
                      xs={24}
                      sm={12}
                      xl={12}
                      xxl={8}
                    >
                      <Checkbox value={17} />
                      <div className="shopify-product">
                        <img
                          className="shopify-product-image"
                          src={guitar3}
                          alt=""
                        />
                        <span className="shopify-product-name">guitar3</span>
                      </div>
                    </Col>
                    <Col
                      className="shopify-product-container"
                      span={24}
                      xs={24}
                      sm={12}
                      xl={12}
                      xxl={8}
                    >
                      <Checkbox value={18} />
                      <div className="shopify-product">
                        <img
                          className="shopify-product-image"
                          src={keyboard}
                          alt=""
                        />
                        <span className="shopify-product-name">keyboard</span>
                      </div>
                    </Col>
                    <Col
                      className="shopify-product-container"
                      span={24}
                      xs={24}
                      sm={12}
                      xl={12}
                      xxl={8}
                    >
                      <Checkbox value={19} />
                      <div className="shopify-product">
                        <img
                          className="shopify-product-image"
                          src={dog1}
                          alt=""
                        />
                        <span className="shopify-product-name">dog1</span>
                      </div>
                    </Col>
                    <Col
                      className="shopify-product-container"
                      span={24}
                      xs={24}
                      sm={12}
                      xl={12}
                      xxl={8}
                    >
                      <Checkbox value={20} />
                      <div className="shopify-product">
                        <img
                          className="shopify-product-image"
                          src={dog2}
                          alt=""
                        />
                        <span className="shopify-product-name">dog2</span>
                      </div>
                    </Col>
                    <Col
                      className="shopify-product-container"
                      span={24}
                      xs={24}
                      sm={12}
                      xl={12}
                      xxl={8}
                    >
                      <Checkbox value={21} />
                      <div className="shopify-product">
                        <img
                          className="shopify-product-image"
                          src={dog4}
                          alt=""
                        />
                        <span className="shopify-product-name">dog4</span>
                      </div>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </Col>
              <Col span={24} className="select-all-column">
                <div className="select-all-wrapper">
                  <Checkbox
                    value={isAllSelected}
                    className="select-all-checkbox"
                    onChange={() => setIsAllSelected(true)}
                  />
                  <span className="select-all-text">Select all</span>
                </div>
              </Col>
            </Row>
          ) : (
            <Row className="shopify-box">
              <Col span={24} className="shopify-products-container">
                <div className="initial-content">
                  <img
                    className="product-background-img"
                    src={backgroundIcon}
                    alt=""
                  />
                  <p className="background-text-dark">
                    Select product type or search for shopify products
                  </p>
                  <p className="background-text-light">
                    Browse through different products and multiselect them to
                    map with your blustream product!
                  </p>
                </div>
              </Col>
            </Row>
          )}
          <Col span={24} className="quick-map-buttons-container">
            <Button
              className="quick-map-now-button"
              onClick={() => {
                setWarningModalVisible(true);
              }}
            >
              Map now
            </Button>
            <Button className="quick-map-cancel-button">Cancel</Button>
          </Col>
        </div>
      </Modal> */}
      {/* <Modal
        centered
        visible={warningModalVisible}
        footer={null}
        className="mapped-warning-modal-container"
        onCancel={() => hideWarningModal()}
      >
        <div className="mapped-warning-modal">
          <img src={glowingBulbIcon} alt="" />
          <p className="warning-modal-dark-text">
            Few Shopify selected products are already mapped with other
            blustream product!
          </p>
          <p className="already-mapped-product-names">(Guiter,Drums)</p>
          <p className="warning-modal-light-text">
            Do you wish to overwrite those?
          </p>
          <div className="mapped-warning-modal-container">
            <Button
              className="mapped-warning-modal-denied-button"
              onClick={() => hideWarningModal()}
            >
              No
            </Button>
            <Button
              className="mapped-warning-modal-confirm-button"
              type="primary"
              onClick={() => overrideProducts()}
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal> */}
      {/* <Modal
        centered
        visible={successModalVisible}
        footer={null}
        className="success-modal-container"
        onCancel={() => hideSuccessModal()}
      >
        <div className="success-modal">
          <img src={magicHatIcon} alt="" />
          <p className="success-modal-dark-text">
            Your shopify products were mapped successfully with Blustream!
          </p>
          <div className="success-modal-container">
            <Button
              className="success-modal-confirm-button"
              type="primary"
              onClick={() => hideSuccessModal()}
            >
              Ok, Thank you!
            </Button>
          </div>
        </div>
      </Modal> */}

      {/* delete products contact modal */}
      <Modal
        centered
        visible={showDeleteProductContactModal}
        closable={false}
        footer={null}
        className="delete-contacts-product-modal"
      >
        <div className="delete-content-container">
          <img src={glowingBulbIcon} alt="" />
          <p className="contacts-product-delete-modal-dark-text">
            {`Are you sure that you want to remove this contact ${selectedProductContactToDelete?.ContactName} ?`}
          </p>
          <p className="contacts-product-delete-modal-light-text">
            Once deleted, all contact attributes linked with the product will
            also be removed!
          </p>
          <div className="delete-contacts-product-modal-button-wrapper">
            <Button
              className="standard-button primary-orange-button"
              type="primary"
              onClick={async () => {
                deleteContactFromProduct();
              }}
              loading={productContactDeleteLoading}
            >
              Yes, I understand
            </Button>
            <Button
              className="standard-button secondary-orange-button"
              onClick={() => setShowDeleteProductContactModal(false)}
            >
              No
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}
