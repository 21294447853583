/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "blustreamREST",
            "endpoint": "https://lqyp5w4dl7.execute-api.us-east-1.amazonaws.com/live",
            "region": "us-east-1"
        },
        {
            "name": "publicshorturl",
            "endpoint": "https://4drum263ua.execute-api.us-east-1.amazonaws.com/live",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://od3ajhqkm5bthkjyrx2czz5dye.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_appsync_apiKey": "da2-kpdpc23t7vfrpiyxtzo6zfnc3q",
    "aws_cognito_identity_pool_id": "us-east-1:1bfc8468-8b82-4d6e-bd6f-4c8d0a9b46f3",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_9F6ZzXiH9",
    "aws_user_pools_web_client_id": "vdokq6lsbnp3d4f0g1h1nt9qf",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "blustreamfrontend-20211126150112-hostingbucket-live",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d1dvoq6hqfqria.cloudfront.net",
    "aws_user_files_s3_bucket": "blustreamfrontenda4c26b55bbdb40489dfdc2867d6081155205-live",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
