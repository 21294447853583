import {
  Col,
  Row,
  Form,
  Input,
  Button,
  Checkbox,
  Select,
  Radio,
  Tooltip,
  TreeSelect,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { emailPattern } from "utility/Constants";
import { useSelector, useDispatch } from "react-redux";
import {
  setCurrentFormElementId,
  setActiveKey,
  setLogokey,
} from "../../store/actions/SettingAction";
import { invertColor } from "../../utility/LightenDarkenColor";
import { handleImagesOnLanguageChange } from "./../../commons/DropDownLang/HandleImageRender";
import marked from "marked";

export default function FormBody() {
  const {
    micrositeFormElements,
    currentFormElementId,
    templateName,
    micrositeDefaultStyle,
    logoKey,
  } = useSelector((state) => state.SettingReducer);
  const { roAdmin } = useSelector((state) => state.loginReducer);
  const { SHOW_PARENT } = TreeSelect;

  const [multiSelectValues, setmultiSelectValues] = useState([]);

  const dispatch = useDispatch();

  const inputElementClickHandler = (event, id) => {
    dispatch(setCurrentFormElementId(id));
    dispatch(setActiveKey("2"));
  };

  // this is for the multiple select dropdown
  const onChange = (newValue) => {
    setmultiSelectValues(newValue);
  };

  const dropdownObj = micrositeFormElements?.find((elementObj) => {
    return elementObj?.type === "multiselect-dropdown";
  });

  const tProps = {
    treeData: dropdownObj?.options,
    value: multiSelectValues,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: dropdownObj?.placeholder,
    showSearch: true,
  };

  const { Option } = Select;

  return (
    <div className="form-body">
      {templateName === "TWO_COLUMN_LEFT_FORM" ||
      templateName === "TWO_COLUMN_RIGHT_FORM" ? (
        <Row
          className="form"
          style={{
            boxShadow: "0px 1px 7px rgba(18, 65, 115, 0.2)",
            position: "relative",
          }}
        >
          <Col
            span={14}
            style={{
              padding: 15,
              position: "relative",
              backgroundColor:
                templateName === "TWO_COLUMN_LEFT_FORM"
                  ? micrositeDefaultStyle?.themeStyle?.backgroundColor
                  : micrositeDefaultStyle?.themeStyle?.secondaryBackgroundColor,
              backgroundImage: `url("${micrositeDefaultStyle?.themeStyle?.backgroundImageUrl}")`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: `${micrositeDefaultStyle?.themeStyle?.backgroundWidth}% ${micrositeDefaultStyle?.themeStyle?.backgroundHeight}%`,
            }}
            order={templateName === "TWO_COLUMN_LEFT_FORM" ? 1 : 2}
          >
            <Form layout="vertical" className="dynamic-form">
              <Row gutter={[10, 10]}>
                {micrositeFormElements &&
                  micrositeFormElements?.map((currentFormElement) => {
                    return (
                      <>
                        {currentFormElement?.type === "image" && (
                          <Col
                            id={currentFormElement?.id}
                            span={24}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={micrositeDefaultStyle?.logoUrl}
                              style={{
                                ...currentFormElement?.style,
                                height: micrositeDefaultStyle?.logoHeight
                                  ? micrositeDefaultStyle?.logoHeight
                                  : "30px",
                                width: micrositeDefaultStyle?.logoWidth
                                  ? micrositeDefaultStyle?.logoWidth
                                  : "auto",
                              }}
                              alt="logo"
                              onClick={() => {
                                if (!roAdmin) {
                                  dispatch(setActiveKey("3"));
                                  dispatch(setLogokey(!logoKey));
                                }
                              }}
                            />
                          </Col>
                        )}
                        {currentFormElement?.type === "heading" && (
                          <div
                            style={{ width: "100%" }}
                            onClick={(e, id = currentFormElement?.id) => {
                              if (!roAdmin) {
                                inputElementClickHandler(e, id);
                              }
                            }}
                          >
                            <h1
                              className="form-heading"
                              style={{
                                ...micrositeDefaultStyle?.headingStyle,
                                ...currentFormElement?.style,
                              }}
                              dangerouslySetInnerHTML={{
                                __html: marked(
                                  currentFormElement?.headingText
                                    ? currentFormElement?.headingText
                                    : ""
                                ),
                              }}
                            />
                          </div>
                        )}
                        {currentFormElement?.type === "sub-heading" && (
                          <div
                            style={{ width: "100%" }}
                            id={currentFormElement?.id}
                            onClick={(e, id = currentFormElement?.id) => {
                              if (!roAdmin) {
                                inputElementClickHandler(e, id);
                              }
                            }}
                          >
                            <h1
                              className="form-sub-heading"
                              style={{
                                ...micrositeDefaultStyle?.subHeadingStyle,
                                ...currentFormElement?.style,
                              }}
                              dangerouslySetInnerHTML={{
                                __html: marked(
                                  currentFormElement?.headingText
                                    ? currentFormElement?.headingText
                                    : ""
                                ),
                              }}
                            />
                          </div>
                        )}

                        {currentFormElement?.type === "default-input" && (
                          <>
                            <Col id={currentFormElement?.id} span={12}>
                              <Form.Item
                                name={currentFormElement?.label}
                                className="dynamic-form-item"
                                label={
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      minWidth: "100%",
                                    }}
                                  >
                                    <span
                                      style={{
                                        ...micrositeDefaultStyle?.questionStyle,
                                      }}
                                    >
                                      {currentFormElement?.label}
                                    </span>
                                    {currentFormElement.description ? (
                                      <Tooltip
                                        title={
                                          currentFormElement.description
                                            ? currentFormElement.description
                                            : ""
                                        }
                                      >
                                        <span>
                                          <InfoCircleOutlined
                                            style={{
                                              color:
                                                micrositeDefaultStyle
                                                  ?.questionStyle?.color,
                                              fontSize: "15px",
                                            }}
                                          />
                                        </span>
                                      </Tooltip>
                                    ) : null}
                                  </div>
                                }
                                rules={
                                  currentFormElement?.htmlType === "tel"
                                    ? [
                                        {
                                          required: true,
                                          message: (
                                            <span
                                              style={{
                                                color:
                                                  micrositeDefaultStyle?.errorMessageColor,
                                              }}
                                            >
                                              Please enter{" "}
                                              {currentFormElement?.label.toLowerCase()}
                                              !
                                            </span>
                                          ),
                                        },
                                        {
                                          pattern:
                                            /^([0|\\+[0-9]{1,3})?([0-9]{10})$/,
                                          message: (
                                            <span
                                              style={{
                                                color:
                                                  micrositeDefaultStyle?.errorMessageColor,
                                              }}
                                            >
                                              Please enter valid phone no.!
                                            </span>
                                          ),
                                        },
                                      ]
                                    : currentFormElement?.htmlType === "email"
                                    ? [
                                        {
                                          required: true,
                                          message: (
                                            <span
                                              style={{
                                                color:
                                                  micrositeDefaultStyle?.errorMessageColor,
                                              }}
                                            >
                                              Please enter{" "}
                                              {currentFormElement?.label.toLowerCase()}
                                              !
                                            </span>
                                          ),
                                        },
                                        {
                                          pattern: emailPattern,
                                          message: (
                                            <span
                                              style={{
                                                color:
                                                  micrositeDefaultStyle?.errorMessageColor,
                                              }}
                                            >
                                              Please enter a valid email
                                              address!
                                            </span>
                                          ),
                                        },
                                      ]
                                    : [
                                        {
                                          required: true,
                                          message: (
                                            <span
                                              style={{
                                                color:
                                                  micrositeDefaultStyle?.errorMessageColor,
                                              }}
                                            >
                                              Please enter{" "}
                                              {currentFormElement?.label.toLowerCase()}
                                              !
                                            </span>
                                          ),
                                        },
                                      ]
                                }
                              >
                                <Input
                                  placeholder={currentFormElement?.placeholder}
                                  style={{
                                    ...micrositeDefaultStyle?.answerStyle,
                                    ...micrositeDefaultStyle?.fieldStyle,
                                  }}
                                />
                              </Form.Item>
                            </Col>
                          </>
                        )}

                        {currentFormElement?.type === "default-email" && (
                          <>
                            <Col
                              id={currentFormElement?.id}
                              span={12}
                              onClick={(e, id = currentFormElement?.id) => {
                                if (!roAdmin) {
                                  inputElementClickHandler(e, id);
                                }
                              }}
                              style={{
                                outline:
                                  currentFormElement?.id ===
                                  currentFormElementId
                                    ? "2px dashed black"
                                    : "",
                                backgroundColor: currentFormElement?.isHidden
                                  ? "#74747426"
                                  : "",
                              }}
                            >
                              <Form.Item
                                name={currentFormElement?.label}
                                className="dynamic-form-item"
                                label={
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      minWidth: "100%",
                                    }}
                                  >
                                    <span
                                      style={{
                                        ...micrositeDefaultStyle?.questionStyle,
                                      }}
                                    >
                                      {currentFormElement?.label}
                                    </span>
                                    {currentFormElement.description ? (
                                      <Tooltip
                                        title={
                                          currentFormElement.description
                                            ? currentFormElement.description
                                            : ""
                                        }
                                      >
                                        <span>
                                          <InfoCircleOutlined
                                            style={{
                                              color:
                                                micrositeDefaultStyle
                                                  ?.questionStyle?.color,
                                              fontSize: "15px",
                                            }}
                                          />
                                        </span>
                                      </Tooltip>
                                    ) : null}
                                  </div>
                                }
                                rules={[
                                  {
                                    required: currentFormElement?.isRequired,
                                    message: (
                                      <span
                                        style={{
                                          color:
                                            micrositeDefaultStyle?.errorMessageColor,
                                        }}
                                      >
                                        Please enter{" "}
                                        {currentFormElement?.label.toLowerCase()}
                                        !
                                      </span>
                                    ),
                                  },
                                  {
                                    pattern: emailPattern,
                                    message: (
                                      <span
                                        style={{
                                          color:
                                            micrositeDefaultStyle?.errorMessageColor,
                                        }}
                                      >
                                        Please enter a valid email address!
                                      </span>
                                    ),
                                  },
                                ]}
                              >
                                <Input
                                  placeholder={currentFormElement?.placeholder}
                                  style={{
                                    ...micrositeDefaultStyle?.answerStyle,
                                    ...micrositeDefaultStyle?.fieldStyle,
                                  }}
                                />
                              </Form.Item>
                            </Col>
                          </>
                        )}

                        {currentFormElement?.type === "input" && (
                          <>
                            <Col
                              id={currentFormElement?.id}
                              span={12}
                              onClick={(e, id = currentFormElement?.id) => {
                                if (!roAdmin) {
                                  inputElementClickHandler(e, id);
                                }
                              }}
                              style={{
                                outline:
                                  currentFormElement?.id ===
                                  currentFormElementId
                                    ? "2px dashed black"
                                    : "",
                                backgroundColor: currentFormElement?.isHidden
                                  ? "#74747426"
                                  : "",
                              }}
                            >
                              <Form.Item
                                name={currentFormElement?.id}
                                className="dynamic-form-item"
                                label={
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      minWidth: "100%",
                                    }}
                                  >
                                    <span
                                      style={{
                                        ...micrositeDefaultStyle?.questionStyle,
                                      }}
                                    >
                                      {currentFormElement?.label}
                                    </span>
                                    {currentFormElement.description ? (
                                      <Tooltip
                                        title={
                                          currentFormElement.description
                                            ? currentFormElement.description
                                            : ""
                                        }
                                      >
                                        <span>
                                          <InfoCircleOutlined
                                            style={{
                                              color:
                                                micrositeDefaultStyle
                                                  ?.questionStyle?.color,
                                              fontSize: "15px",
                                            }}
                                          />
                                        </span>
                                      </Tooltip>
                                    ) : null}
                                  </div>
                                }
                                rules={[
                                  {
                                    required: currentFormElement?.isRequired,
                                    message: (
                                      <span
                                        style={{
                                          color:
                                            micrositeDefaultStyle?.errorMessageColor,
                                        }}
                                      >
                                        Please enter{" "}
                                        {currentFormElement?.label.toLowerCase()}
                                        !
                                      </span>
                                    ),
                                  },

                                  currentFormElement?.htmlType === "number"
                                    ? () => ({
                                        validator(_, value) {
                                          if (!isNaN(value)) {
                                            return Promise.resolve();
                                          }

                                          return Promise.reject(
                                            <span
                                              style={{
                                                color:
                                                  micrositeDefaultStyle?.errorMessageColor,
                                              }}
                                            >
                                              only numbers are acceptable!
                                            </span>
                                          );
                                        },
                                      })
                                    : currentFormElement?.htmlType === "email"
                                    ? {
                                        pattern: emailPattern,
                                        message: (
                                          <span
                                            style={{
                                              color:
                                                micrositeDefaultStyle?.errorMessageColor,
                                            }}
                                          >
                                            Please enter a valid email address!
                                          </span>
                                        ),
                                      }
                                    : currentFormElement?.htmlType === "url"
                                    ? {
                                        pattern:
                                          /(www\.)?[-a-zA-Z0-9_]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
                                        message: (
                                          <span
                                            style={{
                                              color:
                                                micrositeDefaultStyle?.errorMessageColor,
                                            }}
                                          >
                                            Please enter valid URL
                                          </span>
                                        ),
                                      }
                                    : {},
                                ]}
                              >
                                <Input
                                  key={currentFormElement?.id}
                                  id={currentFormElement?.id}
                                  placeholder={currentFormElement?.placeholder}
                                  style={{
                                    ...micrositeDefaultStyle?.answerStyle,
                                    ...micrositeDefaultStyle?.fieldStyle,
                                  }}
                                />
                              </Form.Item>
                            </Col>
                          </>
                        )}

                        {currentFormElement?.type === "dropdown" && (
                          <>
                            <Col
                              id={currentFormElement?.id}
                              span={12}
                              onClick={(e, id = currentFormElement?.id) => {
                                if (!roAdmin) {
                                  inputElementClickHandler(e, id);
                                }
                              }}
                              style={{
                                outline:
                                  currentFormElement?.id ===
                                  currentFormElementId
                                    ? "2px dashed black"
                                    : "",
                                backgroundColor: currentFormElement?.isHidden
                                  ? "#74747426"
                                  : "",
                              }}
                            >
                              <Form.Item
                                name={currentFormElement?.id}
                                className="dynamic-form-item"
                                label={
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      minWidth: "100%",
                                    }}
                                  >
                                    <span
                                      style={{
                                        ...micrositeDefaultStyle?.questionStyle,
                                      }}
                                    >
                                      {currentFormElement?.label}
                                    </span>
                                    {currentFormElement.description ? (
                                      <Tooltip
                                        title={
                                          currentFormElement.description
                                            ? currentFormElement.description
                                            : ""
                                        }
                                      >
                                        <span>
                                          <InfoCircleOutlined
                                            style={{
                                              color:
                                                micrositeDefaultStyle
                                                  ?.questionStyle?.color,
                                              fontSize: "15px",
                                            }}
                                          />
                                        </span>
                                      </Tooltip>
                                    ) : null}
                                  </div>
                                }
                                rules={[
                                  {
                                    required: currentFormElement?.isRequired
                                      ? true
                                      : false,
                                    message: `Please select value!`,
                                  },
                                ]}
                              >
                                <Select
                                  id={currentFormElement?.id}
                                  placeholder={currentFormElement?.placeholder}
                                  style={{ width: "100%" }}
                                  className="Opt-1 round"
                                  value={
                                    currentFormElement?.options[
                                      currentFormElement?.isCheckedIndex
                                    ]?.value
                                  }
                                >
                                  {currentFormElement.options.map(
                                    (obj, index) => {
                                      return (
                                        <Option key={index} value={obj?.value}>
                                          {obj.label}
                                        </Option>
                                      );
                                    }
                                  )}
                                </Select>
                              </Form.Item>
                            </Col>
                          </>
                        )}
                        {currentFormElement?.type ===
                          "multiselect-dropdown" && (
                          <>
                            <Col
                              id={currentFormElement?.id}
                              span={12}
                              onClick={(e, id = currentFormElement?.id) => {
                                if (!roAdmin) {
                                  inputElementClickHandler(e, id);
                                }
                              }}
                              style={{
                                outline:
                                  currentFormElement?.id ===
                                  currentFormElementId
                                    ? "2px dashed black"
                                    : "",
                                backgroundColor: currentFormElement?.isHidden
                                  ? "#74747426"
                                  : "",
                              }}
                            >
                              <Form.Item
                                name={currentFormElement?.id}
                                className="dynamic-form-item"
                                label={
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      minWidth: "100%",
                                    }}
                                  >
                                    <span
                                      style={{
                                        ...micrositeDefaultStyle?.questionStyle,
                                      }}
                                    >
                                      {currentFormElement?.label}
                                    </span>
                                    {currentFormElement.description ? (
                                      <Tooltip
                                        title={
                                          currentFormElement.description
                                            ? currentFormElement.description
                                            : ""
                                        }
                                      >
                                        <span>
                                          <InfoCircleOutlined
                                            style={{
                                              color:
                                                micrositeDefaultStyle
                                                  ?.questionStyle?.color,
                                              fontSize: "15px",
                                            }}
                                          />
                                        </span>
                                      </Tooltip>
                                    ) : null}
                                  </div>
                                }
                                rules={[
                                  {
                                    required: currentFormElement?.isRequired
                                      ? true
                                      : false,
                                    message: `Please select value!`,
                                  },
                                ]}
                              >
                                <TreeSelect
                                  id={currentFormElement?.id}
                                  className="tree-select"
                                  {...tProps}
                                  style={{
                                    ...micrositeDefaultStyle?.answerStyle,
                                    ...micrositeDefaultStyle?.fieldStyle,
                                    border: `${micrositeDefaultStyle?.fieldStyle.borderWidth} solid ${micrositeDefaultStyle?.fieldStyle.borderColor}`,
                                  }}
                                />
                              </Form.Item>
                            </Col>
                          </>
                        )}

                        {currentFormElement?.type === "multichoice" && (
                          <>
                            <Col
                              id={currentFormElement?.id}
                              span={24}
                              onClick={(e, id = currentFormElement?.id) => {
                                if (!roAdmin) {
                                  inputElementClickHandler(e, id);
                                }
                              }}
                              style={{
                                outline:
                                  currentFormElement?.id ===
                                  currentFormElementId
                                    ? "2px dashed black"
                                    : "",
                                backgroundColor: currentFormElement?.isHidden
                                  ? "#74747426"
                                  : "",
                              }}
                            >
                              <Form.Item
                                name={currentFormElement?.id}
                                className="dynamic-form-item"
                                label={
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      minWidth: "100%",
                                    }}
                                  >
                                    <span
                                      style={{
                                        ...micrositeDefaultStyle?.questionStyle,
                                      }}
                                    >
                                      {currentFormElement?.label}
                                    </span>
                                    {currentFormElement.description ? (
                                      <Tooltip
                                        title={
                                          currentFormElement.description
                                            ? currentFormElement.description
                                            : ""
                                        }
                                      >
                                        <span>
                                          <InfoCircleOutlined
                                            style={{
                                              color:
                                                micrositeDefaultStyle
                                                  ?.questionStyle?.color,
                                              fontSize: "15px",
                                            }}
                                          />
                                        </span>
                                      </Tooltip>
                                    ) : null}
                                  </div>
                                }
                                rules={[
                                  {
                                    required: currentFormElement?.isRequired
                                      ? true
                                      : false,
                                    message: `Please pick an item!`,
                                  },
                                ]}
                              >
                                {currentFormElement?.styleRadioValue ===
                                  "Radio" && (
                                  <Radio.Group
                                    id={currentFormElement?.id}
                                    value={
                                      currentFormElement?.isCheckedIndex
                                        ? currentFormElement?.options[
                                            currentFormElement?.isCheckedIndex
                                          ].value
                                        : null
                                    }
                                    style={{
                                      display: "flex",
                                      gap: "5px",
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    {currentFormElement?.isOther
                                      ? [
                                          ...currentFormElement?.options,
                                          { label: "Other", value: "Other" },
                                        ].map((obj, index) => {
                                          return (
                                            <Radio
                                              key={index}
                                              value={obj.value}
                                            >
                                              <span
                                                style={{
                                                  ...micrositeDefaultStyle?.answerStyle,
                                                }}
                                              >
                                                {obj.label}
                                              </span>
                                            </Radio>
                                          );
                                        })
                                      : currentFormElement?.options.map(
                                          (obj, index) => {
                                            return (
                                              <Radio
                                                key={index}
                                                value={obj.value}
                                              >
                                                <span
                                                  style={{
                                                    ...micrositeDefaultStyle?.answerStyle,
                                                  }}
                                                >
                                                  {obj.label}
                                                </span>
                                              </Radio>
                                            );
                                          }
                                        )}
                                  </Radio.Group>
                                )}

                                {currentFormElement?.styleRadioValue ===
                                  "Tags" && (
                                  <Checkbox.Group
                                    id={currentFormElement?.id}
                                    value={
                                      currentFormElement?.isCheckedIndex
                                        ? currentFormElement?.options[
                                            currentFormElement?.isCheckedIndex
                                          ].value
                                        : null
                                    }
                                    style={{
                                      display: "flex",
                                      gap: "5px",
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    {currentFormElement?.isOther
                                      ? [
                                          ...currentFormElement?.options,
                                          { label: "Other", value: "Other" },
                                        ].map((obj, index) => {
                                          return (
                                            <Checkbox
                                              key={index}
                                              value={obj.value}
                                            >
                                              <span
                                                style={{
                                                  ...micrositeDefaultStyle?.answerStyle,
                                                }}
                                              >
                                                {obj.label}
                                              </span>
                                            </Checkbox>
                                          );
                                        })
                                      : currentFormElement?.options.map(
                                          (obj, index) => {
                                            return (
                                              <Checkbox
                                                key={index}
                                                value={obj.value}
                                              >
                                                <span
                                                  style={{
                                                    ...micrositeDefaultStyle?.answerStyle,
                                                  }}
                                                >
                                                  {obj.label}
                                                </span>
                                              </Checkbox>
                                            );
                                          }
                                        )}
                                  </Checkbox.Group>
                                )}
                              </Form.Item>
                            </Col>
                          </>
                        )}

                        {currentFormElement?.type === "button" && (
                          <>
                            <Col id={currentFormElement?.id} span={24}>
                              <Button
                                shape="round"
                                style={{
                                  ...micrositeDefaultStyle?.buttonStyle,
                                  ...currentFormElement?.style,
                                }}
                                onClick={(e, id = currentFormElement?.id) => {
                                  if (!roAdmin) {
                                    inputElementClickHandler(e, id);
                                  }
                                }}
                              >
                                {currentFormElement?.buttonText}
                              </Button>
                            </Col>
                          </>
                        )}
                        {currentFormElement?.type === "text" && (
                          <>
                            <Col id={currentFormElement?.id} span={24}>
                              <p
                                style={{
                                  ...micrositeDefaultStyle?.textStyle,
                                  ...currentFormElement?.style,
                                }}
                              >
                                {currentFormElement?.textValue}
                              </p>
                            </Col>
                          </>
                        )}
                        {currentFormElement?.type === "Consent-text" && (
                          <>
                            <Col
                              id={currentFormElement?.id}
                              span={24}
                              onClick={(e, id = currentFormElement?.id) => {
                                if (!roAdmin) {
                                  inputElementClickHandler(e, id);
                                }
                              }}
                            >
                              <p
                                style={{
                                  color: "gray",
                                }}
                                className="form-consent-text"
                                dangerouslySetInnerHTML={{
                                  __html: marked(
                                    currentFormElement?.textValue
                                      ? currentFormElement?.textValue
                                      : ""
                                  ),
                                }}
                              />
                            </Col>
                          </>
                        )}
                        {currentFormElement?.type === "copyright" && (
                          <>
                            <Col
                              id={currentFormElement?.id}
                              span={24}
                              onClick={(e, id = currentFormElement?.id) => {
                                if (!roAdmin) {
                                  inputElementClickHandler(e, id);
                                }
                              }}
                            >
                              <p
                                style={{
                                  ...micrositeDefaultStyle?.textStyle,
                                  ...currentFormElement?.style,
                                }}
                              >
                                {currentFormElement?.textValue}
                              </p>
                            </Col>
                          </>
                        )}
                      </>
                    );
                  })}
              </Row>
            </Form>
          </Col>

          <Col
            span={10}
            style={{
              backgroundColor:
                templateName === "TWO_COLUMN_LEFT_FORM"
                  ? micrositeDefaultStyle?.themeStyle?.secondaryBackgroundColor
                  : micrositeDefaultStyle?.themeStyle?.backgroundColor,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
            order={templateName === "TWO_COLUMN_LEFT_FORM" ? 2 : 1}
          >
            <h3
              style={{
                textAlign: "center",
                padding: "20px",
                margin: "30px 0px",
                color: "black",
                ...micrositeDefaultStyle?.companyTextStyle,
              }}
              onClick={(e) => {
                if (!roAdmin) {
                  inputElementClickHandler(
                    e,
                    micrositeFormElements[micrositeFormElements.length - 1]?.id
                  );
                }
              }}
              className="form-company-text"
              dangerouslySetInnerHTML={{
                __html: marked(
                  micrositeFormElements[micrositeFormElements?.length - 1]
                    ?.textValue
                    ? micrositeFormElements[micrositeFormElements?.length - 1]
                        ?.textValue
                    : ""
                ),
              }}
            />
          </Col>
          <img
            src={
              invertColor(
                micrositeDefaultStyle?.themeStyle?.secondaryBackgroundColor
                  ? micrositeDefaultStyle?.themeStyle?.secondaryBackgroundColor
                  : "#ffffff"
              ) === "#000000"
                ? handleImagesOnLanguageChange(
                    true,
                    micrositeDefaultStyle?.formLanguage
                  )
                : handleImagesOnLanguageChange(
                    false,
                    micrositeDefaultStyle?.formLanguage
                  )
            }
            alt=""
            style={{
              height: "25px",
              width: "auto",
              position: "absolute",
              bottom: "10px",
              right: "10px",
              cursor: "pointer",
            }}
            onClick={(e) => {
              window.open("https://www.blustream.io", "_blank");
            }}
          />
        </Row>
      ) : (
        <></>
      )}
      {/* //dev :- for adding new template ,don't remove this */}
      {/* {templateName === "THREE_COLUMN_LEFT_FORM" ? <h1>three column layout</h1>:<></>} */}
    </div>
  );
}
