import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import "./dropDownLang.css";
import { useDispatch, useSelector } from "react-redux";
import { setMicrositeDefaultStyle } from "store/actions/SettingAction";

import canadaFlag from "./../../assets/Images/canadaFlag.svg";
import usaFlag from "./../../assets/Images/usaFlag.svg";

const DropdownLang = ({ formLanguage }) => {
  const { Option } = Select;
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const languages = ["en", "fr"];
  const { micrositeDefaultStyle } = useSelector(
    (state) => state.SettingReducer
  );
  const [value, setValue] = useState(null);

  useEffect(() => {
    setValue(languages?.includes(formLanguage) ? formLanguage : "en");
  }, [micrositeDefaultStyle]);

  const handleLanguageChange = (lng) => {
    i18n.changeLanguage(lng);
    dispatch(
      setMicrositeDefaultStyle({
        ...micrositeDefaultStyle,
        formLanguage: lng,
      })
    );
  };

  return (
    <div>
      <Select
        className="language-selector"
        style={{
          width: 200,
        }}
        value={value}
        onChange={(lng) => {
          handleLanguageChange(lng);
        }}
      >
        <Option value="en" label="English">
          <div className="demo-option-label-item language-option">
            <img className="nation-flag" src={usaFlag} alt="usaFlag" />
            <span>English</span>
          </div>
        </Option>
        <Option value="fr" label="French">
          <div className="demo-option-label-item language-option">
            <img className="nation-flag" src={canadaFlag} alt="canadaFlag" />
            <span>French</span>
          </div>
        </Option>
      </Select>
    </div>
  );
};

export default DropdownLang;
