import { Button, Col, Empty, Row, Skeleton } from "antd";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { deleteCompanyInviteHistory } from "graphql/mutations";
import {
  onCreateCompanyInviteHistory,
  onDeleteCompanyInviteHistory,
} from "graphql/subscriptions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import sdk from "sdk/Accounts";
import { updateUserGroups } from "store/actions/loginAction";

import joinTeam from "./../../assets/Images/joinTeam.svg";

const MyInvitations = () => {
  const dispatch = useDispatch();
  const { roAdmin, userData } = useSelector((state) => state.loginReducer);

  const [invitationLoading, setinvitationLoading] = useState(false);
  const [invitationList, setInvitationList] = useState([]);
  const [acceptLoader, setAcceptLoader] = useState(false);
  const [acceptLoaderFlag, setAcceptLoaderFlag] = useState(null);
  const [declineLoader, setDeclineLoader] = useState(false);
  const [declineLoaderFlag, setDeclineLoaderFlag] = useState(null);
  // const [structuredInviteHistoryData, setStructuredInviteHistoryData] =
  //   useState([]);

  const getInviteHistory = async () => {
    try {
      const response = await sdk.listInviteHistory(userData?.email);
      if (response) {
        const structuredData = await Promise.all(
          response?.data?.listCompanyInviteHistories?.items?.map(
            async (item) => {
              const accountData = await sdk.fetchAccountDetailsOfInvitedUser(
                item?.account
              );
              return { ...item, company: accountData?.company };
            }
          )
        );
        setInvitationList(structuredData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const acceptInvitation = async (id) => {
    try {
      setAcceptLoader(true);
      const apiName = "blustreamREST";
      const path = "/accounts/join-organisation";
      const body = {
        id: id,
      };
      await API.post(apiName, path, { body: body });
      await getInviteHistory();
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();
      cognitoUser.refreshSession(
        currentSession.refreshToken,
        (err, session) => {
          const { accessToken } = session;
          if ("cognito:groups" in accessToken.payload) {
            const response = Auth.currentUserInfo();
            response
              .then((data) => {
                dispatch(updateUserGroups(session, data?.attributes));
              })
              .then((_) => {
                window.location.reload();
              });
          }
        }
      );
    } catch (error) {
      console.log(error);
    } finally {
      setAcceptLoader(false);
    }
  };

  const declineInvitation = async (id) => {
    try {
      setDeclineLoader(true);
      await API.graphql(
        graphqlOperation(deleteCompanyInviteHistory, { input: { id: id } })
      );
      await getInviteHistory();
      setDeclineLoader(false);
    } catch (error) {
      console.log(error);
      setDeclineLoader(false);
    }
  };

  useEffect(() => {
    const createInviteHistorySubscription = API.graphql(
      graphqlOperation(onCreateCompanyInviteHistory)
    ).subscribe({
      next: () => {
        getInviteHistory();
      },
      error: (error) => console.warn(error),
    });

    const deleteInviteHistorySubscription = API.graphql(
      graphqlOperation(onDeleteCompanyInviteHistory)
    ).subscribe({
      next: () => {
        getInviteHistory();
      },
      error: (error) => console.warn(error),
    });
    const getInitialValue = async () => {
      try {
        setinvitationLoading(true);
        await getInviteHistory();
        setinvitationLoading(false);
      } catch (error) {
        console.log(error);
        setinvitationLoading(false);
      }
    };
    getInitialValue();

    return () => {
      createInviteHistorySubscription?.unsubscribe();
      deleteInviteHistorySubscription?.unsubscribe();
    };
  }, []);

  return invitationLoading ? (
    <>
      <Skeleton paragraph={{ rows: 4 }} />
    </>
  ) : (
    <>
      <Row>
        {invitationList?.length > 0 ? (
          invitationList?.map((item, index) => {
            return (
              <Col span={24} key={index} className="user-request-main-wrapper">
                <div className="user-request-details-wrapper">
                  <img className="user-icon" src={joinTeam} alt="joinTeam" />
                  <div className="user-request-email">
                    {`${item?.invitedby} has invited you to join the`}{" "}
                    <span style={{ fontStyle: "italic", fontWeight: "600" }}>
                      {item?.company}
                    </span>
                  </div>
                </div>
                <div className="user-request-button-container">
                  <Button
                    className="standard-button secondary-orange-button"
                    onClick={() => {
                      setAcceptLoaderFlag(index);
                      acceptInvitation(item.id);
                    }}
                    disabled={roAdmin}
                    loading={index === acceptLoaderFlag && acceptLoader}
                  >
                    Accept
                  </Button>
                  <Button
                    className="standard-button tertiary-button"
                    onClick={() => {
                      setDeclineLoaderFlag(index);
                      declineInvitation(item.id);
                    }}
                    disabled={roAdmin}
                    loading={index === declineLoaderFlag && declineLoader}
                  >
                    Decline
                  </Button>
                </div>
              </Col>
            );
          })
        ) : (
          <Empty
            description="No invitations found!"
            style={{
              width: "100%",
              marginTop: "20px",
            }}
          />
        )}
      </Row>
    </>
  );
};

export default MyInvitations;
