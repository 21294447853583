import React, { useEffect, useState } from "react";
import { Input, message, Button, Row, Col, Empty } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import sdk from "sdk/Accounts";

import teamIcon from "./../../assets/Images/teamIcon.svg";
import { useSelector } from "react-redux";
import { API } from "aws-amplify";

export default function UserRequests(props) {
  const { acct } = props;
  const { roAdmin } = useSelector((state) => state.loginReducer);

  const [requests, setrequests] = useState([]);
  const [requestsbackup, setrequestsbackup] = useState([]);
  const [approveLoader, setApproveLoader] = useState(false);
  const [approveLoaderFlag, setApproveLoaderFlag] = useState(null);
  const [declineLoader, setDeclineLoader] = useState(false);
  const [declineLoaderFlag, setDeclineLoaderFlag] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    let requestEmail = [];

    let requests = acct?.requests?.items?.filter((item) => {
      if (!requestEmail.includes(item.owner)) {
        requestEmail.push(item.owner);
        return true;
      }
      return false;
    });
    setrequests(requests);
    setrequestsbackup(acct?.requests?.items);
  }, [acct]);

  useEffect(() => {
    if (searchValue?.length > 0) {
      setrequests(
        requestsbackup.filter((item) =>
          String(item?.owner)
            .toLowerCase()
            .includes(String(searchValue).toLowerCase())
        )
      );
    } else {
      setrequests(requestsbackup);
    }
  }, [requestsbackup]);

  const searchReq = (text) => {
    setrequests(
      requestsbackup.filter((item) =>
        String(item?.owner)
          .toLowerCase()
          .includes(String(text?.trimStart()).toLowerCase())
      )
    );
  };

  const approveRequest = async (id) => {
    if (id) {
      try {
        setApproveLoader(true);
        await sdk.approveRequest(id);
        await deleteRequest(id);
        setApproveLoader(false);
        await props.fetchAccountDetails();
      } catch (error) {
        console.log(error);
        message.error("Failed to approve request.");
      } finally {
        setApproveLoader(false);
      }
    }
  };
  const sendRequestDeniedEmail = async (email) => {
    const path = `/accounts/requests/deny`;
    const body = {
      companyName: acct?.company,
      requesterEmail: email,
    };
    try {
      const result = await API.post("blustreamREST", path, {
        body: body,
      });
      return result;
    } catch (e) {
      console.log(e);
      return { error: "Something went wrong!" };
    }
  };

  const deleteRequest = async (id) => {
    if (id) {
      try {
        setDeclineLoader(true);
        await sdk.deleteRequest(id);
        await props.fetchAccountDetails();
      } catch (error) {
        console.log(error);
        message.error("Failed to decline request.");
      } finally {
        setDeclineLoader(false);
      }
    }
  };
  return (
    <>
      <Row
        style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}
      >
        <Col span={12}>
          <div className="user-request-title">User joining requests</div>
        </Col>
        <Col span={12}>
          <Input.Search
            className="standard-searchbox"
            allowClear
            prefix={<SearchOutlined className="standard-searchbox-prefix" />}
            placeholder="Search users"
            type="search"
            value={searchValue}
            onChange={(event) => {
              setSearchValue(event.target.value);
              searchReq(event.target.value);
            }}
          />
        </Col>
      </Row>
      <Row>
        {requests?.length > 0 ? (
          requests?.map((item, index) => {
            return (
              <Col span={24} key={index} className="user-request-main-wrapper">
                <div className="user-request-details-wrapper">
                  <img className="user-icon" src={teamIcon} alt="teamIcon" />
                  <div className="user-request-email">{item.owner}</div>
                </div>
                <div className="user-request-button-container">
                  <Button
                    className="standard-button secondary-orange-button"
                    onClick={() => {
                      setApproveLoaderFlag(index);
                      approveRequest(item.id);
                    }}
                    disabled={roAdmin}
                    loading={index === approveLoaderFlag && approveLoader}
                  >
                    Approve
                  </Button>
                  <Button
                    className="standard-button tertiary-button"
                    onClick={async () => {
                      setDeclineLoaderFlag(index);
                      deleteRequest(item.id);
                      await sendRequestDeniedEmail(item?.owner);
                    }}
                    disabled={roAdmin}
                    loading={index === declineLoaderFlag && declineLoader}
                  >
                    Decline
                  </Button>
                </div>
              </Col>
            );
          })
        ) : (
          <Empty
            description="No user requests found!"
            style={{
              width: "100%",
              marginTop: "20px",
            }}
          />
        )}
      </Row>
    </>
  );
}
