/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Table,
  Input,
  Button,
  ConfigProvider,
  Modal,
  Row,
  Col,
  Upload,
  message,
  Dropdown,
  Drawer,
  Menu,
  Radio,
  Form,
  Space,
  Checkbox,
  Alert,
  Skeleton,
  Typography,
} from "antd";
import { addAttribute, fuzzySearch, getContactbyID } from "apiService/RESTApi";
import {
  SearchOutlined,
  DeleteOutlined,
  Loading3QuartersOutlined,
  CalculatorFilled,
} from "@ant-design/icons";
import "./contact.css";
import exportDataSVG from "../../assets/Images/exportDataSVG.svg";
import DeleteIcon from "../../assets/Images/DeleteIcon.svg";
import contactManagerSVG from "../../assets/Images/contactManagerSVG.svg";
import ContactImportedIcon from "../../assets/Images/ContactImportedIcon.svg";
import CSVIcon from "../../assets/Images/CSVIcon.svg";
import successIcon from "../../assets/Images/successIcon.svg";
import { Link } from "react-router-dom";
import { API } from "aws-amplify";
import uploadFileToS3 from "../../utility/uploadFileToS3";
import {
  deleteContact,
  getContacts,
  getCustomerId,
  getProduct,
} from "apiService/RESTApi";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const { Search } = Input;
const { Dragger } = Upload;
let debounceValue = "";

const Contacts = () => {
  const [loading, setLoading] = useState(false);
  const [isInviteUserModelOpen, setisInviteUserModelOpen] = useState(false);
  const [csvModel, setCsvModel] = useState(false);
  const [csvImportedModel, setCsvImportedModel] = useState(false);
  const [linkProductModel, setLinkProductModel] = useState(false);
  const [linkRadiioModel, setLinkRadioModel] = useState(false);
  const [linkProductSuccessModel, setLinkProductMSuccessodel] = useState(false);
  const [termsConditionModel, setTermsConditionModel] = useState(false);
  const [tabledata, settabledata] = useState([]);
  const { groups, roAdmin } = useSelector((state) => state.loginReducer);
  const CurrentUserID = groups[0];
  const [contacttoDelete, setcontacttoDelete] = useState({});
  const [tableLoading, settableLoading] = useState(false);
  const [currentPage, setcurrentPage] = useState(0);
  const [currentTablePageSize, setcurrentTablePageSize] = useState(10);
  const [noMoreContacts, setNoMoreContacts] = useState(false);
  const [addProductAttributeDrawer, setAddProductAttributeDrawer] =
    useState(false);
  const [customerId, setCustomerId] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [allContactData, setAllContactData] = useState([]);
  const [error, setError] = useState("");
  const [firstData, setFirstData] = useState("");
  const [selectModel, setSelectModel] = useState([]);
  const [form] = Form.useForm();
  const [csvForm] = Form.useForm();
  const [productForm] = Form.useForm();
  const history = useHistory();
  const [productDetails, setProductDetails] = useState([]);
  const [productId, setProductId] = useState([]);
  const [fuzzySearchLoader, setFuzzySearchLoader] = useState(false);
  const [isSearchDisable, setIsSearchDisable] = useState(false);

  function handleChange(e, value) {
    let temp = [...productId];
    if (e === true) {
      temp.push(value.productId);
    } else {
      let indexData = temp.indexOf(value.productId);
      temp.splice(indexData, 1);
    }
    setProductId(temp);
  }

  //  get Contacts
  const getContactsData = async (page, size, data) => {
    if (!noMoreContacts || data) {
      settableLoading(true);
      let response = getContacts(CurrentUserID, page);
      return response
        .then(async (data) => {
          let rescontact = await getCustomerId(groups[0]);
          setCustomerId(rescontact?.response?.data?.[0]?.customerId);
          if (data?.response?.error) {
            message.error("Something went Wrong");
            settableLoading(false);
          } else {
            let usersData = data?.response?.data;
            setFirstData(usersData);
            if (usersData && usersData?.length > 0) {
              let contacts = usersData?.map((item) => ({
                customerId: item?.customerId,
                id: item?.contactId,
                firstName: item?.firstName,
                lastName: item?.lastName,
                primaryPhoneNumber: item?.primaryPhoneNumber
                  ? item?.primaryPhoneNumber
                  : "-",
                primaryEmailAddress: item?.primaryEmailAddress
                  ? item?.primaryEmailAddress
                  : "-",
              }));
              if (page === 0) {
                settabledata(contacts);
              } else {
                settabledata((old) => [...old, ...contacts]);
              }
              setAllContactData((old) => [...old, ...contacts]);
              if (contacts?.length < size) {
                setNoMoreContacts(true);
              }
            }
            settableLoading(false);
            return response;
          }
        })
        .catch((error) => {
          console.log("Error :", error);
          settableLoading(false);
          return error;
        });
    }
  };

  const onSearch = (value) => {
    setSearchValue(value.target.value);
    setLoading(true);
    if (value.target.value !== "") {
      clearTimeout(debounceValue);
      debounceValue = setTimeout(async () => {
        setFuzzySearchLoader(true);
        setIsSearchDisable(true);
        await getCustomerId(groups[0]).then(async (res) => {
          const customerArn = res.response.data[0].customerArn;
          const searchResults = await fuzzySearch(
            customerArn,
            value.target.value
          );
          const promiseArray = searchResults?.data?.map((contact) => {
            return getContactbyID(contact?.contactId);
          });
          Promise.all(promiseArray).then((values) => {
            const searchedContacts = values?.map((item) => ({
              customerId: item?.customerId,
              id: item?.contactId,
              firstName: item?.firstName,
              lastName: item?.lastName,
              primaryPhoneNumber: item?.primaryPhoneNumber
                ? item?.primaryPhoneNumber
                : "-",
              primaryEmailAddress: item?.primaryEmailAddress
                ? item?.primaryEmailAddress
                : "-",
            }));

            settabledata(searchedContacts);
            setFuzzySearchLoader(false);
            setIsSearchDisable(false);
          });
        });
      }, 1000);
    } else {
      clearTimeout(debounceValue);
      settabledata(allContactData);
    }

    setLoading(false);
  };

  const handleAttribute = async () => {
    setLoading(true);
    try {
      let resolveData = {
        customerId: customerId,
        ...form.getFieldsValue(),
      };
      let res = await addAttribute(resolveData);
      if (res) {
        message.success("Attribute Added successfully!");
        setLoading(false);
      } else {
        message.error("Something went wrong");
      }
    } catch (e) {
      console.log(e);
      message.error("Something went wrong!");
    }
    setAddProductAttributeDrawer(false);
    form.resetFields();
    setLoading(false);
  };

  const [skeletonLoader, setskeletonLoader] = useState(false);
  useEffect(async () => {
    setskeletonLoader(true);
    await getContactsData(currentPage, 2000);
    setskeletonLoader(false);
  }, []);

  const handleTableChange = (value) => {
    let currentPage = value.current;
    setcurrentTablePageSize(value.pageSize);
  };

  // to delete the contact
  const handleDeleteContact = async (record) => {
    settableLoading(true);
    await deleteContact(contacttoDelete?.id)
      .then((res) => {
        setcontacttoDelete({});
        const updatedTableData = tabledata?.filter(
          (contact) => contact?.id !== contacttoDelete?.id
        );
        const updatedAllContactData = allContactData?.filter(
          (contact) => contact?.id !== contacttoDelete?.id
        );
        setAllContactData(updatedAllContactData);
        settabledata(updatedTableData);
        settableLoading(false);
        setisInviteUserModelOpen(false);
      })
      .catch((err) => {
        console.error(err);
        settableLoading(false);
      });
  };

  const AddonClose = () => {
    setAddProductAttributeDrawer(false);
  };

  const getProductsApi = async () => {
    setLoading(true);
    await getProduct(groups[0])
      .then((res) => {
        let productValue = res?.response?.data;
        setProductDetails(productValue);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (customerId) {
      getProductsApi();
    }
  }, [customerId]);

  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      render: (text, record) => (
        <Link
          to={{
            pathname: "/contacts/contact-detail",
            state: {
              userToEdit: record,
            },
          }}
        >
          {text}
        </Link>
      ),
      width: "20%",
    },
    // mvp commented code
    {
      title: "Last Name",
      dataIndex: "lastName",
      render: (text, record) => (
        <Link
          to={{
            pathname: "/contacts/contact-detail",
            state: {
              userToEdit: record,
            },
          }}
        >
          {text}
        </Link>
      ),
      width: "20%",
    },
    {
      title: "Phone Number",
      dataIndex: "primaryPhoneNumber",
      render: (text, record) => (
        <Link
          to={{
            pathname: "/contacts/contact-detail",
            state: {
              userToEdit: record,
            },
          }}
        >
          {text}
        </Link>
      ),
      width: "26%",
    },
    {
      title: "Email Address",
      dataIndex: "primaryEmailAddress",
      render: (text, record) => (
        <Link
          to={{
            pathname: "/contacts/contact-detail",
            state: {
              userToEdit: record,
            },
          }}
        >
          {text}
        </Link>
      ),
      width: "26%",
    },

    {
      title: "",
      dataIndex: "delete",
      render: (data, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                disabled={roAdmin}
                onClick={() => {
                  setisInviteUserModelOpen(true);
                  setcontacttoDelete(record);
                  setNoMoreContacts(false);
                }}
              >
                <Typography.Text>Delete Contact</Typography.Text>
              </Menu.Item>
            </Menu>
          }
        >
          <Button className="ant-dropdown-link button" type="text">
            <DeleteOutlined />
          </Button>
        </Dropdown>
      ),
      align: "center",
      width: "8%",
    },
  ];

  const itemRender = (current, type, originalElement) => {
    if (type === "prev") {
      return (
        <span
          style={{ userSelect: "none", cursor: "pointer" }}
          onClick={() => {
            if (currentPage >= 1) setcurrentPage(current - 1);
          }}
        >
          {"< Prev"}
        </span>
      );
    }
    if (type === "next") {
      return (
        <span
          style={{ userSelect: "none", cursor: "pointer", padding: "10px 0px" }}
          onClick={() => {
            let currentPageSize = currentTablePageSize;
            getContactsData(current, currentPageSize);
            setcurrentPage(current);
          }}
        >
          {"Next >"}
        </span>
      );
    }
    if (originalElement?.type === "a") {
      return (
        <span
          style={{
            color:
              String(originalElement?._owner?.key) ===
                String(currentPage + 1) && "#F5786D",
          }}
          onClick={() => {
            if (originalElement?._owner?.key)
              setcurrentPage(parseInt(originalElement?._owner?.key) - 1);
          }}
        >
          {originalElement?._owner?.key}
        </span>
      );
    }
    return originalElement;
  };

  const customizeRenderEmpty = () => (
    <div className="noDataScreen">
      <h1>No contact found</h1>
      <img
        src={exportDataSVG}
        height="90"
        alt=""
        style={{ marginBottom: "20px" }}
      />
      <p>
        Get started with the contacts manager by first
        <br /> importing the product CSV data file
      </p>
      <Button
        className="standard-button tertiary-button"
        type="secondary"
        onClick={handleCsv}
      >
        Import CSV data file
      </Button>
    </div>
  );

  const removeFile = () => {
    let deletebtn = document.getElementById("delete-file-icon");
    deletebtn && deletebtn.click();
  };
  useEffect(() => {
    removeFile();
  }, [csvModel]);

  const [CSVMessage, setCSVMessage] = useState("");
  const props = {
    name: "file",
    multiple: false,
    customRequest: async ({ onSuccess, onError, file }) => {
      try {
        setCSVMessage("Uploading");
        const res = await uploadFileToS3(file, productId, customerId);
        try {
          setCSVMessage("Processing");
          let productIdData = productId.toString();
          await API.get(
            "blustreamREST",
            `/processCSV?filename=public/${res.key}&productId=${productIdData}&accountId=${groups[0]}&customerId=${customerId}`
          )
            .then((res) => {
              setCsvModel(false);
              setCsvImportedModel(true);
              setNoMoreContacts(false);
              getContactsData(currentPage, 2000, true);
              setcurrentPage(currentPage);
              onSuccess(res);
              setCSVMessage("");
            })
            .catch((err) => {
              err.message = "Processing CSV timed out!";
              onError(err);
              setCSVMessage("");
            });
        } catch (error) {
          error.message = "Processing CSV timed out!";
          console.log("Error:", error.response.data);
          message.error(error?.response?.data);
          onError(error);
          setCSVMessage("");
        }
      } catch (err) {
        err.message = "Processing CSV timed out!";
        onError(err);
        setCSVMessage("");
      }
    },
    showUploadList: {
      showRemoveIcon: true,
      removeIcon: <DeleteOutlined id="delete-file-icon" />,
    },
  };

  const handleClick = () => {
    csvForm.getFieldsValue();
    if (selectModel === "csv") {
      if (productDetails?.length > 0) {
        setLinkRadioModel(true);
      } else {
        setCsvModel(true);
      }
      setTermsConditionModel(false);
    } else {
      history.push({
        pathname: "/contacts/create-contact",
        state: { customerId: customerId },
      });
    }
  };

  const handleCsv = async () => {
    setSelectModel("csv");
    if (firstData === null) {
      setTermsConditionModel(true);
    } else {
      if (productDetails?.length > 0) {
        setLinkRadioModel(true);
      } else {
        setCsvModel(true);
      }
    }
  };

  const addContactLink = () => {
    setSelectModel("contact-add");
    if (tabledata?.length === 0) {
      setTermsConditionModel(true);
    } else {
      history.push({
        pathname: "/contacts/create-contact",
        state: { customerId: customerId },
      });
    }
  };

  return (
    <>
      <div>
        <div
          style={{
            width: "100%",
            background: "white",
            borderRadius: "8px",
            marginBottom: "10px",
          }}
        >
          <Row>
            <Col span={16} xl={16} lg={24} md={24} sm={24} xs={24}>
              <div
                style={{
                  paddingLeft: "20px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <h2
                  style={{
                    fontFamily: "Hellix",
                    fontWeight: "700",
                    fontSize: "22px",
                    lineHeight: "26px",
                    color: "#464646",
                    marginBottom: "15px",
                  }}
                >
                  Welcome to Blustream{" "}
                  <span style={{ color: "#F5786D" }}>Contacts</span> Manager!
                </h2>
                <p
                  style={{
                    fontFamily: "Hellix",
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "26px",
                    color: "#464646",
                    maxWidth: "497px",
                    marginBottom: "0px",
                  }}
                >
                  Manage all your contacts details from this dashboard. Add or
                  update new properties as per your requirements easily!
                </p>
              </div>
            </Col>
            <Col
              span={8}
              xl={8}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <img
                src={contactManagerSVG}
                style={{ maxHeight: "151px", paddingRight: "20px" }}
                alt=""
              />
            </Col>
          </Row>
        </div>
        <Drawer
          width={430}
          title="Add new product attribute"
          placement="right"
          onClose={AddonClose}
          loading={loading}
          maskClosable={false}
          visible={addProductAttributeDrawer}
        >
          <Form
            onFinish={handleAttribute}
            form={form}
            loading={loading}
            layout="vertical"
            className="product-attribute-form"
          >
            <Form.Item
              label="Attribute Title"
              name="AttributeTitle"
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "This field can not be empty!",
                },
              ]}
            >
              <Input
                className="standard-input"
                placeholder="Enter attribute title"
              />
            </Form.Item>

            <Form.Item
              label="Attribute Type"
              style={{ width: "100%" }}
              name="AttributeType"
              rules={[
                {
                  required: true,
                  message: "This field can not be empty!",
                },
              ]}
            >
              <Radio.Group>
                <Space direction="vertical">
                  <Radio value={1}>Long</Radio>
                  <Radio value={2}>Date Selection</Radio>
                  <Radio value={3}>String</Radio>
                  <Radio value={4}>Boolean</Radio>
                  <Radio value={5}>Integer</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>

            <div
              style={{
                position: "absolute",
                right: 0,
                bottom: 0,
                left: 0,
                width: "auto",
                borderTop: "1px solid #e9e9e9",
                fontSize: "18px",
                padding: "10px 16px",
                background: "#EFF4F9",
                textAlign: "right",
                display: "flex",
              }}
            >
              <Button
                className="standard-button tertiary-button"
                style={{ marginRight: 8, width: "48%" }}
                size="middle"
                onClick={AddonClose}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                loading={loading}
                style={{ width: "48%" }}
                htmlType="submit"
                className="standard-button primary-orange-button"
                size="middle"
              >
                Add
              </Button>
            </div>
          </Form>
        </Drawer>

        <div className="contactBox">
          <div className="dataContent">
            <div className="tableHeader tableFlexBox">
              <h3 className="all-contact-heading">All Contacts</h3>

              <div className="flexBox contactFlexBox">
                {!skeletonLoader && tableLoading && (
                  <Loading3QuartersOutlined spin style={{ marginRight: 10 }} />
                )}
                {skeletonLoader ? (
                  <Skeleton.Button active style={{ width: 170 }} />
                ) : (
                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      marginRight: "10px",
                    }}
                  >
                    <Search
                      className="standard-searchbox"
                      prefix={
                        <SearchOutlined className="standard-searchbox-prefix" />
                      }
                      placeholder="Search contacts"
                      onChange={onSearch}
                      style={{ width: "100%" }}
                      value={searchValue}
                      disabled={isSearchDisable}
                      allowClear
                    />
                  </div>
                )}
                <div className="flexbox-container">
                  {skeletonLoader ? (
                    <Skeleton.Button
                      active
                      style={{ width: 70, marginLeft: 10 }}
                    />
                  ) : (
                    <Button
                      className="standard-button primary-orange-button"
                      onClick={addContactLink}
                      disabled={roAdmin}
                      type="secondary"
                      style={{
                        marginRight: "10px",
                      }}
                    >
                      Add Contact
                    </Button>
                  )}
                  {skeletonLoader ? (
                    <Skeleton.Button
                      active
                      style={{ width: 70, marginLeft: 10 }}
                    />
                  ) : (
                    <Button
                      className="standard-button tertiary-button"
                      disabled={roAdmin}
                      onClick={handleCsv}
                      type="secondary"
                    >
                      {" "}
                      Import CSV{" "}
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <ConfigProvider renderEmpty={tabledata && customizeRenderEmpty}>
              {skeletonLoader || fuzzySearchLoader ? (
                <Skeleton active paragraph={{ rows: 5 }} />
              ) : (
                <Table
                  className="contacts-table"
                  columns={columns}
                  dataSource={tabledata}
                  onChange={handleTableChange}
                  scroll={{ y: 325 }}
                  pagination={{
                    showSizeChanger: true,
                    position: ["bottomCenter"],
                  }}
                  style={{ height: "calc(100vh - 365px)" }}
                />
              )}
            </ConfigProvider>
          </div>
        </div>

        <Modal
          visible={isInviteUserModelOpen}
          className="invite-modal"
          footer={null}
          width={350}
          centered
          closeIcon={<></>}
          maskClosable={false}
          onCancel={() => {
            setisInviteUserModelOpen(false);
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              padding: "10px 10px 50px",
            }}
          >
            <img src={DeleteIcon} height="40" alt="" />
            <h3 style={{ color: "#252525", margin: "10px 0 20px" }}>
              Are you sure you want to
              <br /> delete this contact ?
            </h3>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Button
                className="standard-button tertiary-button"
                style={{
                  marginRight: 8,
                  width: "48%",
                }}
                size="middle"
                disabled={tableLoading}
                onClick={() => {
                  setisInviteUserModelOpen(false);
                }}
              >
                No
              </Button>
              <Button
                loading={tableLoading}
                type="primary"
                style={{ width: "48%" }}
                htmlType="submit"
                className="standard-button primary-orange-button"
                size="middle"
                onClick={handleDeleteContact}
              >
                Yes
              </Button>
            </div>
          </div>
        </Modal>
      </div>

      {/* import csv modal  */}
      <Modal
        visible={csvModel}
        className="csv-modal"
        footer={null}
        width={600}
        centered
        closeIcon={<></>}
        onCancel={() => {
          setCsvModel(false);
        }}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={9} className="sampleCsv" style={{ background: "#E8F3FF" }}>
            <img src={CSVIcon} alt="" />
            <p>
              CSV should be in specific format. Please check the sample CSV
              format
            </p>
            <a
              className="standard-button primary-orange-button"
              href="https://blustreamfrontend-static-images.s3.amazonaws.com/example-import.csv"
              target="_blank"
              rel="noreferrer"
            >
              Download sample
            </a>
          </Col>

          <Col span={15} className="drag-file">
            <Dragger {...props}>
              <p className="ant-upload-text">Drag and drop CSV file here</p>
            </Dragger>
            <div style={{ paddingTop: 10 }}>{CSVMessage}</div>
          </Col>
        </Row>
      </Modal>

      {/* Importing csv success modal */}
      <Modal
        visible={csvImportedModel}
        className="csv-modal"
        footer={null}
        width={350}
        centered
        onCancel={() => {
          setCsvImportedModel(false);
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: 20,
            textAlign: "center",
          }}
        >
          <img src={ContactImportedIcon} height="40px" alt="Contacts" />
          <h3 style={{ color: "#252525", fontWeight: 600, marginTop: 10 }}>
            Your contacts have been uploaded. Please allow some time for them to
            appear. Check your email for a report for any errors or duplicates.
          </h3>
        </div>
      </Modal>
      {/*terms & condition modal*/}
      <Modal
        visible={termsConditionModel}
        className="csv-modal"
        footer={null}
        width={400}
        centered
        closeIcon={<></>}
        onCancel={() => {
          setTermsConditionModel(false);
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: 20,
            textAlign: "center",
          }}
        >
          {error && (
            <Alert
              style={{ marginBottom: "10px" }}
              type="error"
              showIcon={false}
              message={error}
              banner
            />
          )}
          <Form
            requiredMark={false}
            form={csvForm}
            name="normal_login"
            onSubmit={handleClick}
          >
            <Form.Item>
              <p>
                Asking your contacts to opt-in to receive SMS messages creates a
                great and trusted experience. Not only is it polite, it's
                legally required in many regions. Please make sure you stay
                compliant with any applicable SMS communication laws.
              </p>
            </Form.Item>
            <Form.Item>
              <Button
                className="standard-button primary-orange-button"
                onClick={handleClick}
                htmlType="submit"
                loading={loading}
                type="primary"
              >
                I understand
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>

      {/* linking product modal  */}
      <Modal
        visible={linkProductModel}
        className="addProperty select-journey"
        footer={null}
        width={430}
        centered
        onCancel={() => {
          setLinkProductModel(false);
        }}
      >
        <Form layout="vertical" style={{ width: "100%" }}>
          <h3 style={{ color: "#124173", marginBottom: 15 }}>Select product</h3>

          <Radio.Group style={{ width: "100%" }}>
            <Space direction="vertical">
              <Radio value={1}>Golden Retriever</Radio>
              <Radio value={2}>Labrador</Radio>
              <Radio value={3}>Australian Shepherd</Radio>
              <Radio value={4}>German Shepherd</Radio>
              <Radio value={5}>Chihauhua</Radio>
              <Radio value={6}>Dachsund</Radio>
              <Radio value={7}>Poodle</Radio>
              <Radio value={8}>Labradoodle</Radio>
              <Radio value={9}>Beagle</Radio>
              <Radio value={10}>Akita</Radio>
            </Space>
          </Radio.Group>

          <Button
            className="standard-button primary-orange-button"
            onClick={() => {
              setLinkProductModel(false);
              setLinkProductMSuccessodel(true);
            }}
            style={{
              marginTop: 10,
              width: "100%",
              marginBottom: 10,
            }}
          >
            Link Now
          </Button>
        </Form>
      </Modal>

      {/* success modal  */}
      <Modal
        visible={linkProductSuccessModel}
        className="addProperty select-journey"
        footer={null}
        width={350}
        centered
        onCancel={() => {
          setLinkProductMSuccessodel(false);
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: 20,
            textAlign: "center",
          }}
        >
          <img src={successIcon} height="50px" alt="Success" />
          <h3 style={{ color: "#252525", fontWeight: 600, marginTop: 20 }}>
            Contacts linked successfully with “Golden Retriever”
          </h3>
        </div>
      </Modal>
      <Modal
        visible={linkRadiioModel}
        className="addProperty select-journey"
        footer={null}
        width={430}
        centered
        onCancel={() => {
          setLinkRadioModel(false);
        }}
      >
        <Form form={productForm} layout="vertical" style={{ width: "100%" }}>
          <h3 style={{ color: "#124173", marginBottom: 15 }}>
            Which product would you like contacts to link with?
          </h3>
          <Form.Item name="checked" valuePropName="checked">
            {productDetails?.map((value, key) => {
              return (
                <Checkbox
                  key={key}
                  onChange={(e) => handleChange(e.target.checked, value)}
                >
                  {value?.name}
                </Checkbox>
              );
            })}
          </Form.Item>

          <Button
            className="standard-button primary-orange-button"
            onClick={() => {
              setLinkRadioModel(false);
              setCsvModel(true);
            }}
            htmlType="submit"
            style={{
              marginTop: 10,
              width: "100%",
              marginBottom: 10,
            }}
          >
            Continue
          </Button>
          <Button
            className="standard-button primary-orange-button"
            onClick={() => setCsvModel(true)}
            style={{
              marginTop: 10,
              width: "100%",
              marginBottom: 10,
            }}
          >
            Skip
          </Button>
        </Form>
      </Modal>

      {/* mvp commented code  */}
      {/* <AttributeDrawer /> */}
    </>
  );
};

export default Contacts;
