/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Input,
  Button,
  Modal,
  Avatar,
  Row,
  Col,
  Upload,
  Drawer,
  Space,
  Radio,
  Form,
  Skeleton,
  Empty,
  Select,
  Popover,
  Popconfirm,
} from "antd";
import { SearchOutlined, MoreOutlined } from "@ant-design/icons";
import "./contact.css";
import DeleteIcon from "../../assets/Images/DeleteIcon.svg";
import CSVIcon from "../../assets/Images/CSVIcon.svg";
import ProductManagerSVG from "../../assets/Images/productManagerSVG.svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { getProduct } from "apiService/RESTApi";
import SkeletonBlock from "commons/SkeletonBlock";
import { useHistory } from "react-router";
import MappedProductsDrawer from "./MappedProductsDrawer";

const { Search } = Input;
const { Option } = Select;
const { Dragger } = Upload;

const Product = () => {
  const props = {
    name: "file",
    multiple: false,
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    onChange(info) {},
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const popContent = (
    <div className="popover-text-container" style={{ padding: "15px" }}>
      <p
        className="popover-text"
        onClick={() => {
          setShowMappedProductList(true);
          setOpen(false);
        }}
      >
        All mapped products
      </p>
    </div>
  );

  const history = useHistory();
  const { groups } = useSelector((state) => state.loginReducer);

  const [isInviteUserModelOpen, setisInviteUserModelOpen] = useState(false);
  const [csvModel, setCsvModel] = useState(false);
  const [productData, setProductData] = useState([]);
  const [addProductAttributeDrawer, setAddProductAttributeDrawer] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [searchValue, setSearchValue] = useState("");
  const [showMappedProductList, setShowMappedProductList] = useState(false);
  const [open, setOpen] = useState(false);
  const [searchedProducts, setSearchedProducts] = useState([]);

  const AddonClose = () => {
    setAddProductAttributeDrawer(false);
  };

  const handleGridProduct = async () => {
    setLoading(true);
    let prod = await getProduct(groups[0]);
    if (prod.response.data && prod.response.data.length > 0) {
      let newGridProduct = prod.response.data.map((p) => ({
        pName: p?.name,
        key: p?.productId,
        cId: p?.customerDto?.customerId,
        externalUuid: p?.customerDto?.externalUuid,
      }));
      setProductData(newGridProduct);
      setSearchedProducts(newGridProduct);
    }
    setLoading(false);
  };

  useEffect(() => {
    handleGridProduct();
    // eslint-disable-next-line
  }, []);

  const onProductSearch = (e) => {
    setSearchValue(e.target.value);
    let data = productData.filter((product) =>
      product.pName
        .toLowerCase()
        .includes(e.target.value?.trimStart()?.toLowerCase())
    );
    setSearchedProducts(data);
  };

  return (
    <>
      {/* {!groups.includes("internal") ?

                <div className="wrapperClass wraperclass-product" style={{ backgroundImage: `url(${BG})` }}>
                    <Row>
                        <Col span={24}>
                            <h1>
                                Product Manager<br />
                                <span>Coming Soon</span>
                            </h1>
                        </Col>
                    </Row>

                    <div className='textbox'>
                        <h2 style={{ marginBottom: 20 }}>
                            You'll soon be able to manage<br />
                            Products right here!
                        </h2>
                        <h2>
                            Meanwhile, please contact our team<br />
                            at <a href="mailto:support@blustream.io">support@blustream.io</a><br />
                            for help designing and managing your<br />
                            products.
                        </h2>
                    </div>

                </div> : */}
      <>
        <div
          style={{
            width: "100%",
            background: "white",
            borderRadius: "8px",
            marginBottom: "10px",
          }}
        >
          <Row>
            <Col span={16} xl={16} lg={24} md={24} sm={24} xs={24}>
              <div
                style={{
                  paddingLeft: "20px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <h2
                  style={{
                    fontFamily: "Hellix",
                    fontWeight: "700",
                    fontSize: "22px",
                    lineHeight: "26px",
                    color: "#464646",
                    marginBottom: "15px",
                  }}
                >
                  Welcome to Blustream{" "}
                  <span style={{ color: "#3B84D1" }}>Product</span> Manager!
                </h2>
                <p
                  style={{
                    fontFamily: "Hellix",
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "26px",
                    color: "#464646",
                  }}
                >
                  Manage all your product details from this dashboard.
                </p>
              </div>
            </Col>
            <Col
              span={8}
              xl={8}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <img
                src={ProductManagerSVG}
                style={{
                  maxHeight: "150px",
                  paddingRight: "20px",
                }}
                alt=""
              />
            </Col>
          </Row>
        </div>
        <div>
          <div className="contactBox productBox">
            <div className="dataContent">
              <div className="tableHeader">
                <Button type="text" className="manageButton">
                  All Products
                  {/* mvp commented code  */}
                  {/* <SettingFilled style={{color: '#959595'}} /> */}
                </Button>
                {/* <Select defaultValue="All_Products" onChange={selectedProductsOrigin}>
                                        <Option value="All_Products"  className="products-origin-option">All Products</Option>
                                        <Option value="Mapped_from_shopify" className="products-origin-option">Mapped from shopify</Option>
                                        <Option value="Mapped_from_Prestashop" className="products-origin-option">Mapped from Prestashop</Option>
                                        <Option value="Mapped_from_woo_commerce" className="products-origin-option">Mapped from woo commerce</Option>
                                    </Select> */}
                {/* <button onClick={}> </button> */}

                {loading ? (
                  <Skeleton.Button active style={{ width: 200 }} />
                ) : (
                  <div className="flexBox">
                    {/* mvp commented code  */}
                    <div
                      style={{ display: "flex", marginRight: 10 }}
                      className="view-btns"
                    >
                      {/* <Button onClick={() => setTableView(false)} className="listbtn" icon={<img src={tableView ? ListIcon : ListIconActive} />}></Button> */}
                      {/* <Button onClick={() => setTableView(true)} className="tablebtn" icon={<img src={tableView ? TableIconActive : TableIcon} />}></Button> */}
                    </div>
                    <div style={{ position: "relative", marginRight: "10px" }}>
                      <Search
                        className="standard-searchbox"
                        prefix={
                          <SearchOutlined className="standard-searchbox-prefix" />
                        }
                        placeholder="Search names"
                        type="search"
                        value={searchValue}
                        onChange={(e) => onProductSearch(e)}
                        allowClear
                      />
                    </div>

                    {productData?.length > 0 && (
                      <Button
                        className="standard-button primary-orange-button"
                        type="secondary"
                        onClick={() => history.push("/product-mapping")}
                        ghost={false}
                      >
                        Map Products
                      </Button>
                    )}
                    {/* <Button type='secondary' style={{width:"174px",height:"42px",border:"1px solid #F37064",borderRadius:"7px",color:"#F37064",fontSize:"18px",fontWeight:"500",lineHeight:"22px"}} onClick={() => history.push("/product-mapping")}>Map Products</Button>
                                        <Popover visible={open} placement="bottomRight" content={popContent} trigger="hover" onVisibleChange={(value) => setOpen(value)}>
                                            <MoreOutlined className="vertical-dots"/>
                                        </Popover> */}

                    {/* mvp commented code  */}
                    {/* <Button type='secondary' style={{marginRight: '10px' , borderRadius: '7px' , borderColor: '#124173'}}> <img src={settingIcon} height='15' alt="" /> </Button> */}
                    {/* <Button type='secondary' style={{marginRight: '10px' , borderRadius: '7px' , borderColor: '#124173', background: '#124173' , color: 'white'}}> Add Product </Button>
                            <Button onClick={showAddDrawer} style={{background: '#124173', color: 'white', marginRight: 10, borderRadius: 7}}>Add product Attribute</Button>
                            <Button onClick={() => setCsvModel(true)} type='secondary' style={{marginRight: '10px' , borderRadius: '7px' , borderColor: '#124173' , color: '#124173'}}> Import Products </Button> */}
                  </div>
                )}
              </div>

              <div>
                <div style={{ margin: "24px 21px", position: "relative" }}>
                  {loading ? (
                    <>
                      <Row gutter={[20, 20]}>
                        <SkeletonBlock style={{ width: 250 }} />
                        <SkeletonBlock style={{ width: 250 }} />
                        <SkeletonBlock style={{ width: 250 }} />
                        <SkeletonBlock style={{ width: 250 }} />
                      </Row>
                    </>
                  ) : (
                    <Row gutter={16} style={{}}>
                      {searchedProducts?.length > 0 ? (
                        searchedProducts.map((item) => {
                          return (
                            <Col
                              span={6}
                              lg={6}
                              md={6}
                              sm={24}
                              xs={24}
                              key={item.key}
                            >
                              <Link
                                to={{
                                  pathname: "/product-detail",
                                  state: {
                                    productData: {
                                      pid: item?.key,
                                      pname: item?.pName,
                                      cId: item?.cId,
                                    },
                                  },
                                }}
                              >
                                <div
                                  style={{
                                    background: "#F0F7FD",
                                    marginBottom: 20,
                                    borderRadius: "9px",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: 250,
                                    width: "100%",
                                  }}
                                >
                                  <Avatar size={100}>
                                    <span style={{ color: "black" }}>
                                      {item.pName[0].toUpperCase()}
                                    </span>
                                  </Avatar>
                                  <h3
                                    style={{
                                      marginTop: 10,
                                      fontWeight: 600,
                                      color: "#252525",
                                    }}
                                  >
                                    {item.pName}
                                  </h3>
                                </div>
                              </Link>
                            </Col>
                          );
                        })
                      ) : (
                        <>
                          <Row justify="center" style={{ width: "100%" }}>
                            <Col>
                              <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description="No Products"
                              />
                            </Col>
                          </Row>
                        </>
                      )}
                    </Row>
                  )}
                </div>
              </div>

              {/* {tableView ?
                        <ProductGrid />
                        :
                        

                        <ConfigProvider renderEmpty={productData && customizeRenderEmpty}>
                        <Table
                            bordered
                            loading={tableLoading}
                            columns={columns}
                            dataSource={productData}
                            pagination={false}
                            loading = {setTableLoading(false)}
                            
                            

                            footer={() =>
                                <div className="tableFooter">
                                    <h4>Showing 30 per page</h4>
                                </div>
                            }
                        />
                        <Pagination showSizeChanger style={{marginTop:'15px', justifyContent:"center", display:'flex'}} itemRender={itemRender} />
                        </ConfigProvider>
                    } */}
            </div>
          </div>
        </div>

        <Modal
          visible={isInviteUserModelOpen}
          className="invite-modal"
          footer={null}
          width={350}
          centered
          closeIcon={<></>}
          onCancel={() => {
            setisInviteUserModelOpen(false);
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              padding: "10px 10px 50px",
            }}
          >
            <img src={DeleteIcon} height="40" alt="" />
            <h3 style={{ color: "#252525", margin: "10px 0 20px" }}>
              Are you sure you want to
              <br /> delete this contact ?
            </h3>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Button
                className="standard-button tertiary-button"
                style={{
                  marginRight: 8,
                  width: "48%",
                }}
                onClick={() => {
                  setisInviteUserModelOpen(false);
                }}
              >
                No
              </Button>
              <Button
                className="standard-button primary-orange-button"
                type="primary"
                style={{ width: "48%" }}
                htmlType="submit"
              >
                Yes
              </Button>
            </div>
          </div>
        </Modal>

        <Drawer
          width={430}
          title="Add new product attribute"
          placement="right"
          onClose={AddonClose}
          visible={addProductAttributeDrawer}
        >
          <Form
            form={form}
            className="product-attribute-form"
            layout="vertical"
          >
            <Form.Item label="Attribute Title" style={{ width: "100%" }}>
              <Input
                className="standard-input"
                placeholder="Enter attribute title"
              />
            </Form.Item>

            <Form.Item label="Attribute Title" style={{ width: "100%" }}>
              <Radio.Group>
                <Space direction="vertical">
                  <Radio value={1}>Text field</Radio>
                  <Radio value={2}>Date Selection</Radio>
                  <Radio value={3}>Multi-select dropdown</Radio>
                  <Radio value={4}>Single-select dropdown</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Form>
          <div
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              left: 0,
              width: "auto",
              borderTop: "1px solid #e9e9e9",
              fontSize: "18px",
              padding: "10px 16px",
              background: "#EFF4F9",
              textAlign: "right",
              display: "flex",
            }}
          >
            <Button
              className="standard-button tertiary-button"
              style={{ marginRight: 8, width: "48%" }}
              onClick={AddonClose}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              style={{ width: "48%" }}
              htmlType="submit"
              className="standard-button primary-orange-button"
            >
              Add
            </Button>
          </div>
        </Drawer>

        <Modal
          visible={csvModel}
          className="csv-modal"
          footer={null}
          width={600}
          centered
          closeIcon={<></>}
          onCancel={() => {
            setCsvModel(false);
          }}
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col
              span={9}
              lg={9}
              md={9}
              sm={24}
              xs={24}
              className="sampleCsv"
              style={{ background: "#E8F3FF" }}
            >
              <img src={CSVIcon} alt="" />
              <p>
                CSV should be in specifc format. Please check the sample CSV
                format
              </p>
              <Button
                type="primary"
                htmlType="submit"
                className="standard-button primary-orange-button"
              >
                Download sample
              </Button>
              <p style={{ color: "#7D92A9", marginTop: "14px" }}>
                You will recieve confirmation email after upload.
              </p>
            </Col>

            <Col
              span={15}
              lg={15}
              md={15}
              sm={24}
              xs={24}
              className="drag-file"
            >
              <Dragger {...props}>
                <p className="ant-upload-text">Drag and drop CSV file here</p>
              </Dragger>
            </Col>
          </Row>
        </Modal>

        {/* mvp commented code  */}
        {/* <AttributeDrawer /> */}
      </>
      {/* } */}

      {/* <MappedProductsDrawer
        showMappedProductList={showMappedProductList}
        setShowMappedProductList={setShowMappedProductList}
      /> */}
    </>
  );
};

export default Product;
