import React, { useState, useEffect } from "react";
import { Form, InputNumber } from "antd";
import YourLogo from "./../../../assets/Images/YourLogo.png";
import { CloudUploadOutlined, DeleteFilled } from "@ant-design/icons";
import { Button, Col, Row, Upload } from "antd";
import { API } from "aws-amplify";
import { useSelector, useDispatch } from "react-redux";
import { setMicrositeDefaultStyle } from "../../../store/actions/SettingAction";
import axios from "axios";

export default function Logo() {
  const [isImgLoading, setIsImgLoading] = useState(false);

  const { micrositeDefaultStyle } = useSelector(
    (state) => state.SettingReducer
  );

  const { roAdmin } = useSelector((state) => state.loginReducer);
  // const [form] = Form.useForm();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   form.setFieldsValue({height:micrositeDefaultStyle?.logoHeight && Number(micrositeDefaultStyle?.logoHeight.slice(0,-2)),width:micrositeDefaultStyle?.logoWidth && Number(micrositeDefaultStyle?.logoWidth.slice(0,-2))})

  // }, [])

  const uploadFile = async (options) => {
    API.get(
      "blustreamREST",
      `/getSignedPutURL?filename=${options.file.name}`
    ).then(async (response) => {
      await axios
        .put(response.url, options.file, {
          headers: {
            "Content-Type": options.file.type,
            "x-amz-acl": "public-read",
          },
        })
        .then((res) => {
          dispatch(
            setMicrositeDefaultStyle({
              ...micrositeDefaultStyle,
              logoUrl: response.end_url,
            })
          );
        })
        .catch((error) => {
          new Error(error);
        });
    });
  };

  const deleteLogo = () => {
    dispatch(
      setMicrositeDefaultStyle({
        ...micrositeDefaultStyle,
        logoUrl: YourLogo,
      })
    );
  };

  return (
    <>
      <p style={{ color: "#464646" }}>
        The logo below is used as Form logo for the template
      </p>
      <div className="white-box logo-box" style={{ background: "none" }}>
        <Row gutter={10}>
          <Col
            span={24}
            sm={12}
            md={12}
            lg={24}
            xl={24}
            xxl={12}
            style={{ background: "white", borderRadius: "7px" }}
          >
            <Row gutter={10}>
              <Col span={24}>
                <img
                  src={micrositeDefaultStyle?.logoUrl}
                  style={{
                    margin: "auto",
                    display: "flex",
                    width: "100%",
                    padding: "20px 30px",
                  }}
                  alt="logo"
                />
              </Col>
              <Col span={24}>
                <Row gutter={10}>
                  <Col span={12}>
                    <Button
                      className="standard-button primary-dark-blue-button"
                      icon={<DeleteFilled />}
                      style={{
                        width: "calc(100% - 5px)",
                      }}
                      onClick={() => deleteLogo()}
                      disabled={roAdmin}
                    >
                      Delete
                    </Button>
                  </Col>
                  <Col span={12} style={{ paddingRight: "0px" }}>
                    <Upload
                      customRequest={(e) => uploadFile(e)}
                      accept="image/*"
                    >
                      <Button
                        className="standard-button primary-dark-blue-button"
                        loading={isImgLoading}
                        icon={<CloudUploadOutlined />}
                        style={{
                          width: "calc(100% - 5px)",
                        }}
                        disabled={roAdmin}
                      >
                        Upload
                      </Button>
                    </Upload>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <Form>
        {/* form={form} */}
        <Row style={{ paddingTop: "10px" }}>
          <Col span={24}>
            <Form.Item label="Logo Height  :">
              <InputNumber
                // name="height"
                type="number"
                placeholder="Enter Height"
                onChange={(e) => {
                  // if (e <= 300) {
                  dispatch(
                    setMicrositeDefaultStyle({
                      ...micrositeDefaultStyle,
                      logoHeight:
                        e === null
                          ? "30px"
                          : e > 300
                          ? "300px"
                          : e <= 0
                          ? "30px"
                          : `${e}px`,
                    })
                  );
                  // }
                }}
                disabled={roAdmin}
                min={0}
                max={300}
                defaultValue={
                  micrositeDefaultStyle?.logoHeight &&
                  Number(micrositeDefaultStyle?.logoHeight.slice(0, -2))
                }
              ></InputNumber>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Logo Width  :">
              <InputNumber
                // name="width"
                type="number"
                placeholder="Enter Width"
                onChange={(e) =>
                  dispatch(
                    setMicrositeDefaultStyle({
                      ...micrositeDefaultStyle,
                      logoWidth:
                        e === null
                          ? "auto"
                          : e > 300
                          ? `300px`
                          : e <= 0
                          ? "auto"
                          : `${e}px`,
                    })
                  )
                }
                disabled={roAdmin}
                min={0}
                max={300}
                defaultValue={
                  micrositeDefaultStyle?.logoWidth &&
                  Number(micrositeDefaultStyle?.logoWidth.slice(0, -2))
                }
              ></InputNumber>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <span style={{ fontStyle: "italic" }}>
              *Logo height and width should not be more than 300px
            </span>
          </Col>
        </Row>
      </Form>
    </>
  );
}
