import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Typography,
} from "antd";
import { Auth } from "aws-amplify";
import backgroundImg from "assets/Images/login_signup_background.svg";
import { useHistory } from "react-router-dom";
import logoWhite from "assets/Images/logo-white.svg";
import { emailPattern } from "utility/Constants";
import moment from "moment";
import { CopyrightOutlined, MailOutlined } from "@ant-design/icons";

function ConfirmEmail(props) {
  const [loading, setloading] = useState(false);
  const [error, seterror] = useState("");
  const [msg, setmsg] = useState("Check your email for a confirmation code");
  const [email] = useState(props.location.state?.userName);
  const history = useHistory();
  const [form] = Form.useForm();

  useEffect(() => {
    if (!props.location.state) {
      if (
        window.location.href.includes("confirmationcode=") &&
        window.location.href.includes("/confirm_sign_up")
      ) {
        let Data = {};
        if (localStorage.getItem("User")) {
          Data.email = window.atob(localStorage.getItem("User"));
        }
        Data.code = window.location.href
          .split("confirmationcode=")[1]
          .split("&email=")[0];
        Data.email = window.location.href.split("email=")[1];
        form.setFieldsValue({
          code: Data.code,
          email: Data.email,
        });
        handleConfirmSignUp(form.getFieldsValue(["email", "code"]));
      } else {
        history.push("/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResendCode = async () => {
    setloading(true);
    seterror("");
    try {
      await Auth.resendSignUp(email);
      setmsg("A new confirmation code has been sent.");
      setloading(false);
    } catch (error) {
      console.log("error signing up:", error);
      setmsg(error.message);
      setloading(false);
    }
  };

  const handleConfirmSignUp = async (e) => {
    try {
      if (e.email && e.code) {
        setloading(true);
        seterror("");
        await Auth.confirmSignUp(e.email, e.code);
        setloading(false);
        history.push({
          pathname: "/signin",
          state: {
            feedback: "Sign in with your new account",
            userName: e.email,
          },
        });
      } else {
        message.error("something went wrong");
      }
    } catch (error) {
      if (
        error.message.includes(
          "User cannot be confirmed. Current status is CONFIRMED"
        )
      ) {
        history.push("/");
        message.success("Already Verified");
      }
      console.log("error signing up:", error);
      setmsg(error.message);
      setloading(false);
    }
  };

  return (
    <Row justify="center" align="middle">
      <Col xs={0} md={0} lg={7} xl={6} className="sidebar-background">
        <Col className="info" style={{ position: "absolute", top: 0, left: 0 }}>
          <div className="info-logo">
            <img src={logoWhite} alt="" />
          </div>
          <div className="info-text">
            <Typography.Title className="login-heading" level={1}>
              Welcome to Blustream!
            </Typography.Title>
            <Typography.Text className="login-heading-text">
              Be a part of Blustream to manage and organise daily activities of
              your customers{" "}
            </Typography.Text>
            <Button
              type="primary"
              size="large"
              tabIndex={5}
              className="btn-secondary"
              style={{
                marginTop: 44,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                padding: "12px 20px",
                borderRadius: "8px",
                lineHeight: "16px",
              }}
              onClick={() => history.push("/signup")}
            >
              <span style={{ fontWeight: "bold" }}>Create Account</span>
            </Button>
          </div>
        </Col>
      </Col>
      <Col xs={24} md={24} lg={17} xl={18} style={{ transform: "scale(0.88)" }}>
        <Row
          justify="center"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
            height: "100vh",
            minHeight: 580,
          }}
        >
          <Card
            className="confirm-email-form-container"
            style={{ border: "none", width: "437px", padding: "0px" }}
          >
            <div>
              <Typography.Title
                level={3}
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  paddingBottom: 34,
                }}
              >
                Confirm Signup{" "}
              </Typography.Title>
            </div>
            {msg && (
              <Alert
                style={{ marginBottom: "10px" }}
                type="success"
                showIcon={false}
                message={msg}
                banner
              />
            )}
            {error !== "" && (
              <Alert
                style={{ marginBottom: "10px" }}
                type="error"
                showIcon={false}
                message={error}
                banner
                closable
              />
            )}
            <Form
              form={form}
              labelCol={{ xs: 24 }}
              requiredMark={false}
              layout="vertical"
              name="normal_signup"
              className="signup-form"
              onFinish={(e) => handleConfirmSignUp(e)}
            >
              <Form.Item
                initialValue={email}
                name="email"
                rules={[
                  { required: true, message: "Please input your Email!" },
                  {
                    pattern: emailPattern,
                    message: "Please provide valid Email!",
                  },
                ]}
              >
                <Input
                  className="standard-input"
                  value={email}
                  id="email"
                  key="email"
                  name="email"
                  placeholder="Email ID"
                  disabled={localStorage.getItem("User")}
                  style={{ color: "gray", fontSize: "18px" }}
                  size="large"
                  prefix={<MailOutlined />}
                />
              </Form.Item>

              <Form.Item
                name="code"
                rules={[
                  {
                    required: true,
                    message: "Please input your Confirmation Code!",
                  },
                ]}
              >
                <Input
                  className="standard-input"
                  autoFocus
                  id="code"
                  key="code"
                  name="code"
                  placeholder="Confirmation code"
                  disabled={loading}
                  tabIndex={1}
                  size="large"
                  prefix={<MailOutlined />}
                />
              </Form.Item>

              <h4
                style={{
                  fontSize: 16,
                  textAlign: "right",
                  color: !loading ? "#F5786D" : "gray",
                  paddingBottom: 30,
                  cursor: "pointer",
                }}
                onClick={handleResendCode}
              >
                Resend Code
              </h4>
              <Form.Item>
                <Row justify="space-between" align="middle">
                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    block
                    loading={loading}
                    tabIndex={5}
                    className="standard-button primary-orange-button"
                  >
                    <span style={{ fontWeight: "bold" }}>Confirm</span>
                  </Button>
                </Row>
              </Form.Item>
            </Form>
          </Card>
          <span
            style={{
              color: "#646C79",
              fontSize: "16px",
              textAlign: "center",
              fontWeight: "500",
              userSelect: "none",
              marginBottom: "0px",
              position: "fixed",
              bottom: 0,
            }}
          >
            <div>
              <div>
                Version{" "}
                {process.env.REACT_APP_VERSION
                  ? process.env.REACT_APP_VERSION
                  : "0.1.0"}
              </div>
              <CopyrightOutlined />
              {`${moment().year()}. Blustream.io`}
            </div>
          </span>
        </Row>
      </Col>
    </Row>
  );
}

export default ConfirmEmail;
