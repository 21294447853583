import React,{useState} from 'react'

import {Typography,Select,Drawer, Divider,Modal,Button} from 'antd';
import {CloseOutlined} from "@ant-design/icons";
import dog1 from "./../../assets/Images/dog1.svg";
import dog2 from "./../../assets/Images/dog2.svg";
import dog4 from "./../../assets/Images/dog4.svg";
import keyboard from "./../../assets/Images/keyboard.svg";
import guitar1 from "./../../assets/Images/guitar1.png";
import guitar2 from "./../../assets/Images/guitar2.png";
import guitar3 from "./../../assets/Images/guitar3.png";
import blustreamLogo from "./../../assets/Images/blustreamLogo.svg";
import glowingBulbIcon from "./../../assets/Images/glowingBulbIcon.svg";

const { Option } = Select;

const MappedProductsDrawer = ({showMappedProductList,setShowMappedProductList}) => {

  const [unmapWarningModalVisible, setUnmapWarningModalVisible] = useState(false);

  const selectedPlatform = (value) => {
      console.log(`selected platform ${value}`);
  };

  const hideUnmapWarningModal = () => {
      setUnmapWarningModalVisible(false)
  }

  const unmapProducts = () => {
      console.log("unmap Products!");
      setUnmapWarningModalVisible(false)
  }

  return (
      <>
        <Drawer
        className='mapped-product-list-container'
            visible={showMappedProductList}
            title={<strong className="drawer-title">All Mapped products</strong>}
            width={442}
            onClose={() => {
            setShowMappedProductList(false);
            }}
            destroyOnClose
            maskClosable={false}
            style={{zIndex:"99"}}
        >
            <Select className="platforms-dropdown" defaultValue="Shopify" onChange={selectedPlatform}>
                <Option value="Shopify" className="platform-option">Shopify</Option>
                <Option value="Prestashop" className="platform-option">Prestashop</Option>
                <Option value="Big_commerce"  className="platform-option">Big commerce</Option>
            </Select>
            <div className='mapped-group'>
            <div className='mapped-blustream-product-container'>
                <img className='blustream-logo' src={blustreamLogo} alt=""/>
                <Typography.Text className='mapped-blustream-product-name'>Electric Guitar</Typography.Text>
            </div>
            <div className='platform-product-container'>
                <div className='product-details-wrapper'>
                <img className='platform-product-img' src={guitar1} alt=""/>
                <span className='platform-product-name'>guitar1</span>
                </div>
                <div className='close-icon-container'>
                <CloseOutlined className='close-icon' onClick = {() => setUnmapWarningModalVisible(true)}/>
                </div>
            </div>
            <div className='platform-product-container'>
                <div className='product-details-wrapper'>
                <img className='platform-product-img' src={guitar2} alt=""/>
                <span className='platform-product-name'>guitar2</span>
                </div>
                <div className='close-icon-container'>
                <CloseOutlined className='close-icon' onClick = {() => setUnmapWarningModalVisible(true)}/>
                </div>
            </div>
            <div className='platform-product-container'>
                <div className='product-details-wrapper'>
                <img className='platform-product-img' src={guitar3} alt=""/>
                <span className='platform-product-name'>guitar3</span>
                </div>
                <div className='close-icon-container'>
                <CloseOutlined className='close-icon' onClick = {() => setUnmapWarningModalVisible(true)}/>
                </div>
            </div>
            <div className='platform-product-container'>
                <div className='product-details-wrapper'>
                <img className='platform-product-img' src={keyboard} alt=""/>
                <span className='platform-product-name'>keyboard</span>
                </div>
                <div className='close-icon-container'>
                <CloseOutlined className='close-icon' onClick = {() => setUnmapWarningModalVisible(true)}/>
                </div>
            </div>
            </div>
            <Divider style={{border:"0.5px solid #C7D6E5"}}/>
            <div className='mapped-group'>
            <div className='mapped-blustream-product-container'>
                <img className='blustream-logo' src={blustreamLogo} alt=""/>
                <Typography.Text className='mapped-blustream-product-name'>Golden Retriever</Typography.Text>
            </div>
            <div className='platform-product-container'>
                <div className='product-details-wrapper'>
                <img className='platform-product-img' src={dog1} alt=""/>
                <span className='platform-product-name'>dog1</span>
                </div>
                <div className='close-icon-container'>
                <CloseOutlined className='close-icon' onClick = {() => setUnmapWarningModalVisible(true)}/>
                </div>
            </div>
            <div className='platform-product-container'>
                <div className='product-details-wrapper'>
                <img className='platform-product-img' src={dog2} alt=""/>
                <span className='platform-product-name'>dog2</span>
                </div>
                <div className='close-icon-container'>
                <CloseOutlined className='close-icon' onClick = {() => setUnmapWarningModalVisible(true)}/>
                </div>
            </div>
            <div className='platform-product-container'>
                <div className='product-details-wrapper'>
                <img className='platform-product-img' src={dog4} alt=""/>
                <span className='platform-product-name'>dog4</span>
                </div>
                <div className='close-icon-container'>
                <CloseOutlined className='close-icon' onClick = {() => setUnmapWarningModalVisible(true)}/>
                </div>
            </div>
            <div className='platform-product-container'>
                <div className='product-details-wrapper'>
                <img className='platform-product-img' src={dog1} alt=""/>
                <span className='platform-product-name'>puppy</span>
                </div>
                <div className='close-icon-container'>
                <CloseOutlined className='close-icon' onClick = {() => setUnmapWarningModalVisible(true)}/>
                </div>
            </div>
            </div>
            <Divider style={{border:"0.5px solid #C7D6E5"}}/>
            <div className='mapped-group'>
            <div className='mapped-blustream-product-container'>
                <img className='blustream-logo' src={blustreamLogo} alt=""/>
                <Typography.Text className='mapped-blustream-product-name'>Electric Guitar</Typography.Text>
            </div>
            <div className='platform-product-container'>
                <div className='product-details-wrapper'>
                <img className='platform-product-img' src={guitar1} alt=""/>
                <span className='platform-product-name'>guitar1</span>
                </div>
                <div className='close-icon-container'>
                <CloseOutlined className='close-icon' onClick = {() => setUnmapWarningModalVisible(true)}/>
                </div>
            </div>
            <div className='platform-product-container'>
                <div className='product-details-wrapper'>
                <img className='platform-product-img' src={guitar2} alt=""/>
                <span className='platform-product-name'>guitar2</span>
                </div>
                <div className='close-icon-container'>
                <CloseOutlined className='close-icon' onClick = {() => setUnmapWarningModalVisible(true)}/>
                </div>
            </div>
            <div className='platform-product-container'>
                <div className='product-details-wrapper'>
                <img className='platform-product-img' src={guitar3} alt=""/>
                <span className='platform-product-name'>guitar3</span>
                </div>
                <div className='close-icon-container'>
                <CloseOutlined className='close-icon' onClick = {() => setUnmapWarningModalVisible(true)}/>
                </div>
            </div>
            <div className='platform-product-container'>
                <div className='product-details-wrapper'>
                <img className='platform-product-img' src={keyboard} alt=""/>
                <span className='platform-product-name'>keyboard</span>
                </div>
                <div className='close-icon-container'>
                <CloseOutlined className='close-icon' onClick = {() => setUnmapWarningModalVisible(true)}/>
                </div>
            </div>
            </div>
            <Divider style={{border:"0.5px solid #C7D6E5"}}/>
            <div className='mapped-group'>
            <div className='mapped-blustream-product-container'>
                <img className='blustream-logo' src={blustreamLogo} alt=""/>
                <Typography.Text className='mapped-blustream-product-name'>Golden Retriever</Typography.Text>
            </div>
            <div className='platform-product-container'>
                <div className='product-details-wrapper'>
                <img className='platform-product-img' src={dog1} alt=""/>
                <span className='platform-product-name'>dog1</span>
                </div>
                <div className='close-icon-container'>
                <CloseOutlined className='close-icon' onClick = {() => setUnmapWarningModalVisible(true)}/>
                </div>
            </div>
            <div className='platform-product-container'>
                <div className='product-details-wrapper'>
                <img className='platform-product-img' src={dog2} alt=""/>
                <span className='platform-product-name'>dog2</span>
                </div>
                <div className='close-icon-container'>
                <CloseOutlined className='close-icon' onClick = {() => setUnmapWarningModalVisible(true)}/>
                </div>
            </div>
            <div className='platform-product-container'>
                <div className='product-details-wrapper'>
                <img className='platform-product-img' src={dog4} alt=""/>
                <span className='platform-product-name'>dog4</span>
                </div>
                <div className='close-icon-container'>
                <CloseOutlined className='close-icon' onClick = {() => setUnmapWarningModalVisible(true)}/>
                </div>
            </div>
            <div className='platform-product-container'>
                <div className='product-details-wrapper'>
                <img className='platform-product-img' src={dog1} alt=""/>
                <span className='platform-product-name'>puppy</span>
                </div>
                <div className='close-icon-container'>
                <CloseOutlined className='close-icon' onClick = {() => setUnmapWarningModalVisible(true)}/>
                </div>
            </div>
            </div>
            <Divider style={{border:"0.5px solid #C7D6E5"}}/>
        </Drawer>
      <Modal centered  visible={unmapWarningModalVisible} footer={null} className="unmap-product-modal-container" onCancel={() => hideUnmapWarningModal()}>
        <div className="unmap-product-modal">
          <img src={glowingBulbIcon} alt="" />
          <p className="unmap-product-modal-dark-text">Sure you want to un-map this product with Blustream?</p>
          <p className="unmap-product-modal-light-text">All the data related to this product in blustream will be removed!</p>
          <div className="unmap-product-modal-container">
            <Button className="unmap-product-modal-denied-button" onClick={() => hideUnmapWarningModal()}>No</Button>
            <Button className="unmap-product-modal-confirm-button" type="primary" onClick={() => unmapProducts()}>Yes</Button>
          </div>
        </div>
      </Modal>
      </>
  )
}

export default MappedProductsDrawer;