import {
  AppstoreOutlined,
  EyeOutlined,
  LayoutOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  PageHeader,
  Popover,
  Row,
  Tabs,
  Typography,
  Modal,
  message,
  Switch,
  Image,
  Tooltip,
  Form,
} from "antd";
import React, { useState, useEffect, useRef } from "react";
import Publish from "./Publish";
import Components from "./Tabs/Components";
import Layout from "./Tabs/Layout";
import Settings from "./Tabs/Settings";
import { useSelector, useDispatch } from "react-redux";
import {
  setMicrositeDefaultStyle,
  setActiveKey,
  setMicrositeFormElements,
  setMicrositeId,
  setDisabled,
  setTemplateName,
  setCurrentFormElementId,
  setLogokey,
} from "../../store/actions/SettingAction";
import DynamicFormBody from "./DynamicFormBody";
import { createMicrosite, updateMicrosite } from "graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";
import { Prompt, useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import YourLogo from "./../../assets/Images/YourLogo.png";
import successIcon2 from "./../../assets/Images/successIcon2.svg";
import formImage from "./../../assets/Images/formImage.svg";
import openHead from "./../../assets/Images/openHead.svg";
import PreviewMicrosite from "./PreviewMicrosite";
import "./microsite.css";
import i18n from "./../../i18n";
import { useTranslation } from "react-i18next";

const { Paragraph, Text } = Typography;
const { TabPane } = Tabs;

export default function FormBuilder() {
  const { t } = useTranslation("translations");
  const popupForm = Form.useForm()[0];
  const { groups, accountDetails, roAdmin } = useSelector(
    (state) => state.loginReducer
  );
  const { Title } = Typography;
  const initialStyle = {
    micrositeName: "Write Your Form Title Here...",
    micrositePageTitle: "Untitled Form",
    themeStyle: {
      backgroundColor: "#CDE1F3",
      secondaryBackgroundColor: "#ffffff",
      backgroundImageUrl: "",
      backgroundWidth: "100",
      backgroundHeight: "100",
    },
    logoUrl: `${YourLogo}`,
    logoHeight: "30px",
    logoWidth: "auto",
    headingStyle: {
      fontFamily: "arial",
      fontSize: "23px",
      fontStyle: "normal",
      fontWeight: 400,
      color: "#000000",
    },
    subHeadingStyle: {
      fontFamily: "arial",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      color: "#000000",
    },
    textStyle: {
      fontFamily: "arial",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      color: "#000000",
    },
    questionStyle: {
      fontFamily: "arial",
      fontSize: "17px",
      fontStyle: "normal",
      fontWeight: 400,
      color: "#000000",
    },
    answerStyle: {
      fontFamily: "arial",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      color: "#000000",
    },
    descriptionStyle: {
      fontFamily: "arial",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      color: "#000000",
    },
    fieldStyle: {
      backgroundColor: "#ffffff",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "#000000",
      borderRadius: "7px",
    },
    buttonStyle: {
      fontFamily: "arial",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      backgroundColor: "#ffffff",
      color: "#000000",
      height: "auto",
      borderColor: "#000000",
    },
    companyTextStyle: {
      fontFamily: "arial",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 500,
      color: "#000000",
    },
    errorMessageColor: "#ff0000",
    successMessage: "Congrats and we can't wait for your journey to start!",
    formLanguage: "en",
    permenantHideOnSubmit: false,
    temporaryHideOnPopupClose: false,
    numberOfHidingDays: 1,
  };
  // Dev Comment :- don't change the index of the array as it will break the flow'
  const initialFormElements = [
    {
      id: uuidv4(),
      htmlType: "",
      type: "image",
      src: "link",
      style: {
        display: "flex",
        justifyContent: "center",
        margin: "auto auto 10px",
      },
    },
    {
      id: uuidv4(),
      htmlType: "",
      type: "heading",
      headingText: "Let's get started!",
      style: { marginTop: 10 },
    },
    {
      id: uuidv4(),
      htmlType: "",
      type: "sub-heading",
      headingText: "Your sub heading goes here!",
      style: { marginBottom: 15 },
    },
    {
      id: uuidv4(),
      htmlType: "text",
      type: "default-input",
      label: "First Name",
      placeholder: "Enter first name",
      style: {},
    },
    {
      id: uuidv4(),
      htmlType: "text",
      type: "default-input",
      label: "Last Name",
      placeholder: "Enter last name",
      style: {},
    },

    {
      id: uuidv4(),
      htmlType: "tel",
      type: "default-input",
      label: "Phone No.",
      placeholder: "Enter phone number",
      style: {},
    },
    {
      id: uuidv4(),
      htmlType: "email",
      type: "default-email",
      label: "Email",
      placeholder: "Enter email",
      isRequired: false,
      style: {},
    },
    {
      id: uuidv4(),
      htmlType: "",
      type: "button",
      buttonText: "Send",
      style: {},
    },
    {
      id: uuidv4(),
      htmlType: "",
      type: "Consent-text",
      textValue: `By submitting this form, you consent to receive marketing text messages from ${accountDetails?.company} via an automatic telephone dialing system and that the number you have provided is your own. Consent is not a condition of any purchase. Standard message and data rates may apply. At any time, you can unsubscribe from all messages by replying STOP. View our Privacy Policy and Terms of Service.`,
      style: {},
    },
    {
      id: uuidv4(),
      htmlType: "",
      type: "copyright",
      textValue: `© ${new Date().getFullYear()} ${accountDetails?.company}`,
      style: {},
    },
    {
      id: uuidv4(),
      htmlType: "",
      type: "company-text",
      textValue: `Sign up today to start your product journey via SMS with ${accountDetails?.company}. Get useful tips on the care, maintenance, and enjoyment around your products.`,
      style: {},
    },
  ];
  const {
    micrositeDefaultStyle,
    micrositeFormElements,
    templateName,
    micrositeId,
    activeKey,
    disabled,
  } = useSelector((state) => state.SettingReducer);

  const dispatch = useDispatch();
  const history = useHistory();
  const isMounted = useRef(false); //to stop useEffect from running on mounting.
  const flag = useRef(true); //show success message for directly publishing the default form.

  const [formUrl, setFormUrl] = useState("");
  const [isFormChanges, setIsFormChanges] = useState(false);
  const [isShowModal, setisShowModal] = useState(false);
  const [nextLocation, setnextLocation] = useState(null);
  const [confirmedNavigation, setconfirmedNavigation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isPopUpVisible, setIsPopUpVisible] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [formId, setFormId] = useState("");
  const [saveModal, setSaveModal] = useState(false);
  const [publishModal, setPublishModal] = useState(false);
  const [publishSuccessModal, setPublishSuccessModal] = useState(false);
  const [unpublishModal, setUnpublishModal] = useState(false);
  const [unPublishSuccessModal, setUnPublishSuccessModal] = useState(false);

  const handleLanguageChange = (newLang) => {
    const formElements = [...micrositeFormElements];
    const updatedFormElements = formElements?.map((elementObject, index) => {
      if (
        elementObject?.type === "default-input" ||
        elementObject?.type === "default-email"
      ) {
        if (index === 3) {
          elementObject["label"] = t(`FORMS.firstNameLabel`);
          elementObject["placeholder"] = t(`FORMS.firstNamePlaceholder`);
        } else if (index === 4) {
          elementObject["label"] = t(`FORMS.lastNameLabel`);
          elementObject["placeholder"] = t(`FORMS.lastNamePlaceholder`);
        } else if (index === 5) {
          elementObject["label"] = t(`FORMS.phoneLabel`);
          elementObject["placeholder"] = t(`FORMS.phonePlaceholder`);
        } else if (index === 6) {
          elementObject["label"] = t(`FORMS.emailLabel`);
          elementObject["placeholder"] = t(`FORMS.emailPlaceholder`);
        }
      }
      return elementObject;
    });
    dispatch(setMicrositeFormElements(updatedFormElements));
  };

  useEffect(() => {
    if (isMounted.current) {
      handleLanguageChange();
    }
  }, [micrositeDefaultStyle?.formLanguage]);

  useEffect(() => {
    if (isMounted.current) {
      setIsFormChanges(true);
      flag.current = false;
    } else {
      isMounted.current = true;
    }
  }, [micrositeDefaultStyle, micrositeFormElements, templateName]);

  const hide = () => {
    setIsPopUpVisible(false);
  };

  const handleVisibleChange = (newVisible) => {
    setIsPopUpVisible(newVisible);
  };

  const error = (publishMessage) => {
    message.error(publishMessage);
  };

  const showModal = (nextLocation) => {
    setisShowModal(true);
    setnextLocation(nextLocation);
    setconfirmedNavigation(true);
  };

  const onLeave = () => {
    setisShowModal(false);
    history.push(nextLocation);
  };

  const onStay = () => {
    setisShowModal(false);
    setconfirmedNavigation(false);
  };

  const preventNavigation = (nextLocation) => {
    const location = history.location;
    if (location.pathname === nextLocation.pathname) {
      return false;
    }

    if (confirmedNavigation) {
      return true;
    }

    showModal(nextLocation.pathname);
    return false;
  };

  const addMicrosite = async () => {
    setLoading(true);
    setIsFormChanges(false);
    if (micrositeId === "") {
      try {
        const response = await API.graphql(
          graphqlOperation(createMicrosite, {
            input: {
              account: groups[0],
              templateName: templateName,
              disabled: true,
              micrositeFormElements: JSON.stringify(micrositeFormElements),
              micrositeStyle: JSON.stringify(micrositeDefaultStyle),
            },
          })
        );
        setLoading(false);
        setSaveModal(true);
        dispatch(setDisabled(true));
        const id = response?.data?.createMicrosite?.id;

        dispatch(setMicrositeId(id));
      } catch (e) {
        setLoading(false);
        if (isFormChanges || flag.current) {
          error("Something Went Wrong!");
        }
        console.log(e);
      }
    } else {
      try {
        await API.graphql(
          graphqlOperation(updateMicrosite, {
            input: {
              id: micrositeId,
              account: groups[0],
              templateName: templateName,
              disabled: disabled,
              micrositeFormElements: JSON.stringify(micrositeFormElements),
              micrositeStyle: JSON.stringify(micrositeDefaultStyle),
            },
          })
        );
        setLoading(false);
        setSaveModal(true);
      } catch (e) {
        setLoading(false);
        if (isFormChanges) {
          error("Something Went Wrong!");
        }
        console.log(e);
      }
    }
  };

  /**
   * @desc publish the form so that it is available to all the end users.
   */
  const publishForm = async () => {
    setLoading(true);
    setIsFormChanges(false);
    try {
      await API.graphql(
        graphqlOperation(updateMicrosite, {
          input: {
            id: micrositeId,
            account: groups[0],
            templateName: templateName,
            disabled: false,
            micrositeFormElements: JSON.stringify(micrositeFormElements),
            micrositeStyle: JSON.stringify(micrositeDefaultStyle),
          },
        })
      );
      setLoading(false);
      setPublishModal(false);
      dispatch(setDisabled(false));
      setPublishSuccessModal(true);
    } catch (e) {
      setLoading(false);
      if (isFormChanges) {
        error("Something Went Wrong!");
      }
      console.log(e);
    }
  };

  /**
   * @desc unpublish the form so that it is not available to all the end users.
   */
  const unpublishForm = async () => {
    try {
      await API.graphql(
        graphqlOperation(updateMicrosite, {
          input: {
            id: micrositeId,
            account: groups[0],
            templateName: templateName,
            disabled: true,
            micrositeFormElements: JSON.stringify(micrositeFormElements),
            micrositeStyle: JSON.stringify(micrositeDefaultStyle),
          },
        })
      );
      setLoading(false);
      setUnpublishModal(false);
      setUnPublishSuccessModal(true);
      dispatch(setDisabled(true));
    } catch (e) {
      setLoading(false);
      error("Something Went Wrong!");
      console.log(e);
    }
  };

  const activeTabHandler = (key) => {
    dispatch(setActiveKey(key));
  };

  const setDefaultRedux = () => {
    dispatch(setMicrositeDefaultStyle(initialStyle));
    dispatch(setMicrositeFormElements(initialFormElements));
    dispatch(setTemplateName("TWO_COLUMN_LEFT_FORM"));
    dispatch(setMicrositeId(""));
    dispatch(setCurrentFormElementId(""));
    dispatch(setActiveKey("1"));
    dispatch(setDisabled(true));
  };

  return (
    <>
      <Prompt message={preventNavigation} when={isFormChanges} />
      <Modal
        className="preventNavModal"
        visible={isShowModal}
        closable={false}
        footer={[
          <Button
            className="standard-button secondary-orange-button"
            key="back"
            onClick={() => {
              onLeave();
              setDefaultRedux();
            }}
          >
            Leave Anyway
          </Button>,
          <Button
            className="standard-button primary-orange-button"
            key="stay"
            type="primary"
            onClick={onStay}
          >
            Stay
          </Button>,
        ]}
      >
        <Row>
          <Col span={24}>
            <Typography.Title level={5}>
              Are you sure you want to leave?
            </Typography.Title>
            <Typography.Text type="secondary">
              You have unsaved changes on this page. Leaving this page without
              saving may result in your changes being lost.
            </Typography.Text>
          </Col>
        </Row>
      </Modal>
      <Modal
        className="preview-modal"
        centered
        visible={showPreview}
        onOk={() => setShowPreview(false)}
        onCancel={() => setShowPreview(false)}
        width="100vw"
        footer={null}
      >
        <PreviewMicrosite />
      </Modal>
      <Modal
        centered
        width={340}
        visible={saveModal}
        footer={null}
        onCancel={() => setSaveModal(false)}
      >
        <Row justify="center" align="middle" className="form-save">
          <Image preview={false} src={successIcon2} alt="successIcon" />
          <Title level={4} style={{ color: "#25B291", margin: "0px" }}>
            Form Saved successfully!
          </Title>
          <Title level={4} style={{ margin: "0px" }}>
            {micrositeDefaultStyle?.micrositePageTitle}
          </Title>
        </Row>
      </Modal>
      <Modal
        centered
        width={330}
        visible={publishModal}
        footer={null}
        onCancel={() => setPublishModal(false)}
      >
        <Row justify="center" align="middle" className="form-save">
          <Image preview={false} src={formImage} alt="successIcon" />
          <Text
            style={{
              fontSize: "18px",
              lineHeight: "22px",
              color: "#252525",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            Do you want to publish this form?
          </Text>
          <Text
            style={{
              fontSize: "18px",
              lineHeight: "24px",
              color: "#686868",
              fontWeight: "400",
              textAlign: "center",
            }}
          >
            Your customers will see all the updated details in this form!
          </Text>
          <div className="publish-modal-button-container">
            <Button
              className="standard-button tertiary-button"
              onClick={() => setPublishModal(false)}
            >
              No
            </Button>
            <Button
              className="standard-button primary-orange-button"
              type="primary"
              onClick={() => {
                dispatch(setDisabled(false));
                publishForm();
              }}
            >
              Yes
            </Button>
          </div>
        </Row>
      </Modal>
      <Modal
        centered
        width={420}
        visible={publishSuccessModal}
        footer={null}
        onCancel={() => setPublishSuccessModal(false)}
      >
        <Row justify="center" align="middle" className="form-save">
          <Image src={successIcon2} alt="successIcon" preview={false} />
          <Title level={4} style={{ color: "#25B291", margin: "0px" }}>
            Form Published successfully!
          </Title>
          <Title level={4} style={{ margin: "0px" }}>
            {micrositeDefaultStyle?.micrositePageTitle}
          </Title>
        </Row>
      </Modal>
      <Modal
        centered
        width={330}
        visible={unpublishModal}
        footer={null}
        onCancel={() => setUnpublishModal(false)}
      >
        <Row justify="center" align="middle" className="form-save">
          <Image src={openHead} alt="openHead" preview={false} />
          <Text
            style={{
              fontSize: "18px",
              lineHeight: "25px",
              color: "#252525",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            Are you sure you want to unpublish this form?
          </Text>
          <div className="unpublish-modal-button-container">
            <Button
              className="standard-button tertiary-button"
              onClick={() => setUnpublishModal(false)}
            >
              No
            </Button>
            <Button
              className="standard-button primary-orange-button"
              type="primary"
              onClick={() => {
                dispatch(setDisabled(true));
                unpublishForm();
              }}
            >
              Yes
            </Button>
          </div>
        </Row>
      </Modal>
      <Modal
        centered
        width={420}
        visible={unPublishSuccessModal}
        footer={null}
        onCancel={() => setUnPublishSuccessModal(false)}
      >
        <Row justify="center" align="middle" className="form-save">
          <Image src={successIcon2} alt="successIcon" preview={false} />
          <Title level={4} style={{ color: "#25B291", margin: "0px" }}>
            Form Unpublished successfully!
          </Title>
          <Title level={4} style={{ margin: "0px" }}>
            {micrositeDefaultStyle?.micrositePageTitle}
          </Title>
        </Row>
      </Modal>
      <div className="site-page-header-ghost-wrapper">
        <PageHeader
          className="site-page-header-responsive wrapperBox-header"
          style={{ background: "transparent", padding: 0 }}
          ghost={false}
          onBack={() => {
            window.history.back();
            if (!isFormChanges) {
              dispatch(setMicrositeId(""));
              dispatch(setCurrentFormElementId(""));
              dispatch(setActiveKey("1"));
              dispatch(setDisabled(true));
              dispatch(setLogokey(false));
            }
          }}
          title="Form"
          subTitle={
            <Paragraph
              className="title"
              editable={
                !roAdmin
                  ? {
                      onChange: (event) => {
                        dispatch(
                          setMicrositeDefaultStyle({
                            ...micrositeDefaultStyle,
                            micrositeName: event,
                          })
                        );
                      },
                    }
                  : ""
              }
              style={{ marginBottom: 0, color: "#124173" }}
            >
              {micrositeDefaultStyle.micrositeName}
            </Paragraph>
          }
          extra={[
            <span
              key="1"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginRight: "10px",
              }}
            >
              <Tooltip
                title={
                  micrositeId === ""
                    ? "Please save the form inorder to publish it."
                    : isFormChanges
                    ? "There are unsaved changes. Please save first."
                    : ""
                }
              >
                <Switch
                  checked={!disabled}
                  onChange={(event) => {
                    if (event) {
                      setPublishModal(true);
                    } else {
                      setUnpublishModal(true);
                    }
                  }}
                  disabled={roAdmin || micrositeId === "" || isFormChanges}
                />
              </Tooltip>
              <span
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  lineHeight: "22px",
                  color: "#124173",
                }}
              >
                Publish
              </span>
            </span>,

            <Button
              key="2"
              className="standard-button primary-dark-blue-button"
              onClick={async (event) => {
                if (micrositeDefaultStyle?.temporaryHideOnPopupClose) {
                  popupForm
                    .validateFields()
                    .then(() => {
                      addMicrosite();
                      event.stopPropagation();
                    })
                    .catch(() => {
                      message.error("Please enter days for hiding popup!");
                    });
                } else {
                  addMicrosite();
                  event.stopPropagation();
                }
              }}
              disabled={
                roAdmin || (micrositeId !== "" ? !isFormChanges : false)
              }
            >
              Save
            </Button>,
            <Button
              key="3"
              className="standard-button secondary-dark-blue-button"
              onClick={() => setShowPreview(true)}
            >
              <EyeOutlined />
            </Button>,
            <Popover
              content={
                <Publish
                  url={formUrl}
                  formId={formId}
                  loading={loading}
                  hide={hide}
                />
              }
              trigger={roAdmin ? "" : "click"}
              placement="bottomRight"
              visible={isPopUpVisible}
              onVisibleChange={() => {
                if (micrositeId !== "") {
                  handleVisibleChange();
                }
              }}
            >
              <Button
                key="4"
                onClick={(event) => {
                  handleVisibleChange(true);
                  setFormUrl(`${window.location.href}/m/${micrositeId}`);
                  setFormId(micrositeId);
                  event.stopPropagation();
                }}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "12px 20px",
                  gap: "10px",
                  height: "40px",
                  borderRadius: "8px",
                  fontFamily: "Hellix",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "19px",
                  color: "#124173",
                  borderColor: "#124173",
                }}
                disabled={micrositeId === ""}
              >
                Form details
              </Button>
            </Popover>,
          ]}
        />
      </div>

      <Divider />

      <Row style={{ marginTop: 10 }} gutter={20}>
        <Col span={16} xl={16} lg={15} md={24} xs={24} sm={24}>
          <DynamicFormBody />
        </Col>

        <Col
          span={8}
          xl={8}
          lg={9}
          md={24}
          xs={24}
          sm={24}
          style={{ background: "#E2EBF3", borderRadius: 7, padding: 0 }}
          className="tabs-container"
        >
          <Tabs
            activeKey={activeKey}
            centered
            className="edit-sidebar"
            onTabClick={(e) => activeTabHandler(e)}
          >
            <TabPane
              tab={
                <span
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LayoutOutlined style={{ marginRight: 0 }} />
                  Layout
                </span>
              }
              key="1"
            >
              <Layout />
            </TabPane>

            <TabPane
              tab={
                <span
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <AppstoreOutlined style={{ marginRight: 0 }} />
                  Component
                </span>
              }
              key="2"
            >
              <Components />
            </TabPane>

            <TabPane
              tab={
                <span
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <SettingOutlined style={{ marginRight: 0 }} />
                  Settings
                </span>
              }
              key="3"
            >
              <Settings popupForm={popupForm} />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </>
  );
}
