import React, { useEffect, useState } from "react";
import { Typography, Row, Col, Divider, Slider, Switch } from "antd";
import { InfoCircleOutlined, CheckOutlined } from "@ant-design/icons";
import right_tickImg from "assets/Images/right_tick.svg";
import Lite from "assets/Images/basic-icon.svg";
import Standard from "assets/Images/plus-icon.svg";
import Enterprise from "assets/Images/premium-icon.svg";
import Premium from "assets/Images/advanced-icon.svg";
import PublicDesktopBilling from "./PublicDesktopBilling";
import PublicResponsiveBilling from "./PublicResponsiveBilling";
//   import Slider from 'rsuite/Slider';
// import 'rsuite/styles/index.less';

const messageCostobject = {
  1000: ["0.20", "0.60"],
  2500: ["0.16", "0.32"],
  5000: ["0.14", "0.28"],
  10000: ["0.13", "0.26"],
  25000: ["0.10", "0.20"],
  50000: ["0.08", "0.16"],
};

function BillingRoute() {
  const [sortData, setSortData] = useState([]);
  const [touchpointObject, setTouchpointObject] = useState([]);
  const [touchpoint, setTouchpoint] = useState({});
  const [switchState, setSwitchState] = useState(false);

  const plansData = [
    {
      id: "f3c55c11-d593-43c9-8e61-28d12ddccee9",
      name: "Lite",
      description: "Lite Plan",
      limits: '{"touchpoints":1000}',
      stripePriceID: [
        "price_1MCewoF2ZqjBhZSfJ9Pz1H3E",
        "price_1MCevhF2ZqjBhZSfAb0S91dP",
      ],
      priceValues:
        '{"price_1MCevhF2ZqjBhZSfAb0S91dP":{"billing_period":"metered","value":"0"},"price_1MCewoF2ZqjBhZSfJ9Pz1H3E":{"billing_period":"month","value":"199"}}',
      listed: true,
      order: null,
      createdAt: "2021-12-02T19:12:33.103Z",
      updatedAt: "2021-12-08T13:37:05.103Z",
    },
    {
      id: "f3c55c11-d593-43c9-8e61-28d12ddcced8",
      name: "Lite",
      description: "Lite Plan",
      limits: '{"touchpoints":5000}',
      stripePriceID: [
        "price_1LBc2MF2ZqjBhZSfRUnUHoWV",
        "price_1LBcARF2ZqjBhZSfSTPT3LAm",
        "price_1LBc9JF2ZqjBhZSfyEdwefSQ",
      ],
      priceValues:
        '{"price_1LBc9JF2ZqjBhZSfyEdwefSQ":{"billing_period":"year","value":"6710"},"price_1LBcARF2ZqjBhZSfSTPT3LAm":{"billing_period":"metered","value":"0"},"price_1LBc2MF2ZqjBhZSfRUnUHoWV":{"billing_period":"month","value":"699"}}',
      listed: true,
      order: null,
      createdAt: "2021-12-02T19:12:33.103Z",
      updatedAt: "2021-12-02T19:12:33.103Z",
    },
    {
      id: "f3c55c11-d593-43c9-8e61-28d12eecced8",
      name: "Lite",
      description: "Lite Plan",
      limits: '{"touchpoints":10000}',
      stripePriceID: [
        "price_1LBc2bF2ZqjBhZSfMIoSv1Nu",
        "price_1LBcC1F2ZqjBhZSfrNCTcty8",
        "price_1LBcDGF2ZqjBhZSf83cf01Jd",
      ],
      priceValues:
        '{"price_1LBc2bF2ZqjBhZSfMIoSv1Nu":{"billing_period":"month","value":"1299"},"price_1LBcDGF2ZqjBhZSf83cf01Jd":{"billing_period":"year","value":"12470"},"price_1LBcC1F2ZqjBhZSfrNCTcty8":{"billing_period":"metered","value":"0"}}',
      listed: true,
      order: null,
      createdAt: "2021-12-02T19:12:33.103Z",
      updatedAt: "2021-12-02T19:12:33.103Z",
    },
    {
      id: "f3c55c11-d593-43c9-8e61-28d12ddccdd8",
      name: "Lite",
      description: "Lite Plan",
      limits: '{"touchpoints":2500}',
      stripePriceID: [
        "price_1LBc2AF2ZqjBhZSfKbkzaLWp",
        "price_1LBc4wF2ZqjBhZSf9jzKmD4n",
        "price_1LBc5uF2ZqjBhZSffJ8P8ldP",
      ],
      priceValues:
        '{"price_1LBc4wF2ZqjBhZSf9jzKmD4n":{"billing_period":"metered","value":"0"},"price_1LBc2AF2ZqjBhZSfKbkzaLWp":{"billing_period":"month","value":"399"},"price_1LBc5uF2ZqjBhZSffJ8P8ldP":{"billing_period":"year","value":"3830"}}',
      listed: true,
      order: null,
      createdAt: "2021-12-02T19:12:33.103Z",
      updatedAt: "2021-12-02T19:12:33.103Z",
    },
    {
      id: "8a526a44-cf27-467c-6391-435f141af7bc",
      name: "Pro",
      description: "Learn more about this plan from our sales team",
      limits: '{"touchpoints":100000}',
      stripePriceID: [
        "price_1LBcQLF2ZqjBhZSfiPpLpqP5",
        "price_1LBcR4F2ZqjBhZSfSwGkbBtG",
        "price_1LBcPwF2ZqjBhZSfZhVoKQvR",
      ],
      priceValues:
        '{"price_1LBcR4F2ZqjBhZSfSwGkbBtG":{"billing_period":"metered","value":"0"},"price_1LBcQLF2ZqjBhZSfiPpLpqP5":{"billing_period":"month","value":"5999"},"price_1LBcPwF2ZqjBhZSfZhVoKQvR":{"billing_period":"year","value":"57590"}}',
      listed: true,
      order: null,
      createdAt: "2021-12-02T19:13:12.746Z",
      updatedAt: "2021-12-02T19:13:12.746Z",
    },
    {
      id: "ba10ffdd-df43-42af-980a-464e99b7d65d",
      name: "Standard",
      description: "Standard Plan",
      limits: '{"touchpoints":50000}',
      stripePriceID: [
        "price_1LBcNaF2ZqjBhZSfyt3mVLFi",
        "price_1LBcMZF2ZqjBhZSfFOYCx0fU",
        "price_1LBcNRF2ZqjBhZSfVWAfnmUm",
      ],
      priceValues:
        '{"price_1LBcNaF2ZqjBhZSfyt3mVLFi":{"billing_period":"month","value":"3999"},"price_1LBcMZF2ZqjBhZSfFOYCx0fU":{"billing_period":"metered","value":"0"},"price_1LBcNRF2ZqjBhZSfVWAfnmUm":{"billing_period":"year","value":"38390"}}',
      listed: true,
      order: null,
      createdAt: "2021-12-02T19:13:12.746Z",
      updatedAt: "2021-12-02T19:13:12.746Z",
    },
    {
      id: "aa10ffdd-df43-42af-980a-464e99b7d65d",
      name: "Standard",
      description: "Standard Plan",
      limits: '{"touchpoints":25000}',
      stripePriceID: [
        "price_1LBcJCF2ZqjBhZSfr59c5mCM",
        "price_1LBcKQF2ZqjBhZSfrBPyhkcl",
        "price_1LBcJPF2ZqjBhZSfZp7v0Ikt",
      ],
      priceValues:
        '{"price_1LBcKQF2ZqjBhZSfrBPyhkcl":{"billing_period":"metered","value":"0"},"price_1LBcJCF2ZqjBhZSfr59c5mCM":{"billing_period":"month","value":"2499"},"price_1LBcJPF2ZqjBhZSfZp7v0Ikt":{"billing_period":"year","value":"23990"}}',
      listed: true,
      order: null,
      createdAt: "2021-12-02T19:13:12.746Z",
      updatedAt: "2021-12-02T19:13:12.746Z",
    },
  ];

  const touchpointMarks = async () => {
    const sliderTickmarks = [2, 10, 19, 28, 40, 60, 85];
    const sliderTickmarksObject = {};
    let data1 = plansData.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    setTouchpointObject([data1[2]]);
    setSortData(data1);
    let mapData = plansData.map((item) => JSON.parse(item?.limits));
    console.log(mapData, 157);
    let sorting = mapData.sort((a, b) => {
      return a.touchpoints - b.touchpoints;
    });
    sorting.forEach(
      (item, index) => {
        sliderTickmarksObject[sliderTickmarks[index]] =
          item?.touchpoints === 100000
            ? `${item?.touchpoints / 1000}k+`
            : `${item?.touchpoints / 1000}k`;
      }
      //  qq[index * 9]  = (item?.touchpoints /1000000) >= 1 ? switchState ? (item?.touchpoints /5000000) >= 1 ? `${(item?.touchpoints /5000000)}M`: `${(item?.touchpoints /5000)}k` : `${(item?.touchpoints /1000000)}M` : switchState ? (item?.touchpoints /5000) >= 1 ? `${(item?.touchpoints /5000)}k`: `${(item?.touchpoints /5)}`:`${(item?.touchpoints /1000)}k`}
    );
    setTouchpoint(sliderTickmarksObject);
  };

  useEffect(() => {
    touchpointMarks();
  }, []); //switchState

  const handleSlider = (value) => {
    let touchPointValue = touchpoint[value];
    console.log(touchPointValue.slice(-1), 442);
    touchPointValue =
      Number(
        touchPointValue.slice(-1) === "+"
          ? touchPointValue.slice(0, touchPointValue.length - 2)
          : touchPointValue.slice(0, touchPointValue.length - 1)
      ) * 1000;
    // (touchPointValue.slice(-1) === "k" ? switchState ? 5000 : 1000 : touchPointValue.slice(-1) === "M" ? switchState ? 5000000 : 1000000 : 50);
    let FilterData = sortData.filter(
      (data) => JSON.parse(data?.limits).touchpoints === touchPointValue
    );
    setTouchpointObject(FilterData);
  };

  const toCommas = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const onChange = (checked) => {
    setSwitchState(checked);
  };
  return (
    <div>
      <div className="desktopBilling">
        <PublicDesktopBilling
          messageCostobject={messageCostobject}
          switchState={switchState}
          touchpoint={touchpoint}
          touchpointObject={touchpointObject}
          handleSlider={handleSlider}
          onChange={onChange}
          toCommas={toCommas}
        />
      </div>
      <div className="responsiveBilling">
        <PublicResponsiveBilling
          messageCostobject={messageCostobject}
          switchState={switchState}
          onChange={onChange}
          toCommas={toCommas}
        />
      </div>
    </div>
  );
}

export default BillingRoute;
