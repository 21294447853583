import React, { Component } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Typography,
  Checkbox,
} from "antd";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import backgroundImg from "assets/Images/login_signup_background.svg";
import { emailPattern } from "utility/Constants";
import logoWhite from "assets/Images/logo-white.svg";
import pdf from "../../../assets/pdf/Terms&Condition.pdf";
import moment from "moment";

import {
  MailOutlined,
  LockOutlined,
  UserOutlined,
  CopyrightOutlined,
} from "@ant-design/icons";

export class CreateAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      error: "",
      checked: false,
      loading: false,
    };
  }

  handleSignUp = (e) => {
    if (e.firstName.length <= 1 || e.lastName.length <= 1) {
      this.setState({
        error: "Please enter valid name",
      });
    } else {
      this.setState(
        {
          loading: true,
          error: "",
        },
        async () => {
          try {
            await Auth.signUp({
              username: e.email,
              password: e.password,
              attributes: {
                email: e.email,
                given_name: e.firstName,
                family_name: e.lastName,
              },
            });
            this.setState(
              {
                loading: false,
              },
              () => {
                // Go to ConfirmationAccount Screen
                this.props.history.push({
                  pathname: `/confirm_sign_up`,
                  state: { feedback: "", userName: e.email },
                });
                localStorage.setItem("User", window.btoa(e.email));
              }
            );
          } catch (error) {
            console.log("error signing up:", error);
            this.setState({
              error: error.message,
              loading: false,
            });
          }
        }
      );
    }
  };

  handleOpen = (th) => {
    window.open(th.name, "_blank");
  };

  render() {
    const { error, loading } = this.state;
    const { history } = this.props;
    return (
      <Row justify="center" align="middle">
        <Col xs={0} md={0} lg={7} xl={6} className="sidebar-background">
          <Col
            className="info"
            style={{ position: "absolute", top: 0, left: 0 }}
          >
            <div className="info-logo">
              <a href="/signin">
                <img src={logoWhite} alt="" />
              </a>
            </div>
            <div className="info-text">
              <Typography.Title className="login-heading" level={1}>
                Welcome to Blustream!
              </Typography.Title>
              <Typography.Text className="login-heading-text">
                If you already have an account, please login to continue using
                Blustream.{" "}
              </Typography.Text>
              <Button
                type="primary"
                size="large"
                tabIndex={5}
                className="btn-secondary"
                style={{
                  marginTop: 44,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "12px 20px",
                  borderRadius: "8px",
                  lineHeight: "16px",
                }}
                onClick={() => history.push("/login")}
              >
                <span style={{ fontWeight: "bold" }}>Login</span>
              </Button>
            </div>
          </Col>
        </Col>
        <Col
          xs={24}
          md={24}
          lg={17}
          xl={18}
          style={{ transform: "scale(0.88)" }}
        >
          <Row
            justify="center"
            style={{
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-evenly",
              minHeight: 580,
            }}
          >
            <Card
              className="signup-form-container"
              style={{ border: "none", width: "437px", padding: "0px" }}
            >
              <div>
                <Typography.Title
                  level={3}
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    paddingBottom: 34,
                  }}
                >
                  Create Account!{" "}
                </Typography.Title>
              </div>

              {error && (
                <Alert
                  style={{ marginBottom: "10px" }}
                  type="error"
                  showIcon={false}
                  message={error}
                  banner
                />
              )}
              <Form
                labelCol={{ xs: 24 }}
                requiredMark={false}
                layout="vertical"
                name="normal_signup"
                className="signup-form"
                onFinish={(e) => this.handleSignUp(e)}
              >
                <Form.Item
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your First Name!",
                    },
                    {
                      pattern: /^[a-zA-Z]+/,
                      message: "symbol, number or whitespace are not allowed",
                    },
                  ]}
                >
                  <Input
                    className="standard-input"
                    id="firstName"
                    key="firstName"
                    name="firstName"
                    placeholder="First Name"
                    disabled={loading}
                    tabIndex={1}
                    size="large"
                    prefix={<UserOutlined />}
                  />
                </Form.Item>

                <Form.Item
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Last Name!",
                    },
                    {
                      pattern: /^[a-zA-Z-]+/,
                      message: "symbol, number or whitespace are not allowed",
                    },
                  ]}
                >
                  <Input
                    className="standard-input"
                    id="lastName"
                    key="lastName"
                    name="lastName"
                    placeholder="Last Name"
                    disabled={loading}
                    tabIndex={2}
                    size="large"
                    prefix={<UserOutlined />}
                  />
                </Form.Item>

                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: "Please input your Email!" },
                    {
                      pattern: emailPattern,
                      message: "Please provide valid Email!",
                    },
                  ]}
                >
                  <Input
                    className="standard-input"
                    id="email"
                    key="email"
                    name="email"
                    placeholder="Email Address"
                    disabled={loading}
                    tabIndex={3}
                    type="email"
                    size="large"
                    prefix={<MailOutlined />}
                  />
                </Form.Item>

                <Form.Item
                  name="password"
                  rules={[
                    { required: true, message: "Please input your Password!" },
                  ]}
                >
                  <Input.Password
                    className="standard-input"
                    id="password"
                    key="password"
                    name="password"
                    type="password"
                    placeholder="Create Password"
                    disabled={loading}
                    tabIndex={4}
                    size="large"
                    prefix={<LockOutlined />}
                  />
                </Form.Item>

                <Form.Item
                  name="Terms&Conditions"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(
                              new Error("Please agree with terms & conditions!")
                            ),
                    },
                  ]}
                  style={{ textAlign: "center" }}
                >
                  <Checkbox>
                    <span style={{ color: "#959595" }}>
                      By signing up you agree with{" "}
                    </span>{" "}
                    <a style={{ color: "black" }} rel="noreferrer" href={pdf}>
                      Terms & Conditions
                    </a>
                  </Checkbox>
                </Form.Item>

                <Form.Item>
                  <Row justify="space-between" align="middle">
                    <Button
                      type="primary"
                      size="large"
                      htmlType="submit"
                      block
                      loading={loading}
                      tabIndex={5}
                      className="standard-button primary-orange-button"
                    >
                      <span style={{ fontWeight: "bold" }}>Sign Up</span>
                    </Button>
                    <div className="mobile-login-text">
                      <h3
                        style={{
                          fontSize: 16,
                          textAlign: "center",
                          paddingBottom: 30,
                          cursor: "pointer",
                        }}
                      >
                        Already have an Account ?
                        <Link to="/login" style={{ color: "#F5786D" }}>
                          {" "}
                          LOGIN
                        </Link>
                      </h3>
                    </div>
                  </Row>
                </Form.Item>
              </Form>
              {/* for future ref. login with google btn */}
              {/* <Divider plain="true" style={{
                margin: "12px 0px ", color: "#A9A9A9"
              }}>or</Divider>

              <Button type="primary" block style={{ color: "white", backgroundColor: "#303030" }} > <img src={googleImg} alt="Google" style={{ height: "20px", paddingRight: "10px" }} /> Continue with Google</Button> */}
            </Card>
            <span
              style={{
                color: "#646C79",
                fontSize: "16px",
                textAlign: "center",
                fontWeight: "500",
                userSelect: "none",
                marginBottom: "0px",
                position: "fixed",
                bottom: 0,
              }}
            >
              <div>
                <div>
                  Version{" "}
                  {process.env.REACT_APP_VERSION
                    ? process.env.REACT_APP_VERSION
                    : "0.1.0"}
                </div>
                <CopyrightOutlined />
                {`${moment().year()}. Blustream.io`}
              </div>
            </span>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default CreateAccount;
