import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Typography,
  Card,
  Radio,
  Space,
  Button,
  Divider,
  Tooltip,
} from "antd";
import { CheckOutlined } from "@ant-design/icons";
import blueArrowCircle from "./../../assets/Images/blue-arrow-circle.svg";
import orangeArrowCircle from "./../../assets/Images/orange-arrow-circle.svg";
import infoIcon from "./../../assets/Images/info-icon.svg";
import Lite from "assets/Images/basic-icon.svg";
import Standard from "assets/Images/plus-icon.svg";
import Enterprise from "assets/Images/premium-icon.svg";
import stars_Image from "./../../assets/Images/3-star.svg";
import Premium from "assets/Images/advanced-icon.svg";
import freeplanIndicationIcon from "assets/Images/freeplanIndicationIcon.svg";
import noPlans from "assets/Images/noPlans.svg";
import noStatistics from "assets/Images/noStatistics.svg";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

function ResponsiveBilling({
  list,
  messageCostobject,
  switchState,
  accountInfo,
  loader,
  selectedIndex,
  planDetails,
  accountDetails,
  billingList,
  toCommas,
  handlePlan,
  handleHere,
  cancelPlan,
  ShopifyprofileData,
}) {
  const { roAdmin, groups } = useSelector((state) => state.loginReducer);
  const history = useHistory();
  const [planValue, setPlanValue] = useState();

  useEffect(() => {
    list.sort((a, b) => {
      return (
        Number(JSON.parse(a.limits)?.touchpoints) -
        Number(JSON.parse(b.limits)?.touchpoints)
      );
    });
  }, [list]);

  useEffect(() => {
    if (Object.keys(accountInfo).length > 0) {
      let planInfo = list.find(
        (item) =>
          JSON.parse(item?.priceValues)?.[
            item?.stripePriceID[0]
          ]?.value.toString() === accountInfo?.planID
      );
      if (planInfo) {
        setPlanValue(JSON.parse(planInfo?.limits).touchpoints);
      }
    }
  }, [accountInfo]);

  return (
    <Row>
      <Col span={24} sm={24} lg={24} md={24} xl={24}>
        <div
          style={{
            background: "white",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "0px 10px",
          }}
        >
          <div
            style={{
              paddingTop: "30px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h2
              style={{
                fontSize: "24px",
                fontWeight: "700",
                color: "#252525",
                margin: "0px",
              }}
            >
              Choose Your Plan
            </h2>
            {accountDetails && accountDetails?.planID === "0" && (
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <img
                  src={freeplanIndicationIcon}
                  alt="freeplanIndicationIcon"
                />
                <Typography.Text
                  style={{
                    color: "#3B84D1",
                    fontSize: "16px",
                    fontWeight: "600",
                    fontStyle: "italic",
                  }}
                >
                  You are using a free plan ($0 , 250 msgs)
                </Typography.Text>

                <Button
                  className="standard-button secondary-orange-button"
                  disabled={roAdmin}
                  onClick={() => handleHere(accountDetails?.id)}
                >
                  Manage Billing Details
                </Button>
              </div>
            )}
          </div>
          {/* dev :- needed in future */}
          {/* <div style={{display:"flex",alignItems:"center",justifyContent:"space-between",width:"100%",background:"#EFF4F9",padding:"16px",borderRadius:'7px',marginTop:"28px"}}>
                <div style={{display:"flex",alignItems:"center"}}>
                <Typography.Text  className={`switch-text ${switchState ? "":"switchToggle"}`}>Messages</Typography.Text>
                <Switch value={switchState} onChange={onChange} style={{marginLeft: 7, background: '#124173', fontSize: 16}}/>
                <Typography.Text style={{marginLeft: 7}} className={`switch-text ${switchState ?'switchToggle':""}`}>Customer</Typography.Text>
                </div>
                <div>
                <Tooltip placement="left" title="Switch to see customer reach for the message range selected">
                <InfoCircleOutlined style={{cursor: 'pointer',marginLeft: 7, color: '#252525', fontSize: "17px"}}/>
                </Tooltip>
                </div>
              </div> */}
          <div
            style={{
              marginTop: "18px",
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Card
              className="card-header"
              title="Lite"
              bordered={false}
              style={{
                width: "100%",
              }}
            >
              <Typography.Text
                style={{
                  fontSize: "15px",
                  fontWeight: "500",
                  color: "#252525",
                }}
              >
                Great for trials and companies with 1-1,000 customers
              </Typography.Text>
              <br />
              <Typography.Text
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#7692AE",
                  marginBottom: "14px",
                }}
              >
                Choose number of {switchState ? "customers" : "messages"}
              </Typography.Text>
              <br />
              <Radio.Group
                onChange={(e) => {
                  setPlanValue(e.target.value);
                }}
                value={planValue}
              >
                <Space direction="vertical" style={{ gap: "10px" }}>
                  {/* if admin they have one extra free plan */}
                  {list.slice(0, list.length === 7 ? 4 : 5).map((plan) => {
                    const priceValues = plan && JSON.parse(plan?.priceValues);
                    const limit = plan && JSON.parse(plan?.limits);
                    return (
                      <Radio value={limit.touchpoints}>
                        <span className="radio-labels">
                          {switchState
                            ? limit.touchpoints / 5
                            : limit.touchpoints}
                        </span>
                        {planValue === limit.touchpoints && (
                          <div className="plan-details">
                            <span style={{ marginBottom: "12px" }}>
                              <span
                                style={{
                                  fontSize: "28px",
                                  fontWeight: "600",
                                  lineHeight: "34px",
                                }}
                              >
                                $
                                {priceValues &&
                                  toCommas(
                                    priceValues?.[plan?.stripePriceID[0]]?.value
                                  )}
                              </span>
                              <span>
                                /
                                {priceValues &&
                                  priceValues?.[plan?.stripePriceID[0]]
                                    ?.billing_period}
                              </span>
                            </span>
                            <span
                              style={{ marginBottom: "10px", fontSize: 16 }}
                            >
                              <CheckOutlined
                                style={{ color: "green", fontSize: 16 }}
                              />{" "}
                              ${messageCostobject[limit.touchpoints][0]}{" "}
                              Associated cost/message
                            </span>
                            <span
                              style={{ marginBottom: "20px", fontSize: 16 }}
                            >
                              <CheckOutlined
                                style={{ color: "green", fontSize: 16 }}
                              />{" "}
                              ${messageCostobject[limit.touchpoints][1]} Overage
                              rates/message
                            </span>
                            <Tooltip
                              title={
                                !groups?.includes("admin") &&
                                (accountDetails?.source !== "shopify" ||
                                  ShopifyprofileData?.length === 0)
                                  ? parseInt(accountInfo?.planID || 0) <
                                    parseInt(
                                      priceValues?.[plan?.stripePriceID[0]]
                                        ?.value
                                    )
                                    ? ""
                                    : "Connect blustream support to select this plan"
                                  : ""
                              }
                            >
                              <Button
                                disabled={
                                  roAdmin ||
                                  parseInt(accountInfo?.planID) ===
                                    parseInt(
                                      priceValues?.[plan?.stripePriceID[0]]
                                        ?.value
                                    ) ||
                                  (!groups?.includes("admin") &&
                                    ShopifyprofileData?.length === 0 &&
                                    parseInt(accountInfo?.planID || 0) >=
                                      parseInt(
                                        priceValues?.[plan?.stripePriceID[0]]
                                          ?.value
                                      )) ||
                                  ((accountDetails?.source !== "shopify" ||
                                    parseInt(accountInfo?.planID || 0) ===
                                      parseInt(
                                        priceValues?.[plan?.stripePriceID[0]]
                                          ?.value
                                      )) &&
                                    accountInfo?.planID &&
                                    parseInt(accountInfo?.planID || 0) >=
                                      parseInt(
                                        priceValues?.[plan?.stripePriceID[0]]
                                          ?.value
                                      ))
                                }
                                className="primary-orange-button"
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  padding: "12px 20px",
                                  gap: "10px",
                                  height: "40px",
                                  borderRadius: "8px",
                                  fontFamily: "Hellix",
                                  fontStyle: "normal",
                                  fontWeight: "600",
                                  fontSize: "16px",
                                  lineHeight: "19px",
                                  borderColor: "#f37064",
                                  background: "#f37064",
                                  color: "#ffffff",
                                  width: "161px",
                                }}
                                block
                                size="large"
                                loading={loader && selectedIndex === 0}
                                onClick={() => handlePlan(plan, 0)}
                              >
                                Select Plan
                              </Button>
                            </Tooltip>
                            {!groups?.includes("admin") &&
                            ((ShopifyprofileData?.length === 0 &&
                              parseInt(accountInfo?.planID || 0) >=
                                parseInt(
                                  priceValues?.[plan?.stripePriceID[0]]?.value
                                )) ||
                              (accountDetails?.source !== "shopify" &&
                                (roAdmin ||
                                  (accountInfo?.planID &&
                                    parseInt(accountInfo?.planID || 0) >=
                                      parseInt(
                                        priceValues?.[plan?.stripePriceID[0]]
                                          ?.value
                                      ))))) ? (
                              <p className="contact-sales-text">
                                Please reach out to Blustream support team
                              </p>
                            ) : null}
                          </div>
                        )}
                      </Radio>
                    );
                  })}
                </Space>
              </Radio.Group>
            </Card>
          </div>
          <div
            style={{
              marginTop: "18px",
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Card
              className="card-header"
              title="Standard"
              bordered={false}
              style={{
                width: "100%",
              }}
            >
              <Typography.Text
                style={{
                  fontSize: "15px",
                  fontWeight: "500",
                  color: "#252525",
                }}
              >
                Great for small & medium businesses looking to grow with 1,000 -
                25,000 customers
              </Typography.Text>
              <br />
              <Typography.Text
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#7692AE",
                  marginBottom: "14px",
                }}
              >
                Choose number of {switchState ? "customers" : "messages"}
              </Typography.Text>
              <br />
              <Radio.Group
                onChange={(e) => {
                  setPlanValue(e.target.value);
                }}
                value={planValue}
              >
                <Space direction="vertical" style={{ gap: "10px" }}>
                  {list
                    .slice(list.length === 7 ? 4 : 5, list.length === 7 ? 6 : 7)
                    .map((plan) => {
                      const priceValues = plan && JSON.parse(plan?.priceValues);
                      const limit = plan && JSON.parse(plan?.limits);
                      return (
                        <Radio value={limit.touchpoints}>
                          <span className="radio-labels">
                            {switchState
                              ? `${limit.touchpoints / 5}`
                              : limit.touchpoints}
                          </span>
                          {planValue === limit.touchpoints && (
                            <div className="plan-details">
                              <span style={{ marginBottom: "12px" }}>
                                <span
                                  style={{
                                    fontSize: "28px",
                                    fontWeight: "600",
                                    lineHeight: "34px",
                                  }}
                                >
                                  $
                                  {priceValues &&
                                    toCommas(
                                      priceValues?.[plan?.stripePriceID[0]]
                                        ?.value
                                    )}
                                </span>
                                <span>
                                  /
                                  {priceValues &&
                                    priceValues?.[plan?.stripePriceID[0]]
                                      ?.billing_period}
                                </span>
                              </span>
                              <span
                                style={{ marginBottom: "10px", fontSize: 16 }}
                              >
                                <CheckOutlined
                                  style={{ color: "green", fontSize: 16 }}
                                />{" "}
                                ${messageCostobject[limit.touchpoints][0]}{" "}
                                Associated cost/message
                              </span>
                              <span
                                style={{ marginBottom: "20px", fontSize: 16 }}
                              >
                                <CheckOutlined
                                  style={{ color: "green", fontSize: 16 }}
                                />{" "}
                                ${messageCostobject[limit.touchpoints][1]}{" "}
                                Overage rates/message
                              </span>
                              <Tooltip
                                title={
                                  !groups?.includes("admin") &&
                                  (accountDetails?.source !== "shopify" ||
                                    ShopifyprofileData?.length === 0)
                                    ? parseInt(accountInfo?.planID || 0) <
                                      parseInt(
                                        priceValues?.[plan?.stripePriceID[0]]
                                          ?.value
                                      )
                                      ? ""
                                      : "Connect blustream support to select this plan"
                                    : ""
                                }
                              >
                                <Button
                                  disabled={
                                    roAdmin ||
                                    parseInt(accountInfo?.planID) ===
                                      parseInt(
                                        priceValues?.[plan?.stripePriceID[0]]
                                          ?.value
                                      ) ||
                                    (!groups?.includes("admin") &&
                                      ShopifyprofileData?.length === 0 &&
                                      parseInt(accountInfo?.planID || 0) >=
                                        parseInt(
                                          priceValues?.[plan?.stripePriceID[0]]
                                            ?.value
                                        )) ||
                                    ((accountDetails?.source !== "shopify" ||
                                      parseInt(accountInfo?.planID || 0) ===
                                        parseInt(
                                          priceValues?.[plan?.stripePriceID[0]]
                                            ?.value
                                        )) &&
                                      accountInfo?.planID &&
                                      parseInt(accountInfo?.planID || 0) >=
                                        parseInt(
                                          priceValues?.[plan?.stripePriceID[0]]
                                            ?.value
                                        ))
                                  }
                                  className="primary-orange-button"
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "12px 20px",
                                    gap: "10px",
                                    height: "40px",
                                    borderRadius: "8px",
                                    fontFamily: "Hellix",
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    fontSize: "16px",
                                    lineHeight: "19px",
                                    borderColor: "#f37064",
                                    background: "#f37064",
                                    color: "#ffffff",
                                    width: "161px",
                                  }}
                                  block
                                  size="large"
                                  loading={loader && selectedIndex === 0}
                                  onClick={() => handlePlan(plan, 0)}
                                >
                                  Select Plan
                                </Button>
                              </Tooltip>
                              {!groups?.includes("admin") &&
                              ((ShopifyprofileData?.length === 0 &&
                                parseInt(accountInfo?.planID || 0) >=
                                  parseInt(
                                    priceValues?.[plan?.stripePriceID[0]]?.value
                                  )) ||
                                (accountDetails?.source !== "shopify" &&
                                  (roAdmin ||
                                    (accountInfo?.planID &&
                                      parseInt(accountInfo?.planID || 0) >=
                                        parseInt(
                                          priceValues?.[plan?.stripePriceID[0]]
                                            ?.value
                                        ))))) ? (
                                <p className="contact-sales-text">
                                  Please reach out to Blustream support team
                                </p>
                              ) : null}
                            </div>
                          )}
                        </Radio>
                      );
                    })}
                </Space>
              </Radio.Group>
            </Card>
          </div>
          <div
            style={{
              marginTop: "18px",
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Card
              className="card-header"
              title="Pro"
              bordered={false}
              style={{
                width: "100%",
              }}
            >
              <Typography.Text
                style={{
                  fontSize: "15px",
                  fontWeight: "500",
                  color: "#252525",
                }}
              >
                Great for medium to larger businesses looking to scale widely
                with 25,000+ customers
              </Typography.Text>
              <br />
              <Typography.Text
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#7692AE",
                  marginBottom: "14px",
                }}
              >
                Choose number of {switchState ? "customers" : "messages"}
              </Typography.Text>
              <br />
              <Radio.Group
                onChange={(e) => {
                  setPlanValue(e.target.value);
                }}
                value={planValue}
              >
                <Space direction="vertical" style={{ gap: "10px" }}>
                  {list.slice(list.length === 7 ? 6 : 7).map((plan) => {
                    const priceValues = plan && JSON.parse(plan?.priceValues);
                    const limit = plan && JSON.parse(plan?.limits);
                    return (
                      <Radio value={limit.touchpoints}>
                        <span className="radio-labels">
                          {switchState
                            ? `${limit.touchpoints / 5}`
                            : limit.touchpoints}
                          +
                        </span>
                        {planValue === limit.touchpoints && (
                          <div className="plan-details">
                            <span
                              className="pro-plan-text"
                              style={{ marginTop: "0px", marginBottom: "10px" }}
                            >
                              {plan?.description}
                            </span>

                            <Tooltip
                              title={
                                parseInt(accountInfo?.planID || 0) <
                                parseInt(
                                  priceValues?.[plan?.stripePriceID[0]]?.value
                                )
                                  ? ""
                                  : "Connect blustream support to select this plan"
                              }
                            >
                              <Button
                                disabled={
                                  roAdmin ||
                                  (accountInfo?.planID &&
                                    parseInt(accountInfo?.planID || 0) >=
                                      parseInt(
                                        priceValues?.[plan?.stripePriceID[0]]
                                          ?.value
                                      ))
                                }
                                className="primary-orange-button"
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  padding: "12px 20px",
                                  gap: "10px",
                                  height: "40px",
                                  borderRadius: "8px",
                                  fontFamily: "Hellix",
                                  fontStyle: "normal",
                                  fontWeight: "600",
                                  fontSize: "16px",
                                  lineHeight: "19px",
                                  borderColor: "#f37064",
                                  background: "#f37064",
                                  color: "#ffffff",
                                }}
                                block
                                size="large"
                                loading={loader && selectedIndex === 0}
                                onClick={() => history.push("/support")}
                              >
                                Contact Sales
                              </Button>
                            </Tooltip>
                          </div>
                        )}
                      </Radio>
                    );
                  })}
                </Space>
              </Radio.Group>
            </Card>
          </div>
          {(accountInfo?.accountStatus === "ACTIVE" ||
            accountInfo?.accountStatus === "LATE_START") &&
          planDetails ? (
            <div
              style={{
                marginTop: "18px",
                padding: "14px",
                background: "#EFF4F9",
                width: "100%",
                borderRadius: "7px",
                marginBottom: "20px",
              }}
            >
              <div>
                <Typography.Title
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    lineHeight: "18px",
                    textTransform: "capitalize",
                    color: "#124173",
                    marginBottom: "20px",
                  }}
                >
                  My Plan
                </Typography.Title>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <img
                    style={{ height: "36px", width: "36px" }}
                    src={
                      planDetails?.name === "Lite"
                        ? Lite
                        : planDetails?.name === "Enterprise"
                        ? Enterprise
                        : planDetails?.name === "Standard"
                        ? Standard
                        : Premium
                    }
                    alt=""
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "10px",
                    lineHeight: "22px",
                  }}
                >
                  <span
                    style={{
                      fontSize: "22px",
                      color: "#252525",
                      fontWeight: "700",
                      marginBottom: "7px",
                    }}
                  >
                    {planDetails?.name}
                  </span>
                  <span
                    style={{
                      fontSize: "18px",
                      color: "#252525",
                      marginBottom: "7px",
                    }}
                  >
                    $
                    {JSON.parse(planDetails?.priceValues)
                      ?.[planDetails?.stripePriceID[0]]?.value.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    /
                    {
                      JSON.parse(planDetails?.priceValues)?.[
                        planDetails?.stripePriceID[0]
                      ]?.billing_period
                    }
                  </span>
                  <span
                    style={{
                      fontSize: "18px",
                      color: "#69829B",
                      marginBottom: "12px",
                    }}
                  >
                    Up to{" "}
                    {JSON.parse(planDetails?.limits)
                      ?.touchpoints.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                    Product Messages
                  </span>
                  <div className="manage-billing-button-container">
                    {accountDetails?.shopifyPlanID?.length > 0 ? (
                      <Button
                        disabled={roAdmin}
                        className="standard-button secondary-orange-button"
                        onClick={() => cancelPlan()}
                      >
                        Cancel Plan
                      </Button>
                    ) : (
                      <Button
                        disabled={roAdmin}
                        className="standard-button secondary-orange-button"
                        onClick={() => handleHere(accountDetails?.id)}
                      >
                        Manage Billing Details
                      </Button>
                    )}
                  </div>
                </div>
              </div>
              <Divider
                style={{
                  display: "block",
                  height: "2px",
                  background: "#CDDFEF",
                  margin: "30px 0px",
                }}
              />
              <div>
                <Typography.Title
                  style={{
                    fontSize: 18,
                    fontWeight: 600,
                    color: "#124173",
                    textTransform: "capitalize",
                    marginBottom: "20px",
                  }}
                >
                  My current statistics{" "}
                </Typography.Title>
              </div>
              <div
                className="statistics-box"
                style={{
                  display: "flex",
                  marginBottom: "32px",
                  justifyContent: "space-evenly",
                }}
              >
                <div className="last-billing" style={{ marginRight: "26px" }}>
                  <Typography.Title
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#F37064",
                      lineHeight: "21px",
                      textTransform: "capitalize",
                      marginBottom: "13px",
                    }}
                  >
                    Last billing period
                  </Typography.Title>
                  {billingList?.period?.start && billingList?.period?.end ? (
                    <span
                      style={{ display: "inline-block", marginBottom: "10px" }}
                    >
                      {`${new Date(
                        billingList?.period?.start
                      ).getDate()}/${new Date(
                        billingList?.period?.start
                      ).getMonth()}/${new Date(billingList?.period?.start)
                        .getFullYear()
                        .toString()
                        .slice(-2)}`}{" "}
                      to{" "}
                      {`${new Date(
                        billingList?.period?.end
                      ).getDate()}/${new Date(
                        billingList?.period?.end
                      ).getMonth()}/${new Date(billingList?.period?.end)
                        .getFullYear()
                        .toString()
                        .slice(-2)}`}
                    </span>
                  ) : (
                    <span
                      style={{ display: "inline-block", marginBottom: "10px" }}
                    >
                      Not Available
                    </span>
                  )}{" "}
                  {/* needed*/}
                  <div style={{ display: "flex" }}>
                    <div>
                      <img
                        style={{ height: "30px", width: "30px" }}
                        src={orangeArrowCircle}
                        alt=""
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "10px",
                        lineHeight: "22px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "24px",
                          color: "#252525",
                          fontWeight: "700",
                          marginBottom: "6px",
                        }}
                      >
                        {billingList[1]?.total_usage
                          ? billingList[1]?.total_usage
                          : 0}
                      </span>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span
                          style={{
                            fontSize: "18px",
                            fontWeight: "400",
                            color: "#252525",
                          }}
                        >
                          Messages
                        </span>
                        <Tooltip
                          placement="bottom"
                          title="Messages sent or received by Blustream platform"
                          style={{ fontWeight: 600 }}
                        >
                          <img
                            src={infoIcon}
                            style={{ marginLeft: "8px", height: "13px" }}
                            alt=""
                          />
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="current-billing">
                  <Typography.Title
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#124173",
                      lineHeight: "21px",
                      textTransform: "capitalize",
                      marginBottom: "13px",
                    }}
                  >
                    This billing period
                  </Typography.Title>
                  {billingList?.period?.start ? (
                    <span
                      style={{ display: "inline-block", marginBottom: "10px" }}
                    >
                      since{" "}
                      {`${new Date(
                        billingList?.period?.start
                      ).getDate()}/${new Date(
                        billingList?.period?.start
                      ).getMonth()}/${new Date(billingList?.period?.start)
                        .getFullYear()
                        .toString()
                        .slice(-2)}`}
                    </span>
                  ) : (
                    <span
                      style={{ display: "inline-block", marginBottom: "10px" }}
                    >
                      Not Available
                    </span>
                  )}
                  <div style={{ display: "flex" }}>
                    <div>
                      <img
                        style={{ height: "30px", width: "30px" }}
                        src={blueArrowCircle}
                        alt=""
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "10px",
                        lineHeight: "22px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "24px",
                          color: "#252525",
                          fontWeight: "700",
                          marginBottom: "6px",
                        }}
                      >
                        {billingList[0]?.total_usage
                          ? billingList[0]?.total_usage
                          : 0}
                      </span>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span
                          style={{
                            fontSize: "18px",
                            fontWeight: "400",
                            color: "#252525",
                          }}
                        >
                          Messages
                        </span>
                        <Tooltip
                          placement="bottom"
                          title="Messages sent or received by Blustream platform"
                          style={{ fontWeight: 600 }}
                        >
                          <img
                            src={infoIcon}
                            style={{ marginLeft: "8px", height: "13px" }}
                            alt=""
                          />
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "30px",
                }}
              >
                {accountDetails?.source !== "shopify" && (
                  <Button
                    className="standard-button secondary-orange-button"
                    onClick={() => history.push("/")}
                  >
                    View more usage data
                  </Button>
                )}
              </div>
              <div
                style={{
                  marginTop: "22px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                {billingList[1]?.overagesSms && (
                  <div className="overage-text">
                    {`${
                      billingList[1]?.overagesSms
                        ? billingList[1]?.overagesSms
                        : ""
                    }300 Overraged messages last month!`}
                  </div>
                )}
                {billingList[0]?.total_usage >
                  (billingList[1]?.total_usage
                    ? billingList[1]?.total_usage
                    : 0) && (
                  <div className="greeting-text-container">
                    <img src={stars_Image} alt="" height="20px" width="20px" />
                    <span className="greeting-text">
                      Congrats on your growth! Save money by upgrading your plan
                    </span>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div
              style={{
                marginTop: "18px",
                padding: "14px",
                background: "#EFF4F9",
                width: "100%",
                borderRadius: "7px",
                marginBottom: "20px",
              }}
            >
              <div>
                <Typography.Title
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    lineHeight: "18px",
                    textTransform: "capitalize",
                    color: "#124173",
                    marginBottom: "20px",
                  }}
                >
                  My Plan
                </Typography.Title>
              </div>
              <div className="billing-no-plan-container">
                <img src={noPlans} alt="" />
                <p className="billing-no-plan-text">No plan selected!</p>
              </div>
              <Divider
                style={{
                  display: "block",
                  height: "2px",
                  background: "#CDDFEF",
                  margin: "30px 0px",
                }}
              />
              <div>
                <Typography.Title
                  style={{
                    fontSize: 18,
                    fontWeight: 600,
                    color: "#124173",
                    textTransform: "capitalize",
                    marginBottom: "20px",
                  }}
                >
                  My current statistics{" "}
                </Typography.Title>
              </div>
              <div
                className="statistics-box"
                style={{
                  display: "flex",
                  marginBottom: "32px",
                  justifyContent: "space-evenly",
                }}
              >
                <div className="billing-no-statistics-container">
                  <img src={noStatistics} alt="" />
                  <p className="billing-no-statistics-text">
                    No statistics found!
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </Col>
    </Row>
  );
}

export default ResponsiveBilling;
