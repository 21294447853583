import { React, useState } from "react";
import {
  Col,
  Row,
  Form,
  Input,
  Button,
  Checkbox,
  Radio,
  Select,
  Tooltip,
  TreeSelect,
  Tabs,
} from "antd";
import {
  InfoCircleOutlined,
  LaptopOutlined,
  MobileOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import "./MicrositeUserForm.css";
import { emailPattern } from "utility/Constants";
import { invertColor } from "../../utility/LightenDarkenColor";
import marked from "marked";
import useCustomBreakpoints from "utility/useCustomBreakPoints";
import { handleImagesOnLanguageChange } from "commons/DropDownLang/HandleImageRender";

const MicrositeUserForm = () => {
  const { micrositeDefaultStyle, micrositeFormElements, templateName } =
    useSelector((state) => state.SettingReducer);

  const { Option } = Select;
  const { SHOW_PARENT } = TreeSelect;
  const { TabPane } = Tabs;

  const [multiSelectValues, setmultiSelectValues] = useState([]);
  const screens = useCustomBreakpoints();

  // this is for the multiple select dropdown
  const onChange = (newValue) => {
    setmultiSelectValues(newValue);
  };

  const dropdownObj = micrositeFormElements.find((elementObj) => {
    return elementObj?.type === "multiselect-dropdown";
  });

  const tProps = {
    treeData: dropdownObj?.options,
    value: multiSelectValues,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: dropdownObj?.placeholder,
    showSearch: true,
    style: {
      width: "100%",
    },
  };

  return (
    <>
      <Row justify="center" style={{ height: "100vh", alignItems: "center" }}>
        <Col span={24}>
          <Tabs defaultActiveKey="1" centered className="preview-tabs">
            <TabPane
              tab={
                <LaptopOutlined
                  style={{
                    fontSize: "18px",
                    width: 40,
                    justifyContent: "center",
                  }}
                />
              }
              key="1"
            >
              <div className="form-body" style={{ padding: 0 }}>
                {templateName === "TWO_COLUMN_LEFT_FORM" ||
                templateName === "TWO_COLUMN_RIGHT_FORM" ? (
                  <Row>
                    <Col
                      span={14}
                      lg={14}
                      md={14}
                      sm={24}
                      xs={24}
                      style={{
                        position: "relative",
                        backgroundColor:
                          templateName === "TWO_COLUMN_LEFT_FORM"
                            ? micrositeDefaultStyle?.themeStyle?.backgroundColor
                            : micrositeDefaultStyle?.themeStyle
                                ?.secondaryBackgroundColor,
                        backgroundImage: `url("${micrositeDefaultStyle?.themeStyle?.backgroundImageUrl}")`,
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: `${micrositeDefaultStyle?.themeStyle?.backgroundWidth}% ${micrositeDefaultStyle?.themeStyle?.backgroundHeight}%`,
                      }}
                      order={
                        screens.xs || screens.sm || screens.md
                          ? 2
                          : templateName === "TWO_COLUMN_LEFT_FORM"
                          ? 1
                          : 2
                      }
                    >
                      <Row
                        style={
                          templateName === "TWO_COLUMN_LEFT_FORM"
                            ? {
                                minHeight: "100vh",
                                display: "flex",
                                justifyContent: "end",
                                alignItems: "center",
                                padding: "20px 50px 50px 0",
                              }
                            : {
                                minHeight: "100vh",
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                                padding: "20px 0px 50px 50px",
                              }
                        }
                        className="form-row-container"
                      >
                        <Col span={15} xs={20} md={20} lg={18} xxl={13}>
                          <Form layout="vertical" className="dynamic-form">
                            <Row gutter={[10, 10]}>
                              {micrositeFormElements?.map(
                                (currentFormElement) => {
                                  return (
                                    <>
                                      {currentFormElement?.type === "image" && (
                                        <Col
                                          id={currentFormElement?.id}
                                          span={24}
                                        >
                                          <img
                                            src={micrositeDefaultStyle?.logoUrl}
                                            style={{
                                              ...currentFormElement?.style,
                                              height:
                                                micrositeDefaultStyle?.logoHeight
                                                  ? micrositeDefaultStyle?.logoHeight
                                                  : "30px",
                                              width:
                                                micrositeDefaultStyle?.logoWidth
                                                  ? micrositeDefaultStyle?.logoWidth
                                                  : "auto",
                                            }}
                                            alt="Your logo"
                                          />
                                        </Col>
                                      )}
                                      {currentFormElement?.type ===
                                        "heading" && (
                                        <Col
                                          id={currentFormElement?.id}
                                          span={24}
                                        >
                                          <h1
                                            className="form-heading"
                                            style={{
                                              ...micrositeDefaultStyle?.headingStyle,
                                              ...currentFormElement?.style,
                                            }}
                                            dangerouslySetInnerHTML={{
                                              __html: marked(
                                                currentFormElement?.headingText
                                                  ? currentFormElement?.headingText
                                                  : ""
                                              ),
                                            }}
                                          />
                                        </Col>
                                      )}

                                      {currentFormElement?.type ===
                                        "sub-heading" && (
                                        <div
                                          style={{ width: "100%" }}
                                          id={currentFormElement?.id}
                                          span={24}
                                        >
                                          <h2
                                            className="form-sub-heading"
                                            style={{
                                              ...micrositeDefaultStyle?.subHeadingStyle,
                                              ...currentFormElement?.style,
                                            }}
                                            dangerouslySetInnerHTML={{
                                              __html: marked(
                                                currentFormElement?.headingText
                                                  ? currentFormElement?.headingText
                                                  : ""
                                              ),
                                            }}
                                          />
                                        </div>
                                      )}

                                      {currentFormElement?.type ===
                                        "default-input" && (
                                        <>
                                          <Col
                                            id={currentFormElement?.id}
                                            span={12}
                                            lg={12}
                                            md={12}
                                            sm={24}
                                            xs={24}
                                          >
                                            <Form.Item
                                              name={currentFormElement?.label}
                                              className="dynamic-form-item"
                                              label={
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                    alignItems: "center",
                                                    minWidth: "100%",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      ...micrositeDefaultStyle?.questionStyle,
                                                    }}
                                                  >
                                                    {currentFormElement?.label}
                                                  </span>
                                                  {currentFormElement.description ? (
                                                    <Tooltip
                                                      title={
                                                        currentFormElement.description
                                                          ? currentFormElement.description
                                                          : ""
                                                      }
                                                    >
                                                      <span>
                                                        <InfoCircleOutlined
                                                          style={{
                                                            color:
                                                              micrositeDefaultStyle
                                                                ?.questionStyle
                                                                ?.color,
                                                            fontSize: "15px",
                                                          }}
                                                        />
                                                      </span>
                                                    </Tooltip>
                                                  ) : null}
                                                </div>
                                              }
                                              rules={
                                                currentFormElement?.htmlType ===
                                                "tel"
                                                  ? [
                                                      {
                                                        required: true,
                                                        message: (
                                                          <span
                                                            style={{
                                                              color:
                                                                micrositeDefaultStyle?.errorMessageColor,
                                                            }}
                                                          >
                                                            Please enter{" "}
                                                            {currentFormElement?.label.toLowerCase()}
                                                            !
                                                          </span>
                                                        ),
                                                      },
                                                      {
                                                        pattern:
                                                          /^([0|\\+[0-9]{1,3})?([0-9]{10})$/,
                                                        message: (
                                                          <span
                                                            style={{
                                                              color:
                                                                micrositeDefaultStyle?.errorMessageColor,
                                                            }}
                                                          >
                                                            Please enter valid
                                                            phone no.!
                                                          </span>
                                                        ),
                                                      },
                                                    ]
                                                  : currentFormElement?.htmlType ===
                                                    "email"
                                                  ? [
                                                      {
                                                        required: true,
                                                        message: (
                                                          <span
                                                            style={{
                                                              color:
                                                                micrositeDefaultStyle?.errorMessageColor,
                                                            }}
                                                          >
                                                            Please enter{" "}
                                                            {currentFormElement?.label.toLowerCase()}
                                                            !
                                                          </span>
                                                        ),
                                                      },
                                                      {
                                                        pattern: emailPattern,
                                                        message: (
                                                          <span
                                                            style={{
                                                              color:
                                                                micrositeDefaultStyle?.errorMessageColor,
                                                            }}
                                                          >
                                                            Please enter a valid
                                                            email address!
                                                          </span>
                                                        ),
                                                      },
                                                    ]
                                                  : [
                                                      {
                                                        required: true,
                                                        message: (
                                                          <span
                                                            style={{
                                                              color:
                                                                micrositeDefaultStyle?.errorMessageColor,
                                                            }}
                                                          >
                                                            Please enter{" "}
                                                            {currentFormElement?.label.toLowerCase()}
                                                            !
                                                          </span>
                                                        ),
                                                      },
                                                    ]
                                              }
                                            >
                                              <Input
                                                placeholder={
                                                  currentFormElement?.placeholder
                                                }
                                                style={{
                                                  ...micrositeDefaultStyle?.answerStyle,
                                                  ...micrositeDefaultStyle?.fieldStyle,
                                                }}
                                              />
                                            </Form.Item>
                                          </Col>
                                        </>
                                      )}

                                      {currentFormElement?.type ===
                                        "default-email" &&
                                        !currentFormElement?.isHidden && (
                                          <>
                                            <Col
                                              id={currentFormElement?.id}
                                              span={12}
                                            >
                                              <Form.Item
                                                name={currentFormElement?.label}
                                                className="dynamic-form-item"
                                                label={
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      justifyContent:
                                                        "space-between",
                                                      alignItems: "center",
                                                      minWidth: "100%",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        ...micrositeDefaultStyle?.questionStyle,
                                                      }}
                                                    >
                                                      {
                                                        currentFormElement?.label
                                                      }
                                                    </span>
                                                    {currentFormElement.description ? (
                                                      <Tooltip
                                                        title={
                                                          currentFormElement.description
                                                            ? currentFormElement.description
                                                            : ""
                                                        }
                                                      >
                                                        <span>
                                                          <InfoCircleOutlined
                                                            style={{
                                                              color:
                                                                micrositeDefaultStyle
                                                                  ?.questionStyle
                                                                  ?.color,
                                                              fontSize: "15px",
                                                            }}
                                                          />
                                                        </span>
                                                      </Tooltip>
                                                    ) : null}
                                                  </div>
                                                }
                                                rules={[
                                                  {
                                                    required:
                                                      currentFormElement?.isRequired,
                                                    message: (
                                                      <span
                                                        style={{
                                                          color:
                                                            micrositeDefaultStyle?.errorMessageColor,
                                                        }}
                                                      >
                                                        Please enter{" "}
                                                        {currentFormElement?.label.toLowerCase()}
                                                        !
                                                      </span>
                                                    ),
                                                  },
                                                  {
                                                    pattern: emailPattern,
                                                    message: (
                                                      <span
                                                        style={{
                                                          color:
                                                            micrositeDefaultStyle?.errorMessageColor,
                                                        }}
                                                      >
                                                        Please enter a valid
                                                        email address!
                                                      </span>
                                                    ),
                                                  },
                                                ]}
                                              >
                                                <Input
                                                  placeholder={
                                                    currentFormElement?.placeholder
                                                  }
                                                  style={{
                                                    ...micrositeDefaultStyle?.answerStyle,
                                                    ...micrositeDefaultStyle?.fieldStyle,
                                                  }}
                                                />
                                              </Form.Item>
                                            </Col>
                                          </>
                                        )}

                                      {currentFormElement?.type === "input" &&
                                        !currentFormElement?.isHidden && (
                                          <>
                                            <Col
                                              id={currentFormElement?.id}
                                              span={12}
                                              lg={12}
                                              md={12}
                                              sm={24}
                                              xs={24}
                                            >
                                              <Form.Item
                                                name={currentFormElement?.label}
                                                className="dynamic-form-item"
                                                label={
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      justifyContent:
                                                        "space-between",
                                                      alignItems: "center",
                                                      minWidth: "100%",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        ...micrositeDefaultStyle?.questionStyle,
                                                      }}
                                                    >
                                                      {
                                                        currentFormElement?.label
                                                      }
                                                    </span>
                                                    {currentFormElement.description ? (
                                                      <Tooltip
                                                        title={
                                                          currentFormElement.description
                                                            ? currentFormElement.description
                                                            : ""
                                                        }
                                                      >
                                                        <span>
                                                          <InfoCircleOutlined
                                                            style={{
                                                              color:
                                                                micrositeDefaultStyle
                                                                  ?.questionStyle
                                                                  ?.color,
                                                              fontSize: "15px",
                                                            }}
                                                          />
                                                        </span>
                                                      </Tooltip>
                                                    ) : null}
                                                  </div>
                                                }
                                                rules={[
                                                  {
                                                    required:
                                                      currentFormElement?.isRequired,
                                                    message: (
                                                      <span
                                                        style={{
                                                          color:
                                                            micrositeDefaultStyle?.errorMessageColor,
                                                        }}
                                                      >
                                                        Please enter{" "}
                                                        {currentFormElement?.label.toLowerCase()}
                                                        !
                                                      </span>
                                                    ),
                                                  },

                                                  currentFormElement?.htmlType ===
                                                  "number"
                                                    ? () => ({
                                                        validator(_, value) {
                                                          if (!isNaN(value)) {
                                                            return Promise.resolve();
                                                          }

                                                          return Promise.reject(
                                                            <span
                                                              style={{
                                                                color:
                                                                  micrositeDefaultStyle?.errorMessageColor,
                                                              }}
                                                            >
                                                              only numbers are
                                                              acceptable!
                                                            </span>
                                                          );
                                                        },
                                                      })
                                                    : currentFormElement?.htmlType ===
                                                      "email"
                                                    ? {
                                                        pattern: emailPattern,
                                                        message: (
                                                          <span
                                                            style={{
                                                              color:
                                                                micrositeDefaultStyle?.errorMessageColor,
                                                            }}
                                                          >
                                                            Please enter a valid
                                                            email address!
                                                          </span>
                                                        ),
                                                      }
                                                    : currentFormElement?.htmlType ===
                                                      "url"
                                                    ? {
                                                        pattern:
                                                          /(www\.)?[-a-zA-Z0-9_]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
                                                        message: (
                                                          <span
                                                            style={{
                                                              color:
                                                                micrositeDefaultStyle?.errorMessageColor,
                                                            }}
                                                          >
                                                            Please enter valid
                                                            URL
                                                          </span>
                                                        ),
                                                      }
                                                    : {},
                                                ]}
                                              >
                                                <Input
                                                  placeholder={
                                                    currentFormElement?.placeholder
                                                  }
                                                  style={{
                                                    ...micrositeDefaultStyle?.answerStyle,
                                                    ...micrositeDefaultStyle?.fieldStyle,
                                                  }}
                                                />
                                              </Form.Item>
                                            </Col>
                                          </>
                                        )}

                                      {currentFormElement?.type ===
                                        "dropdown" &&
                                        !currentFormElement?.isHidden && (
                                          <>
                                            <Col
                                              id={currentFormElement?.id}
                                              span={12}
                                              lg={12}
                                              md={12}
                                              sm={24}
                                              xs={24}
                                            >
                                              <Form.Item
                                                name={currentFormElement?.label}
                                                className="dynamic-form-item"
                                                label={
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      justifyContent:
                                                        "space-between",
                                                      alignItems: "center",
                                                      minWidth: "100%",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        ...micrositeDefaultStyle?.questionStyle,
                                                      }}
                                                    >
                                                      {
                                                        currentFormElement?.label
                                                      }
                                                    </span>
                                                    {currentFormElement.description ? (
                                                      <Tooltip
                                                        title={
                                                          currentFormElement.description
                                                            ? currentFormElement.description
                                                            : ""
                                                        }
                                                      >
                                                        <span>
                                                          <InfoCircleOutlined
                                                            style={{
                                                              color:
                                                                micrositeDefaultStyle
                                                                  ?.questionStyle
                                                                  ?.color,
                                                              fontSize: "15px",
                                                            }}
                                                          />
                                                        </span>
                                                      </Tooltip>
                                                    ) : null}
                                                  </div>
                                                }
                                                rules={[
                                                  {
                                                    required:
                                                      currentFormElement?.isRequired
                                                        ? true
                                                        : false,
                                                    message: (
                                                      <span
                                                        style={{
                                                          color:
                                                            micrositeDefaultStyle?.errorMessageColor,
                                                        }}
                                                      >
                                                        Please select value!
                                                      </span>
                                                    ),
                                                  },
                                                ]}
                                              >
                                                <Select
                                                  placeholder={
                                                    currentFormElement?.placeholder
                                                  }
                                                  style={{ width: "100%" }}
                                                  className="Opt-1 round"
                                                >
                                                  {currentFormElement.options.map(
                                                    (obj, index) => {
                                                      return (
                                                        <Option
                                                          key={index}
                                                          value={obj.value}
                                                        >
                                                          {obj.label}
                                                        </Option>
                                                      );
                                                    }
                                                  )}
                                                </Select>
                                              </Form.Item>
                                            </Col>
                                          </>
                                        )}

                                      {currentFormElement?.type ===
                                        "multiselect-dropdown" &&
                                        !currentFormElement?.isHidden && (
                                          <>
                                            <Col
                                              id={currentFormElement?.id}
                                              span={12}
                                              lg={12}
                                              md={12}
                                              sm={24}
                                              xs={24}
                                              style={{
                                                backgroundColor:
                                                  currentFormElement?.isHidden
                                                    ? "#74747426"
                                                    : "",
                                              }}
                                            >
                                              <Form.Item
                                                name={currentFormElement?.label}
                                                className="dynamic-form-item"
                                                label={
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      justifyContent:
                                                        "space-between",
                                                      alignItems: "center",
                                                      minWidth: "100%",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        ...micrositeDefaultStyle?.questionStyle,
                                                      }}
                                                    >
                                                      {
                                                        currentFormElement?.label
                                                      }
                                                    </span>
                                                    {currentFormElement.description ? (
                                                      <Tooltip
                                                        title={
                                                          currentFormElement.description
                                                            ? currentFormElement.description
                                                            : ""
                                                        }
                                                      >
                                                        <span>
                                                          <InfoCircleOutlined
                                                            style={{
                                                              color:
                                                                micrositeDefaultStyle
                                                                  ?.questionStyle
                                                                  ?.color,
                                                              fontSize: "15px",
                                                            }}
                                                          />
                                                        </span>
                                                      </Tooltip>
                                                    ) : null}
                                                  </div>
                                                }
                                                rules={[
                                                  {
                                                    required:
                                                      currentFormElement?.isRequired
                                                        ? true
                                                        : false,
                                                    message: (
                                                      <span
                                                        style={{
                                                          color:
                                                            micrositeDefaultStyle?.errorMessageColor,
                                                        }}
                                                      >
                                                        Please select value!
                                                      </span>
                                                    ),
                                                  },
                                                ]}
                                              >
                                                <TreeSelect
                                                  className="tree-select"
                                                  {...tProps}
                                                  style={{
                                                    ...micrositeDefaultStyle?.answerStyle,
                                                    ...micrositeDefaultStyle?.fieldStyle,
                                                    border: `${micrositeDefaultStyle?.fieldStyle.borderWidth} solid ${micrositeDefaultStyle?.fieldStyle.borderColor}`,
                                                  }}
                                                />
                                              </Form.Item>
                                            </Col>
                                          </>
                                        )}

                                      {currentFormElement?.type ===
                                        "multichoice" &&
                                        !currentFormElement?.isHidden && (
                                          <>
                                            <Col
                                              id={currentFormElement?.id}
                                              span={24}
                                              lg={24}
                                              md={24}
                                              sm={24}
                                              xs={24}
                                            >
                                              <Form.Item
                                                name={currentFormElement?.label}
                                                className="dynamic-form-item"
                                                label={
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      justifyContent:
                                                        "space-between",
                                                      alignItems: "center",
                                                      minWidth: "100%",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        ...micrositeDefaultStyle?.questionStyle,
                                                      }}
                                                    >
                                                      {
                                                        currentFormElement?.label
                                                      }
                                                    </span>
                                                    {currentFormElement.description ? (
                                                      <Tooltip
                                                        title={
                                                          currentFormElement.description
                                                            ? currentFormElement.description
                                                            : ""
                                                        }
                                                      >
                                                        <span>
                                                          <InfoCircleOutlined
                                                            style={{
                                                              color:
                                                                micrositeDefaultStyle
                                                                  ?.questionStyle
                                                                  ?.color,
                                                              fontSize: "15px",
                                                            }}
                                                          />
                                                        </span>
                                                      </Tooltip>
                                                    ) : null}
                                                  </div>
                                                }
                                                rules={[
                                                  {
                                                    required:
                                                      currentFormElement?.isRequired
                                                        ? true
                                                        : false,
                                                    message: (
                                                      <span
                                                        style={{
                                                          color:
                                                            micrositeDefaultStyle?.errorMessageColor,
                                                        }}
                                                      >
                                                        Please pick an item!
                                                      </span>
                                                    ),
                                                  },
                                                ]}
                                              >
                                                {currentFormElement?.styleRadioValue ===
                                                "Tags" ? (
                                                  <Checkbox.Group
                                                    style={{
                                                      display: "flex",
                                                      gap: "5px",
                                                      flexWrap: "wrap",
                                                    }}
                                                  >
                                                    {currentFormElement?.isOther
                                                      ? [
                                                          ...currentFormElement?.options,
                                                          {
                                                            label: "Other",
                                                            value: "Other",
                                                          },
                                                        ].map((obj, index) => {
                                                          return (
                                                            <Checkbox
                                                              key={index}
                                                              value={obj.value}
                                                            >
                                                              <span
                                                                style={{
                                                                  ...micrositeDefaultStyle?.answerStyle,
                                                                }}
                                                              >
                                                                {obj.label}
                                                              </span>
                                                            </Checkbox>
                                                          );
                                                        })
                                                      : currentFormElement?.options.map(
                                                          (obj, index) => {
                                                            return (
                                                              <Checkbox
                                                                key={index}
                                                                value={
                                                                  obj.value
                                                                }
                                                              >
                                                                <span
                                                                  style={{
                                                                    ...micrositeDefaultStyle?.answerStyle,
                                                                  }}
                                                                >
                                                                  {obj.label}
                                                                </span>
                                                              </Checkbox>
                                                            );
                                                          }
                                                        )}
                                                  </Checkbox.Group>
                                                ) : (
                                                  <Radio.Group
                                                    style={{
                                                      display: "flex",
                                                      gap: "5px",
                                                      flexWrap: "wrap",
                                                    }}
                                                  >
                                                    {currentFormElement?.isOther
                                                      ? [
                                                          ...currentFormElement?.options,
                                                          {
                                                            label: "Other",
                                                            value: "Other",
                                                          },
                                                        ].map((obj, index) => {
                                                          return (
                                                            <Radio
                                                              key={index}
                                                              value={obj.value}
                                                            >
                                                              <span
                                                                style={{
                                                                  ...micrositeDefaultStyle?.answerStyle,
                                                                }}
                                                              >
                                                                {obj.label}
                                                              </span>
                                                            </Radio>
                                                          );
                                                        })
                                                      : currentFormElement?.options.map(
                                                          (obj, index) => {
                                                            return (
                                                              <Radio
                                                                key={index}
                                                                value={
                                                                  obj.value
                                                                }
                                                              >
                                                                <span
                                                                  style={{
                                                                    ...micrositeDefaultStyle?.answerStyle,
                                                                  }}
                                                                >
                                                                  {obj.label}
                                                                </span>
                                                              </Radio>
                                                            );
                                                          }
                                                        )}
                                                  </Radio.Group>
                                                )}
                                              </Form.Item>
                                            </Col>
                                          </>
                                        )}

                                      {/* {currentFormElement?.type === "checkbox" ? (
                                    <>
                                      <Col
                                        id={currentFormElement?.id}
                                        span={24}
                                      >
                                        <Form.Item
                                          name="TnC"
                                          style={{marginBottom: "0px"}}
                                          rules={[
                                        {
                                          validator: (_, value) =>
                                            value ? Promise.resolve() : Promise.reject(<span style={{color: micrositeDefaultStyle?.errorMessageColor
                                            }}>Please accept the terms & conditions and privacy policy to sign up</span>),
                                        },
                                      ]}
                                        >
                                          <div style={{display:"flex",gap:"5px"}}>
                                        <Checkbox
                                          style={{
                                            ...micrositeDefaultStyle?.textStyle,
                                          }}
                                        >
                                        </Checkbox>
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: marked(
                                              currentFormElement?.checkboxText ? currentFormElement?.checkboxText : ""
                                            ),
                                          }}
                                        />
                                      </div>
                                        </Form.Item>
                                      </Col>
                                    </>
                                  ):<></>} */}

                                      {currentFormElement?.type ===
                                        "button" && (
                                        <>
                                          <Col
                                            id={currentFormElement?.id}
                                            span={24}
                                          >
                                            <Button
                                              htmlType="submit"
                                              className="secondary-btn"
                                              shape="round"
                                              style={{
                                                ...micrositeDefaultStyle?.buttonStyle,
                                                ...currentFormElement?.style,
                                              }}
                                            >
                                              {currentFormElement?.buttonText}
                                            </Button>
                                          </Col>
                                        </>
                                      )}
                                      {currentFormElement?.type === "text" && (
                                        <>
                                          <Col
                                            id={currentFormElement?.id}
                                            span={24}
                                          >
                                            <p
                                              style={{
                                                ...micrositeDefaultStyle?.textStyle,
                                                ...currentFormElement?.style,
                                              }}
                                            >
                                              {currentFormElement?.textValue}
                                            </p>
                                          </Col>
                                        </>
                                      )}
                                      {currentFormElement?.type ===
                                        "Consent-text" && (
                                        <>
                                          <Col
                                            id={currentFormElement?.id}
                                            span={24}
                                          >
                                            <p
                                              style={{
                                                color: "gray",
                                              }}
                                              dangerouslySetInnerHTML={{
                                                __html: marked(
                                                  currentFormElement?.textValue
                                                    ? currentFormElement?.textValue
                                                    : ""
                                                ),
                                              }}
                                            />
                                          </Col>
                                        </>
                                      )}
                                      {currentFormElement?.type ===
                                        "copyright" && (
                                        <>
                                          <Col
                                            id={currentFormElement?.id}
                                            span={24}
                                          >
                                            <p
                                              style={{
                                                ...micrositeDefaultStyle?.textStyle,
                                                ...currentFormElement?.style,
                                              }}
                                            >
                                              {currentFormElement?.textValue}
                                            </p>
                                          </Col>
                                        </>
                                      )}
                                    </>
                                  );
                                }
                              )}
                            </Row>
                          </Form>
                        </Col>
                      </Row>
                    </Col>

                    <Col
                      span={10}
                      lg={10}
                      md={10}
                      sm={24}
                      xs={24}
                      style={{
                        backgroundColor:
                          templateName === "TWO_COLUMN_LEFT_FORM"
                            ? micrositeDefaultStyle?.themeStyle
                                ?.secondaryBackgroundColor
                            : micrositeDefaultStyle?.themeStyle
                                ?.backgroundColor,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        position: "relative",
                      }}
                      order={
                        screens.xs || screens.sm || screens.md
                          ? 1
                          : templateName === "TWO_COLUMN_LEFT_FORM"
                          ? 2
                          : 1
                      }
                    >
                      <Row
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                        className="form-col-two-wrapper"
                      >
                        <Col span={18} className="form-col-two-container">
                          <h3
                            className="form-company-text"
                            style={{
                              textAlign: "center",
                              padding: "20px",
                              margin: "30px 0px",
                              color: "black",
                              ...micrositeDefaultStyle?.companyTextStyle,
                            }}
                            dangerouslySetInnerHTML={{
                              __html: marked(
                                micrositeFormElements[
                                  micrositeFormElements.length - 1
                                ]?.textValue
                                  ? micrositeFormElements[
                                      micrositeFormElements.length - 1
                                    ]?.textValue
                                  : ""
                              ),
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <img
                      src={
                        micrositeDefaultStyle?.themeStyle
                          ?.secondaryBackgroundColor
                          ? invertColor(
                              screens.xs || screens.sm || screens.md
                                ? templateName === "TWO_COLUMN_LEFT_FORM"
                                  ? micrositeDefaultStyle?.themeStyle
                                      ?.backgroundColor
                                  : micrositeDefaultStyle?.themeStyle
                                      ?.secondaryBackgroundColor
                                : micrositeDefaultStyle?.themeStyle
                                    ?.secondaryBackgroundColor
                            ) === "#000000"
                            ? handleImagesOnLanguageChange(
                                true,
                                micrositeDefaultStyle?.formLanguage
                              )
                            : handleImagesOnLanguageChange(
                                false,
                                micrositeDefaultStyle?.formLanguage
                              )
                          : handleImagesOnLanguageChange(
                              true,
                              micrositeDefaultStyle?.formLanguage
                            )
                      }
                      alt=""
                      style={{
                        height: "25px",
                        width: "auto",
                        position: "absolute",
                        right: "10px",
                        bottom: "10px",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        window.open("https://www.blustream.io", "_blank");
                      }}
                    />
                  </Row>
                ) : (
                  <></>
                )}
                {/* //dev :- for adding new template ,don't remove this */}
                {/* {templateName === "THREE_COLUMN_LEFT_FORM" ? <h1>three column layout</h1>:<></>} */}
              </div>
            </TabPane>

            <TabPane
              tab={
                <MobileOutlined
                  style={{
                    fontSize: "18px",
                    width: 40,
                    justifyContent: "center",
                  }}
                />
              }
              key="2"
            >
              <div className="form-body" style={{ padding: 0 }}>
                {templateName === "TWO_COLUMN_LEFT_FORM" ||
                templateName === "TWO_COLUMN_RIGHT_FORM" ? (
                  <Row justify="center">
                    <Col span={8} lg={8} md={8} sm={24} xs={24}>
                      <Row>
                        <Col
                          span={24}
                          lg={24}
                          md={24}
                          sm={24}
                          xs={24}
                          style={{
                            position: "relative",
                            backgroundColor:
                              templateName === "TWO_COLUMN_LEFT_FORM"
                                ? micrositeDefaultStyle?.themeStyle
                                    ?.backgroundColor
                                : micrositeDefaultStyle?.themeStyle
                                    ?.secondaryBackgroundColor,
                            backgroundImage: `url("${micrositeDefaultStyle?.themeStyle?.backgroundImageUrl}")`,
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: `${micrositeDefaultStyle?.themeStyle?.backgroundWidth}% ${micrositeDefaultStyle?.themeStyle?.backgroundHeight}%`,
                          }}
                          // order={templateName === "TWO_COLUMN_LEFT_FORM" ? 1 : 2}
                          order={2}
                        >
                          <Row
                            style={
                              templateName === "TWO_COLUMN_LEFT_FORM"
                                ? {
                                    minHeight: "100vh",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "20px 0px 50px 0",
                                  }
                                : {
                                    minHeight: "100vh",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "20px 0px 50px 0px",
                                  }
                            }
                            className="form-row-container"
                          >
                            <Col span={15} xs={20} md={20} lg={18} xxl={13}>
                              <Form layout="vertical" className="dynamic-form">
                                <Row gutter={[10, 10]}>
                                  {micrositeFormElements?.map(
                                    (currentFormElement) => {
                                      return (
                                        <>
                                          {currentFormElement?.type ===
                                            "image" && (
                                            <Col
                                              id={currentFormElement?.id}
                                              span={24}
                                            >
                                              <img
                                                src={
                                                  micrositeDefaultStyle?.logoUrl
                                                }
                                                style={{
                                                  ...currentFormElement?.style,
                                                  height:
                                                    micrositeDefaultStyle?.logoHeight
                                                      ? micrositeDefaultStyle?.logoHeight
                                                      : "30px",
                                                  width:
                                                    micrositeDefaultStyle?.logoWidth
                                                      ? micrositeDefaultStyle?.logoWidth
                                                      : "auto",
                                                }}
                                                alt="Your logo"
                                              />
                                            </Col>
                                          )}
                                          {currentFormElement?.type ===
                                            "heading" && (
                                            <Col
                                              id={currentFormElement?.id}
                                              span={24}
                                            >
                                              <h1
                                                className="form-heading"
                                                style={{
                                                  ...micrositeDefaultStyle?.headingStyle,
                                                  ...currentFormElement?.style,
                                                }}
                                                dangerouslySetInnerHTML={{
                                                  __html: marked(
                                                    currentFormElement?.headingText
                                                      ? currentFormElement?.headingText
                                                      : ""
                                                  ),
                                                }}
                                              />
                                            </Col>
                                          )}

                                          {currentFormElement?.type ===
                                            "sub-heading" && (
                                            <div
                                              style={{ width: "100%" }}
                                              id={currentFormElement?.id}
                                              span={24}
                                            >
                                              <h2
                                                className="form-sub-heading"
                                                style={{
                                                  ...micrositeDefaultStyle?.subHeadingStyle,
                                                  ...currentFormElement?.style,
                                                }}
                                                dangerouslySetInnerHTML={{
                                                  __html: marked(
                                                    currentFormElement?.headingText
                                                      ? currentFormElement?.headingText
                                                      : ""
                                                  ),
                                                }}
                                              />
                                            </div>
                                          )}

                                          {currentFormElement?.type ===
                                            "default-input" && (
                                            <>
                                              <Col
                                                id={currentFormElement?.id}
                                                span={24}
                                                lg={24}
                                                md={24}
                                                sm={24}
                                                xs={24}
                                              >
                                                <Form.Item
                                                  name={
                                                    currentFormElement?.label
                                                  }
                                                  className="dynamic-form-item"
                                                  label={
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        justifyContent:
                                                          "space-between",
                                                        alignItems: "center",
                                                        minWidth: "100%",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          ...micrositeDefaultStyle?.questionStyle,
                                                        }}
                                                      >
                                                        {
                                                          currentFormElement?.label
                                                        }
                                                      </span>
                                                      {currentFormElement.description ? (
                                                        <Tooltip
                                                          title={
                                                            currentFormElement.description
                                                              ? currentFormElement.description
                                                              : ""
                                                          }
                                                        >
                                                          <span>
                                                            <InfoCircleOutlined
                                                              style={{
                                                                color:
                                                                  micrositeDefaultStyle
                                                                    ?.questionStyle
                                                                    ?.color,
                                                                fontSize:
                                                                  "15px",
                                                              }}
                                                            />
                                                          </span>
                                                        </Tooltip>
                                                      ) : null}
                                                    </div>
                                                  }
                                                  rules={
                                                    currentFormElement?.htmlType ===
                                                    "tel"
                                                      ? [
                                                          {
                                                            required: true,
                                                            message: (
                                                              <span
                                                                style={{
                                                                  color:
                                                                    micrositeDefaultStyle?.errorMessageColor,
                                                                }}
                                                              >
                                                                Please enter{" "}
                                                                {currentFormElement?.label.toLowerCase()}
                                                                !
                                                              </span>
                                                            ),
                                                          },
                                                          {
                                                            pattern:
                                                              /^([0|\\+[0-9]{1,3})?([0-9]{10})$/,
                                                            message: (
                                                              <span
                                                                style={{
                                                                  color:
                                                                    micrositeDefaultStyle?.errorMessageColor,
                                                                }}
                                                              >
                                                                Please enter
                                                                valid phone no.!
                                                              </span>
                                                            ),
                                                          },
                                                        ]
                                                      : currentFormElement?.htmlType ===
                                                        "email"
                                                      ? [
                                                          {
                                                            required: true,
                                                            message: (
                                                              <span
                                                                style={{
                                                                  color:
                                                                    micrositeDefaultStyle?.errorMessageColor,
                                                                }}
                                                              >
                                                                Please enter{" "}
                                                                {currentFormElement?.label.toLowerCase()}
                                                                !
                                                              </span>
                                                            ),
                                                          },
                                                          {
                                                            pattern:
                                                              emailPattern,
                                                            message: (
                                                              <span
                                                                style={{
                                                                  color:
                                                                    micrositeDefaultStyle?.errorMessageColor,
                                                                }}
                                                              >
                                                                Please enter a
                                                                valid email
                                                                address!
                                                              </span>
                                                            ),
                                                          },
                                                        ]
                                                      : [
                                                          {
                                                            required: true,
                                                            message: (
                                                              <span
                                                                style={{
                                                                  color:
                                                                    micrositeDefaultStyle?.errorMessageColor,
                                                                }}
                                                              >
                                                                Please enter{" "}
                                                                {currentFormElement?.label.toLowerCase()}
                                                                !
                                                              </span>
                                                            ),
                                                          },
                                                        ]
                                                  }
                                                >
                                                  <Input
                                                    placeholder={
                                                      currentFormElement?.placeholder
                                                    }
                                                    style={{
                                                      ...micrositeDefaultStyle?.answerStyle,
                                                      ...micrositeDefaultStyle?.fieldStyle,
                                                    }}
                                                  />
                                                </Form.Item>
                                              </Col>
                                            </>
                                          )}

                                          {currentFormElement?.type ===
                                            "default-email" &&
                                            !currentFormElement?.isHidden && (
                                              <>
                                                <Col
                                                  id={currentFormElement?.id}
                                                  span={24}
                                                  lg={24}
                                                  md={24}
                                                  sm={24}
                                                  xs={24}
                                                >
                                                  <Form.Item
                                                    name={
                                                      currentFormElement?.label
                                                    }
                                                    className="dynamic-form-item"
                                                    label={
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          justifyContent:
                                                            "space-between",
                                                          alignItems: "center",
                                                          minWidth: "100%",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            ...micrositeDefaultStyle?.questionStyle,
                                                          }}
                                                        >
                                                          {
                                                            currentFormElement?.label
                                                          }
                                                        </span>
                                                        {currentFormElement.description ? (
                                                          <Tooltip
                                                            title={
                                                              currentFormElement.description
                                                                ? currentFormElement.description
                                                                : ""
                                                            }
                                                          >
                                                            <span>
                                                              <InfoCircleOutlined
                                                                style={{
                                                                  color:
                                                                    micrositeDefaultStyle
                                                                      ?.questionStyle
                                                                      ?.color,
                                                                  fontSize:
                                                                    "15px",
                                                                }}
                                                              />
                                                            </span>
                                                          </Tooltip>
                                                        ) : null}
                                                      </div>
                                                    }
                                                    rules={[
                                                      {
                                                        required:
                                                          currentFormElement?.isRequired,
                                                        message: (
                                                          <span
                                                            style={{
                                                              color:
                                                                micrositeDefaultStyle?.errorMessageColor,
                                                            }}
                                                          >
                                                            Please enter{" "}
                                                            {currentFormElement?.label.toLowerCase()}
                                                            !
                                                          </span>
                                                        ),
                                                      },
                                                      {
                                                        pattern: emailPattern,
                                                        message: (
                                                          <span
                                                            style={{
                                                              color:
                                                                micrositeDefaultStyle?.errorMessageColor,
                                                            }}
                                                          >
                                                            Please enter a valid
                                                            email address!
                                                          </span>
                                                        ),
                                                      },
                                                    ]}
                                                  >
                                                    <Input
                                                      placeholder={
                                                        currentFormElement?.placeholder
                                                      }
                                                      style={{
                                                        ...micrositeDefaultStyle?.answerStyle,
                                                        ...micrositeDefaultStyle?.fieldStyle,
                                                      }}
                                                    />
                                                  </Form.Item>
                                                </Col>
                                              </>
                                            )}

                                          {currentFormElement?.type ===
                                            "input" &&
                                            !currentFormElement?.isHidden && (
                                              <>
                                                <Col
                                                  id={currentFormElement?.id}
                                                  span={24}
                                                  lg={24}
                                                  md={24}
                                                  sm={24}
                                                  xs={24}
                                                >
                                                  <Form.Item
                                                    name={
                                                      currentFormElement?.label
                                                    }
                                                    className="dynamic-form-item"
                                                    label={
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          justifyContent:
                                                            "space-between",
                                                          alignItems: "center",
                                                          minWidth: "100%",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            ...micrositeDefaultStyle?.questionStyle,
                                                          }}
                                                        >
                                                          {
                                                            currentFormElement?.label
                                                          }
                                                        </span>
                                                        {currentFormElement.description ? (
                                                          <Tooltip
                                                            title={
                                                              currentFormElement.description
                                                                ? currentFormElement.description
                                                                : ""
                                                            }
                                                          >
                                                            <span>
                                                              <InfoCircleOutlined
                                                                style={{
                                                                  color:
                                                                    micrositeDefaultStyle
                                                                      ?.questionStyle
                                                                      ?.color,
                                                                  fontSize:
                                                                    "15px",
                                                                }}
                                                              />
                                                            </span>
                                                          </Tooltip>
                                                        ) : null}
                                                      </div>
                                                    }
                                                    rules={[
                                                      {
                                                        required:
                                                          currentFormElement?.isRequired,
                                                        message: (
                                                          <span
                                                            style={{
                                                              color:
                                                                micrositeDefaultStyle?.errorMessageColor,
                                                            }}
                                                          >
                                                            Please enter{" "}
                                                            {currentFormElement?.label.toLowerCase()}
                                                            !
                                                          </span>
                                                        ),
                                                      },

                                                      currentFormElement?.htmlType ===
                                                      "number"
                                                        ? () => ({
                                                            validator(
                                                              _,
                                                              value
                                                            ) {
                                                              if (
                                                                !isNaN(value)
                                                              ) {
                                                                return Promise.resolve();
                                                              }

                                                              return Promise.reject(
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      micrositeDefaultStyle?.errorMessageColor,
                                                                  }}
                                                                >
                                                                  only numbers
                                                                  are
                                                                  acceptable!
                                                                </span>
                                                              );
                                                            },
                                                          })
                                                        : currentFormElement?.htmlType ===
                                                          "email"
                                                        ? {
                                                            pattern:
                                                              emailPattern,
                                                            message: (
                                                              <span
                                                                style={{
                                                                  color:
                                                                    micrositeDefaultStyle?.errorMessageColor,
                                                                }}
                                                              >
                                                                Please enter a
                                                                valid email
                                                                address!
                                                              </span>
                                                            ),
                                                          }
                                                        : currentFormElement?.htmlType ===
                                                          "url"
                                                        ? {
                                                            pattern:
                                                              /(www\.)?[-a-zA-Z0-9_]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
                                                            message: (
                                                              <span
                                                                style={{
                                                                  color:
                                                                    micrositeDefaultStyle?.errorMessageColor,
                                                                }}
                                                              >
                                                                Please enter
                                                                valid URL
                                                              </span>
                                                            ),
                                                          }
                                                        : {},
                                                    ]}
                                                  >
                                                    <Input
                                                      placeholder={
                                                        currentFormElement?.placeholder
                                                      }
                                                      style={{
                                                        ...micrositeDefaultStyle?.answerStyle,
                                                        ...micrositeDefaultStyle?.fieldStyle,
                                                      }}
                                                    />
                                                  </Form.Item>
                                                </Col>
                                              </>
                                            )}

                                          {currentFormElement?.type ===
                                            "dropdown" &&
                                            !currentFormElement?.isHidden && (
                                              <>
                                                <Col
                                                  id={currentFormElement?.id}
                                                  span={12}
                                                  lg={12}
                                                  md={12}
                                                  sm={24}
                                                  xs={24}
                                                >
                                                  <Form.Item
                                                    name={
                                                      currentFormElement?.label
                                                    }
                                                    className="dynamic-form-item"
                                                    label={
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          justifyContent:
                                                            "space-between",
                                                          alignItems: "center",
                                                          minWidth: "100%",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            ...micrositeDefaultStyle?.questionStyle,
                                                          }}
                                                        >
                                                          {
                                                            currentFormElement?.label
                                                          }
                                                        </span>
                                                        {currentFormElement.description ? (
                                                          <Tooltip
                                                            title={
                                                              currentFormElement.description
                                                                ? currentFormElement.description
                                                                : ""
                                                            }
                                                          >
                                                            <span>
                                                              <InfoCircleOutlined
                                                                style={{
                                                                  color:
                                                                    micrositeDefaultStyle
                                                                      ?.questionStyle
                                                                      ?.color,
                                                                  fontSize:
                                                                    "15px",
                                                                }}
                                                              />
                                                            </span>
                                                          </Tooltip>
                                                        ) : null}
                                                      </div>
                                                    }
                                                    rules={[
                                                      {
                                                        required:
                                                          currentFormElement?.isRequired
                                                            ? true
                                                            : false,
                                                        message: (
                                                          <span
                                                            style={{
                                                              color:
                                                                micrositeDefaultStyle?.errorMessageColor,
                                                            }}
                                                          >
                                                            Please select value!
                                                          </span>
                                                        ),
                                                      },
                                                    ]}
                                                  >
                                                    <Select
                                                      placeholder={
                                                        currentFormElement?.placeholder
                                                      }
                                                      style={{ width: "100%" }}
                                                      className="Opt-1 round"
                                                    >
                                                      {currentFormElement.options.map(
                                                        (obj, index) => {
                                                          return (
                                                            <Option
                                                              key={index}
                                                              value={obj.value}
                                                            >
                                                              {obj.label}
                                                            </Option>
                                                          );
                                                        }
                                                      )}
                                                    </Select>
                                                  </Form.Item>
                                                </Col>
                                              </>
                                            )}

                                          {currentFormElement?.type ===
                                            "multiselect-dropdown" &&
                                            !currentFormElement?.isHidden && (
                                              <>
                                                <Col
                                                  id={currentFormElement?.id}
                                                  span={24}
                                                  lg={24}
                                                  md={24}
                                                  sm={24}
                                                  xs={24}
                                                  style={{
                                                    backgroundColor:
                                                      currentFormElement?.isHidden
                                                        ? "#74747426"
                                                        : "",
                                                  }}
                                                >
                                                  <Form.Item
                                                    name={
                                                      currentFormElement?.label
                                                    }
                                                    className="dynamic-form-item"
                                                    label={
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          justifyContent:
                                                            "space-between",
                                                          alignItems: "center",
                                                          minWidth: "100%",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            ...micrositeDefaultStyle?.questionStyle,
                                                          }}
                                                        >
                                                          {
                                                            currentFormElement?.label
                                                          }
                                                        </span>
                                                        {currentFormElement.description ? (
                                                          <Tooltip
                                                            title={
                                                              currentFormElement.description
                                                                ? currentFormElement.description
                                                                : ""
                                                            }
                                                          >
                                                            <span>
                                                              <InfoCircleOutlined
                                                                style={{
                                                                  color:
                                                                    micrositeDefaultStyle
                                                                      ?.questionStyle
                                                                      ?.color,
                                                                  fontSize:
                                                                    "15px",
                                                                }}
                                                              />
                                                            </span>
                                                          </Tooltip>
                                                        ) : null}
                                                      </div>
                                                    }
                                                    rules={[
                                                      {
                                                        required:
                                                          currentFormElement?.isRequired
                                                            ? true
                                                            : false,
                                                        message: (
                                                          <span
                                                            style={{
                                                              color:
                                                                micrositeDefaultStyle?.errorMessageColor,
                                                            }}
                                                          >
                                                            Please select value!
                                                          </span>
                                                        ),
                                                      },
                                                    ]}
                                                  >
                                                    <TreeSelect
                                                      className="tree-select"
                                                      {...tProps}
                                                      style={{
                                                        ...micrositeDefaultStyle?.answerStyle,
                                                        ...micrositeDefaultStyle?.fieldStyle,
                                                        border: `${micrositeDefaultStyle?.fieldStyle.borderWidth} solid ${micrositeDefaultStyle?.fieldStyle.borderColor}`,
                                                      }}
                                                    />
                                                  </Form.Item>
                                                </Col>
                                              </>
                                            )}

                                          {currentFormElement?.type ===
                                            "multichoice" &&
                                            !currentFormElement?.isHidden && (
                                              <>
                                                <Col
                                                  id={currentFormElement?.id}
                                                  span={24}
                                                  lg={24}
                                                  md={24}
                                                  sm={24}
                                                  xs={24}
                                                >
                                                  <Form.Item
                                                    name={
                                                      currentFormElement?.label
                                                    }
                                                    className="dynamic-form-item"
                                                    label={
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          justifyContent:
                                                            "space-between",
                                                          alignItems: "center",
                                                          minWidth: "100%",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            ...micrositeDefaultStyle?.questionStyle,
                                                          }}
                                                        >
                                                          {
                                                            currentFormElement?.label
                                                          }
                                                        </span>
                                                        {currentFormElement.description ? (
                                                          <Tooltip
                                                            title={
                                                              currentFormElement.description
                                                                ? currentFormElement.description
                                                                : ""
                                                            }
                                                          >
                                                            <span>
                                                              <InfoCircleOutlined
                                                                style={{
                                                                  color:
                                                                    micrositeDefaultStyle
                                                                      ?.questionStyle
                                                                      ?.color,
                                                                  fontSize:
                                                                    "15px",
                                                                }}
                                                              />
                                                            </span>
                                                          </Tooltip>
                                                        ) : null}
                                                      </div>
                                                    }
                                                    rules={[
                                                      {
                                                        required:
                                                          currentFormElement?.isRequired
                                                            ? true
                                                            : false,
                                                        message: (
                                                          <span
                                                            style={{
                                                              color:
                                                                micrositeDefaultStyle?.errorMessageColor,
                                                            }}
                                                          >
                                                            Please pick an item!
                                                          </span>
                                                        ),
                                                      },
                                                    ]}
                                                  >
                                                    {currentFormElement?.styleRadioValue ===
                                                    "Tags" ? (
                                                      <Checkbox.Group
                                                        style={{
                                                          display: "flex",
                                                          gap: "5px",
                                                          flexWrap: "wrap",
                                                        }}
                                                      >
                                                        {currentFormElement?.isOther
                                                          ? [
                                                              ...currentFormElement?.options,
                                                              {
                                                                label: "Other",
                                                                value: "Other",
                                                              },
                                                            ].map(
                                                              (obj, index) => {
                                                                return (
                                                                  <Checkbox
                                                                    key={index}
                                                                    value={
                                                                      obj.value
                                                                    }
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        ...micrositeDefaultStyle?.answerStyle,
                                                                      }}
                                                                    >
                                                                      {
                                                                        obj.label
                                                                      }
                                                                    </span>
                                                                  </Checkbox>
                                                                );
                                                              }
                                                            )
                                                          : currentFormElement?.options.map(
                                                              (obj, index) => {
                                                                return (
                                                                  <Checkbox
                                                                    key={index}
                                                                    value={
                                                                      obj.value
                                                                    }
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        ...micrositeDefaultStyle?.answerStyle,
                                                                      }}
                                                                    >
                                                                      {
                                                                        obj.label
                                                                      }
                                                                    </span>
                                                                  </Checkbox>
                                                                );
                                                              }
                                                            )}
                                                      </Checkbox.Group>
                                                    ) : (
                                                      <Radio.Group
                                                        style={{
                                                          display: "flex",
                                                          gap: "5px",
                                                          flexWrap: "wrap",
                                                        }}
                                                      >
                                                        {currentFormElement?.isOther
                                                          ? [
                                                              ...currentFormElement?.options,
                                                              {
                                                                label: "Other",
                                                                value: "Other",
                                                              },
                                                            ].map(
                                                              (obj, index) => {
                                                                return (
                                                                  <Radio
                                                                    key={index}
                                                                    value={
                                                                      obj.value
                                                                    }
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        ...micrositeDefaultStyle?.answerStyle,
                                                                      }}
                                                                    >
                                                                      {
                                                                        obj.label
                                                                      }
                                                                    </span>
                                                                  </Radio>
                                                                );
                                                              }
                                                            )
                                                          : currentFormElement?.options.map(
                                                              (obj, index) => {
                                                                return (
                                                                  <Radio
                                                                    key={index}
                                                                    value={
                                                                      obj.value
                                                                    }
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        ...micrositeDefaultStyle?.answerStyle,
                                                                      }}
                                                                    >
                                                                      {
                                                                        obj.label
                                                                      }
                                                                    </span>
                                                                  </Radio>
                                                                );
                                                              }
                                                            )}
                                                      </Radio.Group>
                                                    )}
                                                  </Form.Item>
                                                </Col>
                                              </>
                                            )}
                                          {currentFormElement?.type ===
                                            "button" && (
                                            <>
                                              <Col
                                                id={currentFormElement?.id}
                                                span={24}
                                              >
                                                <Button
                                                  htmlType="submit"
                                                  className="secondary-btn"
                                                  shape="round"
                                                  style={{
                                                    ...micrositeDefaultStyle?.buttonStyle,
                                                    ...currentFormElement?.style,
                                                  }}
                                                >
                                                  {
                                                    currentFormElement?.buttonText
                                                  }
                                                </Button>
                                              </Col>
                                            </>
                                          )}
                                          {currentFormElement?.type ===
                                            "text" && (
                                            <>
                                              <Col
                                                id={currentFormElement?.id}
                                                span={24}
                                              >
                                                <p
                                                  style={{
                                                    ...micrositeDefaultStyle?.textStyle,
                                                    ...currentFormElement?.style,
                                                  }}
                                                >
                                                  {
                                                    currentFormElement?.textValue
                                                  }
                                                </p>
                                              </Col>
                                            </>
                                          )}
                                          {currentFormElement?.type ===
                                            "Consent-text" && (
                                            <>
                                              <Col
                                                id={currentFormElement?.id}
                                                span={24}
                                              >
                                                <p
                                                  style={{
                                                    color: "gray",
                                                  }}
                                                  dangerouslySetInnerHTML={{
                                                    __html: marked(
                                                      currentFormElement?.textValue
                                                        ? currentFormElement?.textValue
                                                        : ""
                                                    ),
                                                  }}
                                                />
                                              </Col>
                                            </>
                                          )}
                                          {currentFormElement?.type ===
                                            "copyright" && (
                                            <>
                                              <Col
                                                id={currentFormElement?.id}
                                                span={24}
                                              >
                                                <p
                                                  style={{
                                                    ...micrositeDefaultStyle?.textStyle,
                                                    ...currentFormElement?.style,
                                                  }}
                                                >
                                                  {
                                                    currentFormElement?.textValue
                                                  }
                                                </p>
                                              </Col>
                                            </>
                                          )}
                                        </>
                                      );
                                    }
                                  )}
                                </Row>
                              </Form>
                            </Col>
                          </Row>
                        </Col>

                        <Col
                          span={24}
                          lg={24}
                          md={24}
                          sm={24}
                          xs={24}
                          style={{
                            backgroundColor:
                              templateName === "TWO_COLUMN_LEFT_FORM"
                                ? micrositeDefaultStyle?.themeStyle
                                    ?.secondaryBackgroundColor
                                : micrositeDefaultStyle?.themeStyle
                                    ?.backgroundColor,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            position: "relative",
                          }}
                          order={1}
                        >
                          <Row
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                            className="form-col-two-wrapper"
                          >
                            <Col span={18} className="form-col-two-container">
                              <h3
                                className="form-company-text"
                                style={{
                                  textAlign: "center",
                                  padding: "20px",
                                  margin: "30px 0px",
                                  color: "black",
                                  ...micrositeDefaultStyle?.companyTextStyle,
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: marked(
                                    micrositeFormElements[
                                      micrositeFormElements.length - 1
                                    ]?.textValue
                                      ? micrositeFormElements[
                                          micrositeFormElements.length - 1
                                        ]?.textValue
                                      : ""
                                  ),
                                }}
                              />
                            </Col>
                          </Row>
                        </Col>
                        <img
                          src={
                            (
                              templateName === "TWO_COLUMN_LEFT_FORM"
                                ? micrositeDefaultStyle?.themeStyle
                                    ?.backgroundColor
                                : micrositeDefaultStyle?.themeStyle
                                    ?.secondaryBackgroundColor
                            )
                              ? invertColor(
                                  templateName === "TWO_COLUMN_LEFT_FORM"
                                    ? micrositeDefaultStyle?.themeStyle
                                        ?.backgroundColor
                                    : micrositeDefaultStyle?.themeStyle
                                        ?.secondaryBackgroundColor
                                ) === "#000000"
                                ? handleImagesOnLanguageChange(
                                    true,
                                    micrositeDefaultStyle?.formLanguage
                                  )
                                : handleImagesOnLanguageChange(
                                    false,
                                    micrositeDefaultStyle?.formLanguage
                                  )
                              : handleImagesOnLanguageChange(
                                  true,
                                  micrositeDefaultStyle?.formLanguage
                                )
                          }
                          alt=""
                          style={{
                            height: "25px",
                            width: "auto",
                            position: "absolute",
                            right: "10px",
                            bottom: "10px",
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            window.open("https://www.blustream.io", "_blank");
                          }}
                        />
                      </Row>
                    </Col>
                  </Row>
                ) : (
                  <></>
                )}
                {/* //dev :- for adding new template ,don't remove this */}
                {/* {templateName === "THREE_COLUMN_LEFT_FORM" ? <h1>three column layout</h1>:<></>} */}
              </div>
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </>
  );
};

export default MicrositeUserForm;
