import { Auth } from "aws-amplify";
import sdk from "sdk/Accounts";
import { BlustreamAPI } from "utility/Api";
import {
  LOGOUT,
  UPDATE_LOGIN,
  UPDATE_COMPANY_ACCOUNT,
  UPDATE_USER_GROUPS,
  UPDATE_USER_DATA,
  SET_ACCOUNT_DETAILS,
  SET_ACCOUNT_REQUESTS,
  SET_ACCOUNT_STATUS,
  SET_RO_ADMIN,
  SET_SHOPIFY_PROFILE_ID,
  SET_VERIFICATION_DETAILS,
} from "./index";

export const login = (userName, password) => {
  return async (dispatch, getState) => {
    let response = await Auth.signIn(userName, password);
    if (response.challengeName === "NEW_PASSWORD_REQUIRED") {
      return response;
    } else {
      let groups = [];
      if ("cognito:groups" in response.signInUserSession.accessToken.payload) {
        groups =
          response.signInUserSession.accessToken.payload["cognito:groups"];
        groups.push(groups.splice(groups.indexOf("admin"), 1)[0]);
        groups.push(groups.splice(groups.indexOf("internal"), 1)[0]);

        let res = await sdk.fetchAccount(groups[0]);
        dispatch(setAccountDetails(res));
        dispatch(listCustomerAccountRequests());
        let data = await sdk.fecthCompanies(groups[0]);
        dispatch(updateCompanyAccount(res));
      }
      dispatch(setLoginData(response.signInUserSession, response.attributes));
      return response;
    }
  };
};
export const setAccountRequests = (inputdata) => {
  return {
    type: SET_ACCOUNT_REQUESTS,
    payload: inputdata,
  };
};

export const listCustomerAccountRequests = () => (dispatch) => {
  BlustreamAPI.listCustomerAccountRequestss()
    .then((response) => {
      dispatch(
        setAccountRequests(response.data.listCustomerAccountRequests?.items)
      );
    })
    .catch((e) => console.log(e));
};

export const setLoginData = (token, userData) => {
  return {
    type: UPDATE_LOGIN,
    payload: token,
    data: userData,
  };
};

export const updateCompanyAccount = (data) => {
  return {
    type: UPDATE_COMPANY_ACCOUNT,
    payload: data,
  };
};

export const updateUserGroups = (token) => {
  return {
    type: UPDATE_USER_GROUPS,
    payload: token,
  };
};

export const updateUserData = (data) => {
  return {
    type: UPDATE_USER_DATA,
    payload: data,
  };
};

export const getaccountDetails = (inputdata) => (dispatch) => {
  return BlustreamAPI.getAccointApi(inputdata)
    .then((response) => {
      dispatch(setAccountDetails(response.data.getCustomerAccount));
      return response;
    })
    .catch((err) => console.log(err));
};

export const setAccountDetails = (data) => {
  return {
    type: SET_ACCOUNT_DETAILS,
    payload: data,
  };
};

export const setAccountStatus = (data) => {
  return {
    type: SET_ACCOUNT_STATUS,
    payload: data,
  };
};

export const setRoAdmin = (inputdata) => {
  return {
    type: SET_RO_ADMIN,
    payload: inputdata,
  };
};

export const setShopifyProfileId = (inputdata) => {
  return {
    type: SET_SHOPIFY_PROFILE_ID,
    payload: inputdata,
  };
};

export const setVerificationDetails = (inputdata) => {
  return {
    type: SET_VERIFICATION_DETAILS,
    payload: inputdata,
  };
};

export const logout = () => {
  return {
    type: LOGOUT,
  };
};

export const UpdateCompanyMetaData = (metaData) => (dispatch, getState) => {
  let account = getState().loginReducer?.accountDetails;
  let inputData = {
    id: account.id,
    metadata: JSON.stringify(metaData),
  };
  return BlustreamAPI.updateCompanyDetailsApi(inputData)
    .then((res) => {
      dispatch(setAccountDetails(res?.data?.updateCustomerAccount));
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};
export const UpdateCompanyTags = (inputData) => (dispatch, getState) => {
  return BlustreamAPI.updateCompanyDetailsApi(inputData)
    .then((res) => {
      dispatch(setAccountDetails(res?.data?.updateCustomerAccount));
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};
